export interface TaskData {
  key: string;
  Title: string;
  Description: string;
  Path?: string;
}

export const tasks: TaskData[] = [
  {
    key: 'Fastract',
    Title: 'Fastract Registration',
    Description: 'Getting your NAA#',
  },
  {
    key: 'HasSignedICA',
    Title: 'Agent Agreement',
    Description: 'View and Complete the Agent Agreement',
  },
  {
    key: 'HasLicenses',
    Title: 'Licenses',
    Description: 'Add the states you are licensed to sell in',
    Path: '/licenses',
  },
  {
    key: 'HasCarrierContract',
    Title: 'Carrier Contracting',
    Description: 'Complete Carrier Contracts to eventually get a Writing #',
    Path: '/requests',
  },
  {
    key: 'HasCommDepositInfo',
    Title: 'Your Wallet',
    Description: 'Account for Mon, Wed, & Fri Deposits',
    Path: '/wallet',
  },
  {
    key: 'HasCompletedW9',
    Title: 'Tax Document (W-9)',
    Description: 'Complete your W-9 Tax Form',
  },
];
