import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export function BackgroundQuestion(props: {
  children?: JSX.Element;
  editing: boolean;
  disabled?: boolean;
  question: string;
  example?: string;
  hasOccurred: 0 | 1 | null;
  onResponse: (response: 0 | 1 | null) => void;
}) {
  return (
    <Box sx={{ borderTop: '1px solid #00000011' }}>
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'start',
          transition: 'all 0.2s',
          ':hover': {
            backgroundColor: '#fafafa',
          },
        }}
      >
        <Box sx={{ flex: 1, pr: 2 }}>
          <Box sx={{ fontSize: 18 }}>{props.question}</Box>
          {props.example ? (
            <Box
              sx={{
                pt: 1,
                fontSize: 14,
                fontStyle: 'italic',
                color: '#7a7a7a',
              }}
            >
              {props.example}
            </Box>
          ) : null}
        </Box>

        {props.editing ? (
          <Box>
            <ToggleButtonGroup
              disabled={props.disabled}
              color="info"
              exclusive
              value={props.hasOccurred}
              onChange={(event, newValue) => props.onResponse(newValue)}
            >
              <ToggleButton value={1}>Yes</ToggleButton>
              <ToggleButton value={0}>No</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        ) : (
          <Box
            sx={{
              p: 1,
              backgroundColor: '#fafafa',
              border: '1px solid #00000033',
              borderRadius: 2,
              display: 'flex',
              justifyContent: 'center',
              minWidth: 52,
            }}
          >
            <Box
              sx={{
                color: '#b26500',
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
                fontSize: 18,
                textTransform: 'uppercase',
                opacity: props.hasOccurred === 0 ? 0.6 : 1,
              }}
            >
              {props.hasOccurred === 1
                ? 'Yes'
                : props.hasOccurred === 0
                ? 'No'
                : '---'}
            </Box>
          </Box>
        )}
      </Box>

      {props.hasOccurred ? props.children : null}
    </Box>
  );
}
