import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Bowser from 'bowser';
import React from 'react';

const isMobile = (): boolean => {
  const parser = Bowser.getParser(window.navigator.userAgent);
  return parser.getPlatform().type === 'mobile';
};

export function PaperExpandable(props: {
  children: JSX.Element;
  title: string;
  open?: boolean;
  count?: number;
  description?: string;
  searchString?: string;
  onChangeSearch?: StandardTextFieldProps['onChange'];
}) {
  // State
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
    } else if (props.count !== undefined) {
      if (props.count === 0) {
        setOpen(false);
      } else if (props.count > 0) {
        setOpen(true);
      }
    }
  }, [props.open, props.count]);

  const displaySearch =
    props.count &&
    props.count > 0 &&
    props.searchString !== undefined &&
    props.onChangeSearch !== undefined;

  return (
    <Paper
      elevation={0}
      sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
    >
      <Box
        sx={{ padding: 1, pl: 1, pr: 2, display: 'flex', alignItems: 'center' }}
      >
        {props.count !== undefined ? (
          <Box
            sx={{
              mr: 1,
              height: 28,
              width: 28,
              borderRadius: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: props.count === 0 ? '#2196f388' : '#2196f3',
              fontWeight: 'bold',
              fontSize: 16,
              color: '#fff',
            }}
          >
            {props.count}
          </Box>
        ) : null}

        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ fontSize: { xs: 14, sm: 16, md: 18 } }}
        >
          {props.title}
        </Typography>

        {!isMobile() && props.description ? (
          <Box sx={{ mx: 1, display: 'flex', alignItems: 'center' }}>
            <Tooltip title={props.description} placement="right" arrow>
              <InfoOutlinedIcon color="info" />
            </Tooltip>
          </Box>
        ) : null}

        <Box sx={{ flex: 1 }} />

        {displaySearch ? (
          <TextField
            id={`search-${props.title.replaceAll(' ', '-')}`}
            disabled={!open}
            value={props.searchString}
            onChange={props.onChangeSearch}
            size="small"
            type="search"
            variant="outlined"
            placeholder="Search..."
            sx={{
              mr: 1,
              opacity: open ? 1 : 0.4,
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        ) : null}

        <Tooltip title={open ? 'Collapse' : 'Expand'} placement="left" arrow>
          <IconButton onClick={() => setOpen((currentState) => !currentState)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {open ? (
        <React.Fragment>
          <Divider />

          {props.children}
        </React.Fragment>
      ) : null}
    </Paper>
  );
}
