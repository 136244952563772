import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Warning from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { RequestChangeData } from '../../../types';

export function ManageChangesItem(props: {
  item: RequestChangeData;
  onClick: () => void;
}) {
  // Props
  const { item } = props;

  let CreatedDate = item.CreatedDate;
  if (CreatedDate && CreatedDate.includes(' ') && !CreatedDate.includes('T')) {
    // Convert "2023-06-12 15:47:42.3483176" to "2023-06-12T15:47:42.3483176"
    CreatedDate = CreatedDate.replace(' ', 'T');
  }

  return (
    <Box
      component="li"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #00000011',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':first-of-type': {
          borderTop: 'none',
        },
        ':nth-of-type(even)': {
          backgroundColor: '#fcfcfc',
        },
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
        ':hover .chevron-icon-item-hover': {
          color: '#03182E',
          mr: 0,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            padding: { xs: 1, md: 2 },
            pb: { xs: 0, md: 2 },
            color: '#b26500',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          {item.AgtNo}
        </Box>

        <Box sx={{ flex: 1, padding: 1, pl: { xs: 1, md: 0 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: {
                xs: 'flex-start',
                lg: 'center',
              },
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
            }}
          >
            <Box sx={{ fontSize: 22, mr: 1 }}>
              {item.Fname} {item.Lname}
            </Box>
          </Box>

          {CreatedDate ? (
            <Box sx={{ fontSize: 12, color: '#7a7a7a' }}>
              Change requested on {new Date(CreatedDate).toDateString()}
            </Box>
          ) : null}
        </Box>

        {item.Reason ? (
          <Box
            sx={{
              px: 1,
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #fafafa',
              borderRadius: 2,
            }}
          >
            <Warning fontSize="small" color="warning" />
            <Box
              sx={{
                p: 1,
              }}
            >
              {item.Reason}
            </Box>
          </Box>
        ) : null}

        {item.ChangeType === 'level' ? (
          <Box
            sx={{
              mx: 1,
              mb: { xs: 1, md: 0 },
              py: 0.6,
              px: 1,
              fontFamily: 'Roboto Mono',
              backgroundColor: '#f3f3f3',
              borderRadius: 2,
              border: '1px solid #e1e1e1',
            }}
          >
            <Tooltip title="New level" placement="left" arrow>
              <Box
                sx={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#b26500',
                }}
              >
                {item.ToLevel}
              </Box>
            </Tooltip>
          </Box>
        ) : item.ChangeType === 'hierarchy' ? (
          <Box
            sx={{
              mx: 1,
              mb: { xs: 1, md: 0 },
              py: 0.6,
              px: 1,
              backgroundColor: '#f3f3f3',
              borderRadius: 2,
              border: '1px solid #e1e1e1',
            }}
          >
            <Tooltip title="New upline" placement="left" arrow>
              <Box
                sx={{
                  fontSize: 14,
                  fontWeight: 'bold',
                  color: '#2196f3',
                }}
              >
                {item.ToName}
              </Box>
            </Tooltip>
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          minWidth: 38,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChevronRightIcon
          className="chevron-icon-item-hover"
          sx={{
            color: '#03182E44',
            transition: 'all 0.2s',
            mr: 0.8,
          }}
        />
      </Box>
    </Box>
  );
}
