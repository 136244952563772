import Box from '@mui/material/Box';
import { PaperExpandable } from '../../components/paper-expandable';
import { AppointmentData, CarrierData } from '../../types';
import { stateNames } from '../../utils/states';
import { AppointmentItem } from './appointment-item';

export function AppointmentGroup(props: {
  viewing: 'state' | 'company';
  title: string;
  items: AppointmentData[];
  carriers: CarrierData[];
}) {
  // Props
  const { items } = props;

  let title = props.title;
  if (props.viewing === 'state' && stateNames.hasOwnProperty(props.title)) {
    title = stateNames[props.title];
  } else if (props.viewing === 'company' && props.carriers.length) {
    const item = props.items[0];
    const carrier = props.carriers.find((i) => i.FEIN === item.FEIN);
    if (carrier) {
      title = carrier.FullName;
    }
  }

  return (
    <PaperExpandable title={title} count={items.length}>
      <Box component="ul" sx={{ p: 0, m: 0 }}>
        {items.map((item) => {
          return <AppointmentItem key={item.ApptId} item={item} />;
        })}
      </Box>
    </PaperExpandable>
  );
}
