import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { CarrierData, ColumnHeader, PendingContractData } from '../../../types';

export function ManageContractsRow(props: {
  row: PendingContractData;
  tableColumns: ColumnHeader<PendingContractData>[];
  carrier: CarrierData | undefined;
  carriers: CarrierData[];
  onClick: () => void;
}) {
  // Props
  const { row, tableColumns } = props;

  return (
    <TableRow
      hover
      tabIndex={0}
      onClick={props.onClick}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        ':nth-of-type(odd)': {
          backgroundColor: '#fafafa',
        },
        ':hover #arrow-forward-icon': {
          opacity: 1,
        },
      }}
    >
      {tableColumns.map((column) => {
        const align = column.align || 'center';
        switch (column.key) {
          case 'CreatedDate': {
            let value = row[column.key];
            let title =
              row.CreatedDate && new Date(row.CreatedDate).toDateString();
            if (value && value.length > 10) {
              value = value.substring(0, 10);
              title = new Date(value + 'T00:00').toDateString();
            }
            return (
              <TableCell key={column.key} align={align}>
                <Tooltip title={title} placement="right" arrow>
                  <Box
                    component="span"
                    sx={{
                      display: 'inline',
                      fontFamily: 'Roboto Mono',
                      fontSize: { xs: 10, sm: 12, md: 14 },
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {value}
                  </Box>
                </Tooltip>
              </TableCell>
            );
          }
          case 'Carrier': {
            return (
              <TableCell key={column.key} align={align}>
                <Tooltip
                  title={props.carrier?.FullName}
                  placement="right"
                  arrow
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      display: 'inline',
                    }}
                  >
                    {row[column.key]}
                  </Box>
                </Tooltip>
              </TableCell>
            );
          }
          case 'AgtNo': {
            return (
              <TableCell
                key={column.key}
                align={align}
                sx={{
                  color: '#b26500',
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                }}
              >
                {row[column.key]}
              </TableCell>
            );
          }
          case 'PendingReasons': {
            return (
              <TableCell key={column.key} align={align}>
                <Box
                  sx={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    fontFamily: 'Roboto Mono',
                    textTransform: 'uppercase',
                    color: '#ED6C02',
                    opacity: row.PendingReasons?.length ? 1 : 0.6,
                  }}
                >
                  {row.PendingReasons?.length}
                </Box>
              </TableCell>
            );
          }
          default: {
            return (
              <TableCell key={column.key} align={align}>
                {row[column.key]}
              </TableCell>
            );
          }
        }
      })}

      <Box
        sx={{
          position: 'absolute',
          right: 2,
          top: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ArrowForwardIcon
          id="arrow-forward-icon"
          fontSize="small"
          sx={{ transition: 'all 0.2s', opacity: 0 }}
        />
      </Box>
    </TableRow>
  );
}
