import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { DocumentAgentData } from '../../types';

export function ContractStartedReasons(props: { data: DocumentAgentData }) {
  // Props
  const {
    data: { PendingReasons },
  } = props;
  // State
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Box
        sx={{
          p: 1,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #00000011',
        }}
      >
        <Typography variant="h6" noWrap component="div">
          Pending Reasons ({PendingReasons.length})
        </Typography>

        <Box sx={{ flex: 1 }} />

        <Tooltip
          title={open ? 'Collapse' : 'View Pending Reasons'}
          placement="left"
          arrow
        >
          <IconButton onClick={() => setOpen((currentState) => !currentState)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {open ? (
        <Box
          sx={{
            borderBottom: '1px solid #00000011',
            minHeight: 0,
            flex: 1,
            overflow: 'auto',
          }}
        >
          {PendingReasons.map((item, index) => {
            return (
              <Box
                key={item.PendID}
                sx={{
                  display: 'flex',
                  borderBottom: '1px solid #00000011',
                }}
              >
                <Box
                  sx={{
                    minWidth: 52,
                    borderRight: '1px solid #00000011',
                    backgroundColor: '#ED6C0211',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto Mono',
                      textTransform: 'uppercase',
                      color: '#ED6C02',
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>

                <Box sx={{ p: 2, fontSize: 20, flex: 1 }}>{item.Reason}</Box>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </React.Fragment>
  );
}
