import { CopyAll, Info } from '@mui/icons-material';
import {
  Alert,
  CircularProgress,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useWindowWidth } from '@react-hook/window-size';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { differenceInYears, format } from 'date-fns';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { AgentProfileData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { PhoneMask, TaxIDMask, ZipMask } from '../../utils/form-masks';
import {
  validateEmail,
  validateState,
  validateTaxID,
} from '../../utils/validation';
import { PhotoManager } from './photo-manger';

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    textTransform: 'uppercase',
    fontSize: 13,
    margin: '3px 0px 0px 0px',
    fontWeight: 'bold',
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
});

const maskLabelStyle = {
  textTransform: 'uppercase',
  fontSize: 13,
  margin: '11px 7px',
  fontWeight: 'bold',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
};

const sectionLabel = {
  pl: 1,
  pb: 1,
  fontSize: 18,
  fontWeight: 'bold',
  fontFamily: 'Lato',
  '& .MuiFormControlLabel-label': {
    fontWeight: 'bold',
    fontFamily: 'Lato',
  },
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#000000',
    color: '#ffffff',
    fontSize: theme.typography.pxToRem(12),
    paddingRight: '10px',
    fontFamily: 'Verdana',
    cursor: 'help',
  },
  arrow: {
    color: '#000000',
  },
}));

export function ContactInfo(props: {
  AgtNo: string | undefined;
  data: AgentProfileData;
  user: string | undefined;
}) {
  // Context
  const {
    state: { impersonatedAgent },
  } = React.useContext(AuthContext);
  // Hooks
  const width = useWindowWidth();
  // State
  const [editInfo, setEditInfo] = React.useState<any>({
    AliasName: '',
    Title: '',
    Gender: '',
    DOB: '',
    Email: '',
    Email2: '',
    HomePh: '',
    CellPh: '',
    Fax: '',
    HomeAdd: '',
    HomeAptSte: '',
    HomeCity: '',
    HomeSt: '',
    HomeZip: '',
    HomeCnty: '',
    MailToAdd: '',
    MailToAdptSte: '',
    MailToCity: '',
    MailToSt: '',
    MailToZip: '',
    DrivLic: '',
    DrivLicSt: '',
    MaidName: '',
    MaritalStatus: '',
    SpouseName: '',
    HasCorp: false,
    CorpName: '',
    TaxID: '',
    BusAdd: '',
    BusAptSte: '',
    BusCity: '',
    BusSt: '',
    BusZip: '',
    BusPh: '',
    PrevAdd: '',
    PrevAptSte: '',
    PrevCity: '',
    PrevSt: '',
    PrevZip: '',
    PresAddLth: '',
    PrevAddLth: '',
    Licensed: 0,
    USCitizen: 0,
  });
  const [updateDOB, setUpdateDOB] = React.useState(false);

  // Reset state
  React.useEffect(() => {
    setEditInfo({
      AliasName: props.data?.AliasName || '',
      Title: props.data?.Title || '',
      Gender: props.data?.Gender?.charAt(0) || '',
      DOB: props.data?.DOB || new Date(),
      Email: props.data?.Email || '',
      Email2: props.data?.Email2 || '',
      HomePh: props.data?.HomePh || '',
      CellPh: props.data?.CellPh || '',
      Fax: props.data?.Fax || '',
      HomeAdd: props.data?.HomeAdd || '',
      HomeAptSte: props.data?.HomeAptSte || '',
      HomeCity: props.data?.HomeCity || '',
      HomeSt: props.data?.HomeSt || '',
      HomeZip: props.data?.HomeZip || '',
      HomeCnty: props.data?.HomeCnty || '',
      MailToAdd: props.data?.MailToAdd || '',
      MailToAdptSte: props.data?.MailToAdptSte || '',
      MailToCity: props.data?.MailToCity || '',
      MailToSt: props.data?.MailToSt || '',
      MailToZip: props.data?.MailToZip || '',
      DrivLic: props.data?.DrivLic || '',
      DrivLicSt: props.data?.DrivLicSt || '',
      MaidName: props.data?.MaidName || '',
      MaritalStatus: props.data?.MaritalStatus || '',
      SpouseName: props.data?.SpouseName || '',
      HasCorp: props.data?.HasCorp === 1 ? true : false,
      CorpName: props.data?.CorpName || '',
      TaxID: props.data?.TaxID || '',
      BusAdd: props.data?.BusAdd || '',
      BusAptSte: props.data?.BusAptSte || '',
      BusCity: props.data?.BusCity || '',
      BusSt: props.data?.BusSt || '',
      BusZip: props.data?.BusZip || '',
      BusPh: props.data?.BusPh || '',
      PrevAdd: props.data?.PrevAdd || '',
      PrevAptSte: props.data?.PrevAptSte || '',
      PrevCity: props.data?.PrevCity || '',
      PrevSt: props.data?.PrevSt || '',
      PrevZip: props.data?.PrevZip || '',
      PresAddLth: props.data?.PresAddLth || '',
      PrevAddLth: props.data?.PrevAddLth || '',
      Licensed: props.data?.Licensed || 0,
      USCitizen: props.data?.USCitizen || 0,
    });
  }, [props.data]);

  // Mutation - Submit Profile Info
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async () => {
      const info = editInfo;
      info.HasCorp = editInfo.HasCorp === true ? 1 : 0;
      // Ensure all state info is capitalized
      info.HomeSt = editInfo.HomeSt.toUpperCase() || '';
      info.PrevSt = editInfo.PrevSt.toUpperCase() || '';
      info.MailToSt = editInfo.MailToSt.toUpperCase() || '';
      info.BusSt = editInfo.BusSt.toUpperCase() || '';
      info.DrivLicSt = editInfo.DrivLicSt.toUpperCase() || '';

      if (impersonatedAgent?.AgtNo) {
        info.AgtNo = props.AgtNo;
      }
      if (editInfo.HasCorp === false || editInfo.HasCorp === 0) {
        info.CorpName = '';
        info.TaxID = '';
        info.BusAdd = '';
        info.BusCity = '';
        info.BusSt = '';
        info.BusZip = '';
        info.BusAptSte = '';
      }
      const response = await API.post('ContractingAPI', '/agent/info/update', {
        body: info,
      });
      return response.data;
    },
    onSuccess: async (data) => {
      // Refetch all info
      await queryClient.invalidateQueries({
        queryKey: ['/agent/info', { AgtNo: props.AgtNo }],
      });
    },
    onError: (error) => {
      captureError({ data: { error } });
      window.scrollTo(0, 0);
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInfo({ ...editInfo, [e.target.name]: e.target.value });
  };
  // Copy Home Address to be used for Mailing Address
  const handleCopyHome = () => {
    setEditInfo({
      ...editInfo,
      MailToAdd: editInfo.HomeAdd,
      MailToAdptSte: editInfo.HomeAptSte,
      MailToCity: editInfo.HomeCity,
      MailToSt: editInfo.HomeSt,
      MailToZip: editInfo.HomeZip,
    });
  };

  // Disable submit button conditions
  const disableSubmit =
    (editInfo.HasCorp && (!editInfo.TaxID || !editInfo.CorpName)) ||
    validateState({ state: editInfo.HomeSt }) ||
    validateState({ state: editInfo.MailToSt }) ||
    validateState({ state: editInfo.PrevSt }) ||
    validateState({ state: editInfo.BusSt }) ||
    validateState({ state: editInfo.DrivLicSt }) ||
    validateEmail({ email: editInfo.Email }) ||
    !editInfo.Email ||
    !editInfo.CellPh ||
    !editInfo.HomeAdd ||
    !editInfo.HomeCity ||
    !editInfo.HomeSt ||
    !editInfo.HomeZip ||
    !editInfo.DOB ||
    props.AgtNo !== props.user;

  const editingButtons = (
    <Box
      sx={{
        p: 1,
        borderTop: '1px solid #00000011',
        borderBottom: '1px solid #00000011',
      }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <Button
          fullWidth
          size="large"
          color="info"
          variant="contained"
          disabled={mutation.isLoading || mutation.isError || disableSubmit}
          disableElevation
          endIcon={
            mutation.isLoading && <CircularProgress size={20} color="inherit" />
          }
          onClick={() => mutation.mutate()}
        >
          Save Changes
        </Button>
      </Stack>
    </Box>
  );

  const modifyDOB =
    differenceInYears(new Date(), new Date(props.data.DOB || '')) > 100;

  return (
    <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
      {mutation.isError ? (
        <Alert onClose={() => mutation.reset()} severity="error">
          <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
          <small>Please refresh the page and try again.</small>
        </Alert>
      ) : null}
      <Box sx={{ p: 1, px: 2, display: 'flex', alignItems: 'start' }}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontSize: 32, fontWeight: 'bold' }}>
            {props.data?.Prefix || ''} {props.data?.Fname || ''}{' '}
            {props.data?.Lname || ''} {props.data?.Suffix || ''}{' '}
            <HtmlTooltip
              arrow
              placement="top"
              title={
                <ul>
                  <li style={{ marginBottom: 5 }}>
                    Must use your <b>legal</b> name
                  </li>
                  <li>Contact help desk if any changes are needed</li>
                </ul>
              }
            >
              <Info fontSize="small" style={{ marginLeft: 5 }} />
            </HtmlTooltip>
          </Box>

          <Box
            sx={{
              pl: 1,
              color: '#b26500',
              fontFamily: 'Roboto Mono',
              fontWeight: 'bold',
              fontSize: 14,
            }}
          >
            {props.data?.AgtNo}
          </Box>
        </Box>
      </Box>
      {mutation.isLoading && <LinearProgress />}

      <Box sx={{ border: '1px solid #eee', borderRadius: 2, mx: 2, pb: 1 }}>
        <Grid
          container
          direction={width < 800 ? undefined : 'row-reverse'}
          sx={{ p: 1 }}
        >
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography sx={sectionLabel}>Agent Photo:</Typography>
            </Grid>
            <PhotoManager agent={props.AgtNo} user={props.user} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography sx={sectionLabel}>Personal Information:</Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="Preferred Name"
                  name="AliasName"
                  size="small"
                  value={editInfo.AliasName}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="Title"
                  name="Title"
                  size="small"
                  value={editInfo.Title}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="NPN"
                  name="NPN"
                  size="small"
                  value={props.data?.NPN ? props.data.NPN : ''}
                  fullWidth
                  disabled={true}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                      cursor: 'not-allowed',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {modifyDOB && !updateDOB ? (
                  <CssTextField
                    label="Date of Birth"
                    size="small"
                    fullWidth
                    onFocus={() => setUpdateDOB(true)}
                    value={
                      editInfo.DOB
                        ? format(new Date(editInfo.DOB + 'T00:00'), 'MM/dd')
                        : ''
                    }
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    disabled={mutation.isLoading || impersonatedAgent !== null}
                  />
                ) : (
                  <CssTextField
                    label="Date Of Birth"
                    name="DOB"
                    size="small"
                    type="date"
                    value={editInfo.DOB}
                    color="info"
                    fullWidth
                    required
                    disabled={mutation.isLoading || impersonatedAgent !== null}
                    onChange={handleChange}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                    error={!editInfo.DOB}
                    helperText={!editInfo.DOB && 'Required'}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  required
                  error={!editInfo.Gender}
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Gender
                  </InputLabel>
                  <Select
                    value={editInfo.Gender || ''}
                    label="Gender"
                    name="Gender"
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                    onChange={(e: SelectChangeEvent) => {
                      setEditInfo({
                        ...editInfo,
                        [e.target.name]: e.target.value as string,
                      });
                    }}
                  >
                    <MenuItem value={''}></MenuItem>
                    <MenuItem value={'M'}>Male</MenuItem>
                    <MenuItem value={'F'}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="SSN"
                  name="SSN"
                  size="small"
                  value={
                    props.data?.Last4SSN ? 'XXX-XX-' + props.data?.Last4SSN : ''
                  }
                  fullWidth
                  disabled={true}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                      cursor: 'not-allowed',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="License State"
                  name="DrivLicSt"
                  size="small"
                  value={editInfo.DrivLicSt}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  inputProps={{
                    maxLength: 2,
                    style: { textTransform: 'uppercase' },
                  }}
                  error={validateState({ state: editInfo.DrivLicSt })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="Drivers License"
                  name="DrivLic"
                  size="small"
                  value={editInfo.DrivLic}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ px: 1 }}>
          <Grid item xs={12} sm={6} md={4}>
            <CssTextField
              label="Marital Status"
              name="MaritalStatus"
              size="small"
              value={editInfo.MaritalStatus}
              color="info"
              fullWidth
              disabled={mutation.isLoading || impersonatedAgent !== null}
              onChange={handleChange}
              sx={{
                m: 1,
                pr: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CssTextField
              label="Spouse Name"
              name="SpouseName"
              size="small"
              value={editInfo.SpouseName}
              color="info"
              fullWidth
              disabled={mutation.isLoading || impersonatedAgent !== null}
              onChange={handleChange}
              sx={{
                m: 1,
                pr: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CssTextField
              label="Maiden Name"
              name="MaidName"
              size="small"
              value={editInfo.MaidName}
              color="info"
              fullWidth
              disabled={mutation.isLoading || impersonatedAgent !== null}
              onChange={handleChange}
              sx={{
                m: 1,
                pr: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              color="info"
              disabled={mutation.isLoading || impersonatedAgent !== null}
              style={{ backgroundColor: '#fff' }}
            >
              <InputLabel sx={maskLabelStyle} color="info">
                US Citizen
              </InputLabel>
              <Select
                value={editInfo.USCitizen}
                label="US Citizen"
                name="USCitizen"
                sx={{
                  m: 1,
                  pr: 2,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000',
                  },
                }}
                onChange={(e: SelectChangeEvent) => {
                  setEditInfo({
                    ...editInfo,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              color="info"
              disabled={mutation.isLoading || impersonatedAgent !== null}
              style={{ backgroundColor: '#fff' }}
            >
              <InputLabel sx={maskLabelStyle} color="info">
                Licensed
              </InputLabel>
              <Select
                value={editInfo.Licensed}
                label="Licensed"
                name="Licensed"
                sx={{
                  m: 1,
                  pr: 2,
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000',
                  },
                }}
                onChange={(e: SelectChangeEvent) => {
                  setEditInfo({
                    ...editInfo,
                    [e.target.name]: e.target.value,
                  });
                }}
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CssTextField
              label="Recruiter"
              name="Recruiter"
              size="small"
              value={
                props.data?.Recruiter
                  ? props.data?.RecName + '  (' + props.data.Recruiter + ')'
                  : ''
              }
              fullWidth
              disabled={true}
              sx={{
                m: 1,
                pr: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000',
                  cursor: 'not-allowed',
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CssTextField
              label="Website"
              name="Website"
              size="small"
              value={editInfo.Website}
              disabled
              fullWidth
              sx={{
                m: 1,
                pr: 2,
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#00000085',
                  cursor: 'not-allowed',
                },
              }}
            />
          </Grid>
        </Grid>{' '}
      </Box>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Box sx={{ border: '1px solid #eee', borderRadius: 2, p: 1, m: 1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={sectionLabel}>Contact Information:</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <CssTextField
                  label="Email"
                  name="Email"
                  size="small"
                  value={editInfo.Email}
                  color="info"
                  fullWidth
                  required
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  error={
                    validateEmail({ email: editInfo.Email }) || !editInfo.Email
                  }
                  helperText={!editInfo.Email && 'Required'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CssTextField
                  label="Business Email"
                  name="Email2"
                  size="small"
                  value={editInfo.Email2}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Home Phone
                  </InputLabel>
                  <OutlinedInput
                    label="Home Phone"
                    size="small"
                    value={editInfo.HomePh}
                    onChange={handleChange}
                    name="HomePh"
                    inputComponent={PhoneMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  required
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  error={!editInfo.CellPh}
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel color="info" sx={maskLabelStyle}>
                    Cell Phone
                  </InputLabel>
                  <OutlinedInput
                    label="Cell Phone"
                    size="small"
                    value={editInfo.CellPh}
                    onChange={handleChange}
                    name="CellPh"
                    inputComponent={PhoneMask as any}
                    autoComplete="off"
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                  {!editInfo.CellPh && (
                    <FormHelperText style={{ margin: '-5px 20px 0px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel color="info" sx={maskLabelStyle}>
                    Business Phone
                  </InputLabel>
                  <OutlinedInput
                    label="Business Phone"
                    size="small"
                    value={editInfo.BusPh}
                    onChange={handleChange}
                    name="BusPh"
                    inputComponent={PhoneMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel color="info" sx={maskLabelStyle}>
                    Fax Number
                  </InputLabel>
                  <OutlinedInput
                    label="Fax Number"
                    size="small"
                    value={editInfo.Fax}
                    onChange={handleChange}
                    name="Fax"
                    inputComponent={PhoneMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #eee', borderRadius: 2, p: 1, m: 1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={sectionLabel}>Home Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  label="Street Address"
                  name="HomeAdd"
                  size="small"
                  value={editInfo.HomeAdd}
                  required
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  error={!editInfo.HomeAdd}
                  helperText={!editInfo.HomeAdd && 'Required'}
                />
              </Grid>

              <Grid item xs={12}>
                <CssTextField
                  label="STREET ADDRESS 2 (SUITE, APT, ETC)"
                  name="HomeAptSte"
                  size="small"
                  value={editInfo.HomeAptSte}
                  color="info"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <CssTextField
                  label="City"
                  name="HomeCity"
                  size="small"
                  required
                  value={editInfo.HomeCity}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  error={!editInfo.HomeCity}
                  helperText={!editInfo.HomeCity && 'Required'}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <CssTextField
                  label="State"
                  name="HomeSt"
                  size="small"
                  required
                  value={editInfo.HomeSt}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  inputProps={{
                    maxLength: 2,
                    style: { textTransform: 'uppercase' },
                  }}
                  error={
                    validateState({ state: editInfo.HomeSt }) ||
                    !editInfo.HomeSt
                  }
                  helperText={
                    (!editInfo.HomeSt && 'Required') ||
                    (validateState({ state: editInfo.HomeSt }) &&
                      'Invalid Entry')
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  required
                  style={{ backgroundColor: '#fff' }}
                  error={!editInfo.HomeZip}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Zip
                  </InputLabel>
                  <OutlinedInput
                    label="Zip"
                    size="small"
                    value={editInfo.HomeZip}
                    onChange={handleChange}
                    name="HomeZip"
                    inputComponent={ZipMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                  {!editInfo.HomeZip && (
                    <FormHelperText style={{ margin: '-5px 20px 0px' }}>
                      Required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="County"
                  name="HomeCnty"
                  size="small"
                  value={editInfo.HomeCnty}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="Years at Address"
                  name="PresAddLth"
                  size="small"
                  type="number"
                  value={editInfo.PresAddLth}
                  color="info"
                  fullWidth
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #eee', borderRadius: 2, p: 1, m: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={sectionLabel}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>Mailing Address</div>{' '}
                <Button
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  color="primary"
                  startIcon={<CopyAll />}
                  onClick={handleCopyHome}
                >
                  Copy Home
                </Button>
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  label="Street Address"
                  name="MailToAdd"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  value={editInfo.MailToAdd}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CssTextField
                  label="STREET ADDRESS 2 (SUITE, APT, ETC)"
                  name="MailToAdptSte"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  value={editInfo.MailToAdptSte}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <CssTextField
                  label="City"
                  name="MailToCity"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  value={editInfo.MailToCity}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <CssTextField
                  label="State"
                  name="MailToSt"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  value={editInfo.MailToSt}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  inputProps={{
                    maxLength: 2,
                    style: { textTransform: 'uppercase' },
                  }}
                  error={validateState({ state: editInfo.MailToSt })}
                  helperText={
                    validateState({ state: editInfo.MailToSt }) &&
                    'Invalid Entry'
                  }
                />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  color="info"
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Zip
                  </InputLabel>
                  <OutlinedInput
                    label="Zip"
                    size="small"
                    value={editInfo.MailToZip}
                    onChange={handleChange}
                    name="MailToZip"
                    inputComponent={ZipMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #eee', borderRadius: 2, p: 1, m: 1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography sx={sectionLabel}>Previous Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  label="Street Address"
                  name="PrevAdd"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.PrevAdd}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <CssTextField
                  label="STREET ADDRESS 2 (SUITE, APT, ETC)"
                  name="PrevAptSte"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.PrevAptSte}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <CssTextField
                  label="City"
                  name="PrevCity"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.PrevCity}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <CssTextField
                  label="State"
                  name="PrevSt"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.PrevSt}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  inputProps={{
                    maxLength: 2,
                    style: { textTransform: 'uppercase' },
                  }}
                  error={validateState({ state: editInfo.PrevSt })}
                  helperText={
                    validateState({ state: editInfo.PrevSt }) && 'Invalid Entry'
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  color="info"
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Zip
                  </InputLabel>
                  <OutlinedInput
                    label="Zip"
                    size="small"
                    value={editInfo.PrevZip}
                    onChange={handleChange}
                    name="PrevZip"
                    inputComponent={ZipMask as any}
                    sx={{
                      m: 1,
                      mt: 1.1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <CssTextField
                  label="Years at Address"
                  name="PrevAddLth"
                  size="small"
                  disabled={mutation.isLoading || impersonatedAgent !== null}
                  type="number"
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.PrevAddLth}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ border: '1px solid #eee', borderRadius: 2, p: 1, m: 1 }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={sectionLabel}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                Corporate Info
                <FormGroup style={{ margin: '-5px 5px' }}>
                  <FormControlLabel
                    style={{ fontWeight: 'bold' }}
                    value={editInfo.HasCorp}
                    disabled={mutation.isLoading || impersonatedAgent !== null}
                    control={
                      <Checkbox
                        checked={editInfo.HasCorp}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setEditInfo({
                            ...editInfo,
                            HasCorp: e.target.checked,
                          })
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 'bold' }}
                      >
                        Has Corp?
                      </Typography>
                    }
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <CssTextField
                  label="Corp Name"
                  name="CorpName"
                  size="small"
                  required={editInfo.HasCorp}
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.CorpName}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                  error={editInfo.HasCorp && !editInfo.CorpName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  style={{ backgroundColor: '#fff' }}
                  required={editInfo.HasCorp}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                  error={
                    validateTaxID({ taxID: editInfo.TaxID }) ||
                    (editInfo.HasCorp && !editInfo.TaxID)
                  }
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Tax ID / EIN
                  </InputLabel>
                  <OutlinedInput
                    label="Tax ID / EIN"
                    size="small"
                    value={editInfo.TaxID}
                    onChange={handleChange}
                    name="TaxID"
                    inputComponent={TaxIDMask as any}
                    sx={{
                      m: 1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <CssTextField
                  label="Street Address"
                  name="BusAdd"
                  size="small"
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.BusAdd}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <CssTextField
                  label="STREET ADDRESS 2 (SUITE, APT, ETC)"
                  name="BusAptSte"
                  size="small"
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.BusAptSte}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                />
              </Grid>

              <Grid item xs={12} md={6} lg={5}>
                <CssTextField
                  label="City"
                  name="BusCity"
                  size="small"
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.BusCity}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                />
              </Grid>

              <Grid item xs={12} md={6} lg={2}>
                <CssTextField
                  label="State"
                  name="BusSt"
                  size="small"
                  style={{
                    margin: 10,
                    paddingRight: 20,
                  }}
                  value={editInfo.BusSt}
                  color="info"
                  fullWidth
                  onChange={handleChange}
                  sx={{
                    m: 1,
                    pr: 2,
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000',
                    },
                  }}
                  inputProps={{
                    maxLength: 2,
                    style: { textTransform: 'uppercase' },
                  }}
                  disabled={
                    mutation.isLoading ||
                    !editInfo.HasCorp ||
                    impersonatedAgent !== null
                  }
                  error={validateState({ state: editInfo.BusSt })}
                  helperText={
                    validateState({ state: editInfo.BusSt }) && 'Invalid Entry'
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  color="info"
                  disabled={
                    mutation.isLoading ||
                    impersonatedAgent !== null ||
                    !editInfo.HasCorp
                  }
                  style={{ backgroundColor: '#fff' }}
                >
                  <InputLabel sx={maskLabelStyle} color="info">
                    Zip
                  </InputLabel>
                  <OutlinedInput
                    label="Zip"
                    size="small"
                    value={editInfo.BusZip}
                    onChange={handleChange}
                    name="BusZip"
                    inputComponent={ZipMask as any}
                    sx={{
                      m: 1,
                      mt: 1.1,
                      pr: 2,
                      '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: '#000',
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {editingButtons}
    </Paper>
  );
}
