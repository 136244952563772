import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import isWithinInterval from 'date-fns/isWithinInterval';
import orderBy from 'lodash/orderBy';
import { PaperExpandable } from '../../../components/paper-expandable';
import { AnnouncementData } from '../../../types';
import { AnnouncementsItem } from './announcement-item';

export function Announcements(props: { data: AnnouncementData[] | undefined }) {
  // Props
  const { data } = props;

  // Show the most recent announcement first and the oldest last
  const announcements = orderBy(data, 'StartDate', 'desc');
  // Only display announcements between their start and end date on the home page
  const current = announcements.filter((a) => {
    if (a.EndDate && a.StartDate) {
      return isWithinInterval(new Date(), {
        start: new Date(a.StartDate),
        end: new Date(a.EndDate),
      });
    } else {
      return false;
    }
  });

  return (
    <PaperExpandable
      title="Announcements"
      count={current?.length || 0}
      open={current.length > 0}
    >
      {current.length === 0 ? (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
            fontFamily: 'Roboto Mono',
            color: '#9b9b9b',
          }}
        >
          No announcements to be made at this time
        </Box>
      ) : (
        <Stack spacing={2}>
          {current.map((item, index) => {
            const borderTop = index === 0 ? 'none' : '1px solid #00000011';

            const isEven = index % 2 === 0;

            return (
              <Box
                key={item.AnnouncementId}
                sx={{ borderTop, backgroundColor: isEven ? '#fff' : '#fcfcfc' }}
              >
                <AnnouncementsItem item={item} />
              </Box>
            );
          })}
        </Stack>
      )}
    </PaperExpandable>
  );
}
