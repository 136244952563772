import { Auth } from 'aws-amplify';
import axios from 'axios';

/**
 * Make a POST Request with FormData. Used for file uploading.
 */
export async function formDataRequest(params: {
  path: string;
  formData: FormData;
  baseURL?: string;
}) {
  const { path, formData } = params;

  // Add the Authenticated User's JWT to the request headers
  const Authorization = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();

  // Default to the University API URL
  let url = process.env.REACT_APP_CONTRACTING_API_URL + path;
  if (params.baseURL) {
    url = params.baseURL + path;
  }

  return await axios.post(url, formData, {
    headers: { 'Content-Type': 'multipart/form-data', Authorization },
  });
}
