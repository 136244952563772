import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../contexts/auth-context';
import { MultiFactorInfo } from '../routes/account/multi-factor-info';

export function MFACheck() {
  // Context
  const {
    state: { user },
  } = React.useContext(AuthContext);

  return (
    <Box
      sx={{
        height: '100dvh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack spacing={2} alignItems="center" sx={{ p: 1 }}>
        {user ? <MultiFactorInfo user={user} /> : null}

        <Box sx={{ fontSize: 18, textAlign: 'center' }}>
          Please click the <b>Manage</b> button to select your preferred method
          of Multi-Factor Authentication.
        </Box>

        <Box sx={{ fontSize: 18, textAlign: 'center' }}>
          You can change your preferred method of MFA at any time by visiting
          the <b>Agent Profile</b> page.
        </Box>

        <Button
          variant="outlined"
          sx={{ whiteSpace: 'nowrap' }}
          endIcon={<LogoutIcon />}
          onClick={() => Auth.signOut()}
        >
          Sign out
        </Button>
      </Stack>
    </Box>
  );
}
