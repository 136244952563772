import CloseIcon from '@mui/icons-material/Close';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import orderBy from 'lodash/orderBy';
import { PendingContractData } from '../../../types';

export function ManageContractsInfo(props: {
  contract: PendingContractData | undefined;
  onClose: () => void;
}) {
  // Props
  const { contract } = props;

  const handleClose = () => {
    props.onClose();
  };

  let CreatedDate: Date | null = null;
  if (contract && contract.CreatedDate) {
    CreatedDate = new Date(contract.CreatedDate.substring(0, 10) + 'T00:00');
  }

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={contract !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          {contract !== undefined ? (
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontSize: { xs: 16, md: 22 },
                  flex: 1,
                  fontWeight: 'normal',
                }}
              >
                Pending Contract for {contract.AgtName}
              </Typography>

              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                  fontSize: 14,
                  color: '#b26500',
                }}
              >
                {contract.AgtNo}
              </Box>
            </Box>
          ) : null}
        </Toolbar>

        <Divider />

        {contract !== undefined ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack spacing={1} sx={{ p: 2 }}>
              <Box
                component="a"
                href={`mailto:${contract.Email}?subject=Your ${contract.FullName} contract&body=Hello ${contract.AgtName} (${contract.AgtNo}),`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  textDecorationLine: 'none',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#2196f344',
                  borderRadius: 2,
                  color: '#2196f3',
                  backgroundColor: '#2196f304',
                  transition: 'all 0.2s',
                  ':hover': {
                    color: '#1769aa',
                    borderColor: '#1769aa',
                    backgroundColor: '#1769aa08',
                  },
                }}
              >
                <ContactMailIcon fontSize="small" />

                <Box sx={{ flex: 1, px: 1 }}>{contract.Email}</Box>

                <Box sx={{ px: 1, fontSize: 12 }}>Open new message</Box>

                <OpenInNewIcon fontSize="small" />
              </Box>

              {CreatedDate ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #eaeaea',
                    borderRadius: 2,
                  }}
                >
                  <ScheduleIcon fontSize="small" />

                  <Box sx={{ px: 1, fontSize: 16 }}>
                    Created contract on {CreatedDate.toDateString()}
                  </Box>

                  <Box
                    sx={{
                      fontSize: 12,
                      fontStyle: 'italic',
                      color: '#9b9b9b',
                    }}
                  >
                    {formatDistanceToNowStrict(CreatedDate, {
                      addSuffix: true,
                    })}
                  </Box>
                </Box>
              ) : null}

              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #eaeaea',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ fontSize: 14, pr: 1 }}>Contract for</Box>

                <Box sx={{ flex: 1, fontWeight: 'bold', fontSize: 18 }}>
                  {contract.FullName}
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 66,
                  }}
                >
                  {contract.Logo ? (
                    <img
                      loading="lazy"
                      src={contract.Logo}
                      alt={contract.Carrier || 'Carrier'}
                      style={{ maxWidth: 66, maxHeight: 32 }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: 66,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          flex: 1,
                          borderBottom: '1px solid #b2650044',
                        }}
                      />
                      <Box
                        sx={{
                          fontFamily: 'Roboto Mono',
                          fontWeight: 'bold',
                          fontSize: 12,
                          color: '#b26500',
                          px: 1,
                        }}
                      >
                        {contract.Carrier}
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                          borderBottom: '1px solid #b2650044',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #eaeaea',
                  borderRadius: 2,
                }}
              >
                <Box sx={{ fontSize: 14, pr: 1 }}>Processing Status</Box>
                <Box sx={{ fontWeight: 'bold', fontSize: 18 }}>
                  {contract.ProcStatus}
                </Box>
              </Box>

              {contract.PendingReasons && contract.PendingReasons.length ? (
                <Box sx={{ pb: 1 }}>
                  <Paper
                    elevation={0}
                    sx={{ border: '1px solid #eaeaea', overflow: 'hidden' }}
                  >
                    <Box sx={{ p: 1, fontWeight: 'bold' }}>
                      Pending Reasons ({contract.PendingReasons.length})
                    </Box>

                    <Box>
                      {orderBy(
                        contract.PendingReasons,
                        'AssignDate',
                        'desc'
                      ).map((reason, index) => {
                        return (
                          <Box
                            key={reason.PendID}
                            sx={{
                              display: 'flex',
                              borderTop: '1px solid #00000011',
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: 36,
                                borderRight: '1px solid #00000011',
                                backgroundColor: '#ED6C0211',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto Mono',
                                  textTransform: 'uppercase',
                                  color: '#ED6C02',
                                }}
                              >
                                {index + 1}
                              </Box>
                            </Box>

                            <Box sx={{ px: 1, py: 0.6, fontSize: 16, flex: 1 }}>
                              {reason.Reason}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Paper>
                </Box>
              ) : null}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
