import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import { PaperExpandable } from '../../../components/paper-expandable';
import { NotificationData } from '../../../types';
import { NotificationItem } from './notification-item';

export function Notifications(props: { data: NotificationData[] }) {
  // Props
  const { data } = props;

  return (
    <PaperExpandable
      title="Notifications"
      count={data?.length || 0}
      description="Displaying all unread messages from the past 7 days"
    >
      <Box>
        {data.length === 0 ? (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              fontFamily: 'Roboto Mono',
              color: '#9b9b9b',
            }}
          >
            No notifications at this time
          </Box>
        ) : (
          <Box>
            {orderBy(data, 'CreatedDate', 'desc').map((item, index) => {
              const borderTop = index === 0 ? 'none' : '1px solid #00000011';

              const isEven = index % 2 === 0;

              return (
                <Box
                  key={item.NotificationId}
                  sx={{
                    borderTop,
                    backgroundColor: isEven ? '#fff' : '#fcfcfc',
                  }}
                >
                  <NotificationItem data={item} />
                </Box>
              );
            })}
          </Box>
        )}

        <Divider />

        <Button
          color="info"
          fullWidth
          component={Link}
          to="/team?tab=Notifications"
          sx={{ borderRadius: 0 }}
        >
          View all
        </Button>
      </Box>
    </PaperExpandable>
  );
}
