import CallIcon from '@mui/icons-material/Call';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { GettingStartedTeamData } from '../../../types';
import { tasks } from '../../home/data';

export function ManageProgressInfo(props: {
  progress: GettingStartedTeamData | undefined;
  onClose: () => void;
}) {
  // Props
  const { progress } = props;

  const handleClose = () => {
    props.onClose();
  };

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={progress !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          {progress !== undefined ? (
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Box>
                <Box sx={{ fontSize: 22 }}>Progress for {progress.AgtName}</Box>
                {progress.HomeCity && progress.HomeSt ? (
                  <Box sx={{ fontSize: 14 }}>
                    {progress.HomeCity}, {progress.HomeSt}
                  </Box>
                ) : null}
              </Box>

              <Box sx={{ flex: 1 }} />

              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                  fontSize: 16,
                  color: '#b26500',
                }}
              >
                {progress.AgtNo}
              </Box>
            </Box>
          ) : null}
        </Toolbar>

        <Divider />

        {progress !== undefined ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            <Stack spacing={1} sx={{ p: 2 }}>
              {progress.RecAgtName ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #fafafa',
                    borderRadius: 2,
                  }}
                >
                  <PersonIcon fontSize="small" />

                  <Box sx={{ px: 1, fontSize: 16 }}>Recruited by</Box>

                  <Box sx={{ fontSize: 18, fontWeight: 'bold' }}>
                    {progress.RecAgtName}
                  </Box>

                  <Box
                    sx={{
                      pl: 1,
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: '#b26500',
                    }}
                  >
                    {progress.RecAgtNo}
                  </Box>
                </Box>
              ) : null}

              {progress.CreatedDate ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #fafafa',
                    borderRadius: 2,
                  }}
                >
                  <ScheduleIcon fontSize="small" />

                  <Box sx={{ px: 1, fontSize: 16 }}>
                    Completed registration on{' '}
                    {new Date(progress.CreatedDate).toDateString()}
                  </Box>

                  <Box
                    sx={{ fontSize: 12, fontStyle: 'italic', color: '#9b9b9b' }}
                  >
                    {formatDistanceToNowStrict(new Date(progress.CreatedDate), {
                      addSuffix: true,
                    })}
                  </Box>
                </Box>
              ) : null}

              {progress.Email ? (
                <Box
                  component="a"
                  href={`mailto:${progress.Email}?subject=Getting Started&body=Hello ${progress.AgtName} (${progress.AgtNo}),`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    textDecorationLine: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#2196f344',
                    borderRadius: 2,
                    color: '#2196f3',
                    backgroundColor: '#2196f304',
                    transition: 'all 0.2s',
                    ':hover': {
                      color: '#1769aa',
                      borderColor: '#1769aa',
                      backgroundColor: '#1769aa08',
                    },
                  }}
                >
                  <ContactMailIcon />

                  <Box sx={{ flex: 1, px: 1 }}>{progress.Email}</Box>

                  <Box sx={{ px: 1, fontSize: 12 }}>Open new message</Box>

                  <OpenInNewIcon fontSize="small" />
                </Box>
              ) : null}

              {progress.Phone ? (
                <Box
                  component="a"
                  href={`tel:${progress.Phone.replace(/\D/g, '')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    textDecorationLine: 'none',
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: '#2196f344',
                    borderRadius: 2,
                    color: '#2196f3',
                    backgroundColor: '#2196f304',
                    transition: 'all 0.2s',
                    ':hover': {
                      color: '#1769aa',
                      borderColor: '#1769aa',
                      backgroundColor: '#1769aa08',
                    },
                  }}
                >
                  <CallIcon />

                  <Box sx={{ flex: 1, px: 1 }}>{progress.Phone}</Box>

                  <Box sx={{ px: 1, fontSize: 12 }}>Open new call</Box>

                  <OpenInNewIcon fontSize="small" />
                </Box>
              ) : null}

              <Box>
                {tasks.map((task, index) => {
                  let isCompleted = false;
                  if (task.key === 'Fastract') {
                    isCompleted = true;
                  } else if (task.key in progress) {
                    // @ts-ignore
                    isCompleted = Boolean(progress[task.key]);
                  }

                  return (
                    <Box key={task.Title}>
                      {index === 0 ? null : (
                        <Box
                          sx={{
                            marginLeft: 2.6,
                            height: 12,
                            width: 2,
                            backgroundColor: '#00000022',
                          }}
                        />
                      )}

                      <Paper
                        elevation={0}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: 1,
                          textDecorationLine: 'none',
                          borderWidth: 2,
                          borderStyle: 'solid',
                          borderColor: isCompleted ? '#00C86888' : '#03182E22',
                          backgroundColor: isCompleted ? '#00C86804' : '#fff',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {isCompleted ? (
                            <CheckCircleIcon color="secondary" />
                          ) : (
                            <RadioButtonUncheckedIcon
                              sx={{ color: '#9b9b9b' }}
                            />
                          )}
                        </Box>

                        <Box sx={{ flex: 1, pl: 1, pr: 1 }}>
                          <Box sx={{ fontWeight: 'bold', color: '#03182E' }}>
                            {task.Title}
                          </Box>
                          <Box sx={{ fontSize: 12, color: '#4a4a4a' }}>
                            {task.Description}
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  );
                })}
              </Box>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
