import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { AWS_GROUP, WalletData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { Billing } from './billing';
import { CreditCards } from './credit-cards';

export function Wallet(props: { fullWidth?: boolean }) {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // Hooks
  const { checkPermissions } = usePermissions();
  const queryClient = useQueryClient();

  let AgtNo = user?.getUsername();
  // Admins with these permissions can view Wallet Info
  const isAdmin = checkPermissions([
    AWS_GROUP['Contracting'],
    AWS_GROUP['AgentDataAdmin'],
    AWS_GROUP['Accounting'],
  ]);
  if (impersonatedAgent !== null && isAdmin) {
    AgtNo = impersonatedAgent?.AgtNo || undefined;
  } else if (impersonatedAgent !== null) {
    AgtNo = undefined;
  }

  // Query - Bank Accounts and Credit Cards
  let path = '/billing/accounts';
  if (isAdmin) {
    path = '/admin/billing/accounts';
  }
  const queryKey = [path, { isAdmin, AgtNo }];
  const query = useQuery({
    enabled: isAgt(AgtNo),
    queryKey,
    queryFn: async () => {
      const body = isAdmin ? { AgtNo } : {};
      const response: {
        data: WalletData;
      } = await API.post('ContractingAPI', path, { body });
      return response.data;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Agreement
  const pathAgree = '/billing/lead-bill/agreement';
  const queryKeyAgree = [pathAgree];
  const queryAgree = useQuery({
    enabled: impersonatedAgent === null && isAgt(user?.getUsername()),
    queryKey: queryKeyAgree,
    queryFn: async () => {
      const response: {
        data: { AgrmtText: string; HasAgreed: boolean };
      } = await API.post('ContractingAPI', pathAgree, {});
      return response.data;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Agree to the T&C
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post(
        'ContractingAPI',
        '/billing/lead-bill/agreement/agree',
        {},
      );
    },
    onSuccess: async () => {
      // Refetch Bank Accounts and Credit Cards
      await queryClient.invalidateQueries({ queryKey });

      // Refetch Agreement Info
      await queryClient.invalidateQueries({ queryKey: queryKeyAgree });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Cannot edit info for Impersonated agents
  const disableEditing = !isAgt(AgtNo) || AgtNo !== user?.getUsername();

  let impersonateWarning: JSX.Element | null = null;
  if (!isAdmin && impersonatedAgent !== null) {
    impersonateWarning = (
      <Alert severity="info">Impersonation does not work on this page.</Alert>
    );
  }

  const isLoading =
    Boolean(query.isLoading && query.fetchStatus !== 'idle') ||
    Boolean(queryAgree.isLoading && queryAgree.fetchStatus !== 'idle');

  return (
    <RouteContainer
      routeTitle="My Wallet"
      withContainer
      maxWidth="md"
      loading={isLoading}
      hasError={query.isError}
      fullWidth={props.fullWidth}
    >
      <Box>
        <Stack spacing={2} sx={{ pt: 2, pb: 16 }}>
          {impersonateWarning}

          {isAgt(user?.getUsername()) &&
          impersonatedAgent === null &&
          queryAgree.data &&
          !queryAgree.data.HasAgreed ? (
            <Alert
              severity="warning"
              action={
                <Button
                  disabled={mutation.isLoading || mutation.isError}
                  onClick={() => mutation.mutate()}
                  startIcon={
                    mutation.isLoading ? <CircularProgress size={16} /> : null
                  }
                >
                  Agree
                </Button>
              }
            >
              <DisplayMarkup markup={queryAgree.data.AgrmtText} />
            </Alert>
          ) : null}

          <Billing
            data={query.data?.billing}
            queryKey={queryKey}
            HasAgreed={queryAgree.data?.HasAgreed || false}
            disableEditing={disableEditing}
          />

          <CreditCards
            data={query.data?.['credit-cards']}
            queryKey={queryKey}
            disableEditing={disableEditing}
          />
        </Stack>
      </Box>
    </RouteContainer>
  );
}
