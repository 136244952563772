import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForwardIcon from '@mui/icons-material/Forward';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import orderBy from 'lodash/orderBy';
import { CarrierData, MyContractData } from '../../types';

export enum ContractStatus {
  'waiting-on-you' = 'waiting-on-you',
  'waiting-on-you-to-write-business' = 'waiting-on-you-to-write-business',
  'waiting-on-us' = 'waiting-on-us',
  'waiting-on-carrier' = 'waiting-on-carrier',
  active = 'active',
  closed = 'closed',
  unknown = 'unknown',
}

export function ContractItem(props: {
  item: MyContractData;
  carrier: CarrierData;
  onClick: () => void;
  status: ContractStatus;
  disabled?: boolean;
}) {
  // Props
  const { carrier, item } = props;

  const isActive = props.status === ContractStatus['active'];
  const isClosed = props.status === ContractStatus['closed'];

  return (
    <Box
      component="li"
      tabIndex={0}
      onClick={props.disabled ? () => {} : props.onClick}
      onKeyDown={(event) => {
        if (!props.disabled && event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        pl: 1,
        pr: 2,
        minHeight: 64,
        cursor: props.disabled ? 'inherit' : 'pointer',
        borderTop: '1px solid #00000011',
        backgroundColor: isActive ? '#00C86811' : isClosed ? '#fafafa' : '#fff',
        transition: 'all 0.2s',
        ':first-of-type': {
          borderTop: 'none',
        },
        ':nth-of-type(even)': {
          backgroundColor: isActive ? '#00C86818' : '#fcfcfc',
        },
        ':hover': {
          backgroundColor: isActive ? '#00C86824' : '#f5f5f5',
        },
        ':hover .contract-item-forward-icon': {
          color: '#03182E',
          mr: 0,
        },
      }}
    >
      <Box sx={{ minHeight: 64, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: {
              xs: 'flex-start',
              md: 'center',
            },
          }}
        >
          <Box sx={{ padding: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 88,
              }}
            >
              {carrier.Logo ? (
                <img
                  loading="lazy"
                  src={carrier.Logo}
                  alt={carrier.Carrier || 'Carrier'}
                  style={{ maxWidth: 88, maxHeight: 52 }}
                />
              ) : (
                <Box sx={{ width: 88, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
                  <Box
                    sx={{
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: '#b26500',
                      px: 1,
                    }}
                  >
                    {carrier.Carrier}
                  </Box>
                  <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ flex: 1, padding: 1 }}>
            <Box sx={{ fontSize: 18 }}>{carrier.FullName}</Box>
            {item.CreatedDate ? (
              <Box sx={{ fontSize: 11, fontStyle: 'italic', color: '#7a7a7a' }}>
                Created{' '}
                {new Date(
                  item.CreatedDate.substring(0, 10) + 'T00:00'
                ).toDateString()}
              </Box>
            ) : null}
          </Box>
        </Box>

        {item.ProcStatus &&
        Boolean(props.status !== ContractStatus['active']) ? (
          <Tooltip arrow placement="bottom" title="Contract Processing Status">
            <Box
              sx={{
                pr: 1,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: 12,
                fontFamily: 'Roboto Mono',
              }}
            >
              {item.ProcStatus}
            </Box>
          </Tooltip>
        ) : null}

        {props.status === ContractStatus['waiting-on-you'] &&
        item.Signed === true ? (
          <Tooltip arrow placement="bottom" title="Request has been sent">
            <Box
              sx={{
                pr: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <HistoryEduIcon fontSize="small" />
            </Box>
          </Tooltip>
        ) : null}

        {props.status === ContractStatus['waiting-on-you'] &&
        item.Signed === false ? (
          <Tooltip arrow placement="bottom" title="Needs to be finished by you">
            <Box
              sx={{
                pr: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <HistoryEduIcon fontSize="small" color="disabled" />
            </Box>
          </Tooltip>
        ) : null}

        <Box
          sx={{
            pr: 0.4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {isClosed ? (
            <HideSourceIcon sx={{ color: '#03182E22' }} />
          ) : isActive ? (
            <CheckCircleIcon color="secondary" />
          ) : props.disabled ? null : (
            <ForwardIcon
              className="contract-item-forward-icon"
              sx={{ transition: 'all 0.2s', color: '#03182E22' }}
            />
          )}
        </Box>
      </Box>

      {item.PendingReasons && item.PendingReasons.length ? (
        <Box sx={{ pb: 1 }}>
          <Paper
            elevation={0}
            sx={{ border: '1px solid #00000022', overflow: 'hidden' }}
          >
            <Box sx={{ p: 1, fontWeight: 'bold' }}>
              Pending Reasons ({item.PendingReasons.length})
            </Box>

            <Box>
              {orderBy(item.PendingReasons, 'AssignDate', 'desc').map(
                (reason, index) => {
                  return (
                    <Box
                      key={reason.PendID}
                      sx={{ display: 'flex', borderTop: '1px solid #00000011' }}
                    >
                      <Box
                        sx={{
                          minWidth: 36,
                          borderRight: '1px solid #00000011',
                          backgroundColor: '#ED6C0211',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Mono',
                            textTransform: 'uppercase',
                            color: '#ED6C02',
                          }}
                        >
                          {index + 1}
                        </Box>
                      </Box>

                      <Box sx={{ px: 1, py: 0.6, fontSize: 16, flex: 1 }}>
                        {reason.Reason}
                      </Box>
                    </Box>
                  );
                }
              )}
            </Box>
          </Paper>
        </Box>
      ) : null}
    </Box>
  );
}
