import Box from '@mui/material/Box';
import { orderBy } from 'lodash';
import React from 'react';
import { PaperExpandable } from '../../../components/paper-expandable';
import { searchBy } from '../../../components/search-field';
import { RequestChangeData } from '../../../types';
import { ManageChangesItem } from './manage-changes-item';

export function ManageChanges(props: {
  data: RequestChangeData[];
  onSelect: (item: RequestChangeData) => void;
}) {
  // State
  const [searchString, setSearchString] = React.useState('');

  // Ordering
  const orderedItems = orderBy(props.data, 'CreatedDate', 'desc');
  // Searching
  const searchedItems = searchBy(orderedItems, searchString);

  return (
    <PaperExpandable
      open
      title="Pending Changes"
      description="Changes you have requested for these Agents are waiting to be reviewed by an Admin."
      count={props.data.length}
      searchString={searchString}
      onChangeSearch={(event) => setSearchString(event.target.value)}
    >
      {searchedItems.length > 0 ? (
        <Box component="ul" sx={{ p: 0, m: 0 }}>
          {searchedItems.map((item) => {
            return (
              <ManageChangesItem
                key={item.RequestID}
                item={item}
                onClick={() => props.onSelect(item)}
              />
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            p: 4,
            textAlign: 'center',
            fontFamily: 'Roboto Mono',
            color: '#9b9b9b',
          }}
        >
          No hierarchy/level changes are waiting to be reviewed by an admin
        </Box>
      )}
    </PaperExpandable>
  );
}
