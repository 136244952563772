import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AgentW9 } from '../../components/agent-w9';
import { Agreement } from '../../components/agreement';
import { Contract } from '../../components/contract';
import { PaperExpandable } from '../../components/paper-expandable';
import { RouteContainer } from '../../components/route-container';
import { AuthContext } from '../../contexts/auth-context';
import { CarrierData, MyContractsData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { ContractItem, ContractStatus } from './contract-item';

export function Contracts() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [CarrierID, setCarrierID] = React.useState<CarrierData['CarrierID']>();
  const [AgtDocID, setAgtDocID] = React.useState<number | null>(null);

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Carriers
  const pathCarriers = '/carrier';
  const queryCarriers = useQuery({
    queryKey: [pathCarriers],
    queryFn: async () => {
      const response: {
        data: CarrierData[];
      } = await API.post('ContractingAPI', pathCarriers, {
        body: {},
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - My Contracts
  const pathContracts = '/documents/my-contracts';
  const pathContractsKey = [pathContracts, { AgtNo }];
  const queryContracts = useQuery({
    queryKey: pathContractsKey,
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: MyContractsData;
        } = await API.post('ContractingAPI', pathContracts, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return {
        'waiting-on-you': [],
        'waiting-on-you-to-write-business': [],
        'waiting-on-us': [],
        'waiting-on-carrier': [],
        active: [],
        closed: [],
        unknown: [],
      };
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <RouteContainer
      routeTitle="My Contracts"
      withContainer
      loading={queryCarriers.isLoading || queryContracts.isLoading}
      hasError={queryCarriers.isError || queryContracts.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          <PaperExpandable
            title="Waiting on You"
            description="These contracts are waiting for you to take an action"
            count={queryContracts.data?.['waiting-on-you'].length}
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.['waiting-on-you'].map((item) => {
                const carrier = queryCarriers.data?.find(
                  (i) => i.CarrierID === item.CarrierID,
                );

                if (carrier) {
                  return (
                    <ContractItem
                      key={item.Name}
                      item={item}
                      carrier={carrier}
                      status={ContractStatus['waiting-on-you']}
                      onClick={() => {
                        setCarrierID(carrier.CarrierID);
                        setAgtDocID(item.AgtDocID);
                      }}
                      disabled={
                        item.AgtDocID === null || impersonatedAgent !== null
                      }
                    />
                  );
                } else {
                  return <Box key={item.Name} />;
                }
              })}
            </Box>
          </PaperExpandable>

          <PaperExpandable
            title="Waiting on You to Write Business"
            description="The carrier will not issue your writing number until the first piece of business is submitted"
            count={
              queryContracts.data?.['waiting-on-you-to-write-business'].length
            }
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.['waiting-on-you-to-write-business'].map(
                (item) => {
                  const carrier = queryCarriers.data?.find(
                    (i) => i.CarrierID === item.CarrierID,
                  );

                  if (carrier) {
                    return (
                      <ContractItem
                        key={item.Name}
                        item={item}
                        carrier={carrier}
                        status={
                          ContractStatus['waiting-on-you-to-write-business']
                        }
                        onClick={() => {
                          setCarrierID(carrier.CarrierID);
                          setAgtDocID(item.AgtDocID);
                        }}
                        disabled={
                          item.AgtDocID === null || impersonatedAgent !== null
                        }
                      />
                    );
                  } else {
                    return <Box key={item.Name} />;
                  }
                },
              )}
            </Box>
          </PaperExpandable>

          <PaperExpandable
            title="Waiting on Us"
            description="These contracts are being reviewed by our Admins"
            count={queryContracts.data?.['waiting-on-us'].length}
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.['waiting-on-us'].map((item) => {
                const carrier = queryCarriers.data?.find(
                  (i) => i.CarrierID === item.CarrierID,
                );

                if (carrier) {
                  return (
                    <ContractItem
                      key={item.Name}
                      item={item}
                      carrier={carrier}
                      status={ContractStatus['waiting-on-us']}
                      onClick={() => {
                        setCarrierID(carrier.CarrierID);
                        setAgtDocID(item.AgtDocID);
                      }}
                      disabled={
                        item.AgtDocID === null || impersonatedAgent !== null
                      }
                    />
                  );
                } else {
                  return <Box key={item.Name} />;
                }
              })}
            </Box>
          </PaperExpandable>

          <PaperExpandable
            title="Waiting on Carrier"
            description="Once these contracts have been accepted by the Carrier you will receive a Writing Number"
            count={queryContracts.data?.['waiting-on-carrier'].length}
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.['waiting-on-carrier'].map((item) => {
                const carrier = queryCarriers.data?.find(
                  (i) => i.CarrierID === item.CarrierID,
                );

                if (carrier) {
                  return (
                    <ContractItem
                      key={item.Name}
                      item={item}
                      carrier={carrier}
                      status={ContractStatus['waiting-on-carrier']}
                      onClick={() => {
                        setCarrierID(carrier.CarrierID);
                        setAgtDocID(item.AgtDocID);
                      }}
                      disabled={
                        item.AgtDocID === null || impersonatedAgent !== null
                      }
                    />
                  );
                } else {
                  return <Box key={item.Name} />;
                }
              })}
            </Box>
          </PaperExpandable>

          <PaperExpandable
            title="Active"
            description="These contracts are complete and you have received a Writing Number from the Carrier"
            count={queryContracts.data?.active.length}
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.active.map((item) => {
                const carrier = queryCarriers.data?.find(
                  (i) => i.CarrierID === item.CarrierID,
                );

                if (carrier) {
                  return (
                    <ContractItem
                      key={item.Name}
                      item={item}
                      carrier={carrier}
                      status={ContractStatus['active']}
                      onClick={() => {
                        setCarrierID(carrier.CarrierID);
                        setAgtDocID(item.AgtDocID);
                      }}
                      disabled={
                        item.AgtDocID === null || impersonatedAgent !== null
                      }
                    />
                  );
                } else {
                  return <Box key={item.Name} />;
                }
              })}
            </Box>
          </PaperExpandable>

          <PaperExpandable
            title="Closed"
            description="These contracts are no longer being pursued"
            count={queryContracts.data?.closed.length}
          >
            <Box component="ul" sx={{ p: 0, m: 0 }}>
              {queryContracts.data?.closed.map((item) => {
                const carrier = queryCarriers.data?.find(
                  (i) => i.CarrierID === item.CarrierID,
                );

                if (carrier) {
                  return (
                    <ContractItem
                      key={item.Name}
                      item={item}
                      carrier={carrier}
                      status={ContractStatus['closed']}
                      onClick={() => {
                        setCarrierID(carrier.CarrierID);
                        setAgtDocID(item.AgtDocID);
                      }}
                      disabled={
                        item.AgtDocID === null || impersonatedAgent !== null
                      }
                    />
                  );
                } else {
                  return <Box key={item.Name} />;
                }
              })}
            </Box>
          </PaperExpandable>

          {impersonatedAgent === null ? <Agreement /> : null}
          {impersonatedAgent === null ? <AgentW9 /> : null}
        </Stack>

        {impersonatedAgent === null ? (
          <Contract
            carrier={queryCarriers.data?.find((i) => i.CarrierID === CarrierID)}
            AgtDocID={AgtDocID}
            queryKey={pathContractsKey}
            onClose={() => {
              setCarrierID(undefined);
              setAgtDocID(null);
            }}
          />
        ) : null}
      </Box>
    </RouteContainer>
  );
}
