import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import React from 'react';
import { SearchField, searchBy } from '../../../components/search-field';
import { TableColumnHeaders } from '../../../components/table-column-headers';
import { CarrierData, ColumnHeader, PendingContractData } from '../../../types';
import { subarray } from '../../../utils/subarray';
import { ManageContractsRow } from './manage-contracts-row';

const tableColumns: ColumnHeader<PendingContractData>[] = [
  { key: 'CreatedDate', label: 'Date', align: 'left' },
  { key: 'Carrier', align: 'left' },
  { key: 'ProcStatus', label: 'Status', align: 'right' },
  { key: 'AgtNo', align: 'right' },
  { key: 'AgtName', align: 'left' },
  { key: 'PendingReasons', label: 'Reasons' },
];

export function ManageContracts(props: {
  data: PendingContractData[];
  carriers: CarrierData[];
  onSelect: (item: PendingContractData) => void;
}) {
  // State
  const [carriers, setCarriers] = React.useState<string[]>([]);
  const [searchString, setSearchString] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [sort, setSort] = React.useState<'asc' | 'desc'>('asc');
  const [sortBy, setSortBy] =
    React.useState<ColumnHeader<PendingContractData>['key']>('CreatedDate');

  const handleFilter = async (params: { carriers?: string[] }) => {
    // Filter on carriers
    if (params.carriers !== undefined) {
      const newCarriers = uniq(params.carriers);
      setCarriers(newCarriers);
    }
  };

  // Used by TableHead
  const handleSort =
    (property: ColumnHeader<PendingContractData>['key']) =>
    (event: React.MouseEvent<unknown>) => {
      if (property === sortBy) {
        // User is changing sort of current column
        setSort((currentState) => (currentState === 'asc' ? 'desc' : 'asc'));
      } else {
        // User is changing sort of new column
        setSortBy(property);
        setSort('asc');
      }
    };

  // Used by TablePagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Used by TablePagination
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const items = props.data;
  // Filter by Carriers
  let dataCarriers = items;
  if (carriers.length) {
    dataCarriers = items.filter(
      (i) => i.Carrier && carriers.includes(i.Carrier)
    );
  }
  // Searching
  const searchedItems = searchBy(dataCarriers, searchString);
  // Sorting
  const sortedItems = orderBy(searchedItems, sortBy, sort);
  // Paging
  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  const renderItems = subarray({ data: sortedItems, start, end });

  const options = props.carriers
    ? orderBy(
        props.carriers.map((carrier) => ({
          title: carrier.Carrier,
          FullName: carrier.FullName,
        })),
        [(i) => i.FullName.toLowerCase()]
      )
    : [];

  const filterResults = (
    <Stack direction="row" alignItems="center" sx={{ px: 2 }}>
      <SearchField
        id="admin-search-contracts"
        placeholder="Search contracts"
        value={searchString}
        onChange={(event) => {
          setSearchString(event.target.value);
          // Reset page
          setPage(0);
        }}
      />

      <Box sx={{ flex: 1 }} />

      <TablePagination
        rowsPerPageOptions={[100, 500, 1000]}
        component="div"
        count={sortedItems.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );

  return (
    <Box>
      <Grid container spacing={2} sx={{ py: 2 }}>
        <Grid item xs={12}>
          <Autocomplete
            id="admin-select-carriers"
            multiple
            fullWidth
            options={options}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) =>
              option.title === value.title
            }
            getOptionLabel={(option) => option.title}
            getOptionDisabled={(option) => {
              const count = items.filter(
                (i) => i.Carrier === option.title
              ).length;

              if (count === 0) {
                return true;
              } else {
                return false;
              }
            }}
            renderOption={(props, option, { selected }) => {
              const count = items.filter(
                (i) => i.Carrier === option.title
              ).length;
              return (
                <Box
                  component="li"
                  {...props}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Box
                    sx={{
                      pr: 1,
                      fontFamily: 'Roboto Mono',
                      fontWeight: 'bold',
                    }}
                  >
                    {option.title}
                  </Box>
                  <Box sx={{ flex: 1 }}>{option.FullName}</Box>
                  <Box
                    sx={{
                      mr: 1,
                      height: 28,
                      width: 28,
                      borderRadius: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#2196f311',
                      fontWeight: 'bold',
                      color: '#2196f3',
                      border: '1px solid #2196f3',
                    }}
                  >
                    {count}
                  </Box>
                </Box>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Carriers" />}
            filterOptions={createFilterOptions({
              stringify: (option) => option.title + ' ' + option.FullName,
            })}
            value={
              carriers.map((i) => ({
                title: i,
                FullName: options.find((o) => o.title === i)?.FullName,
              })) || null
            }
            onChange={(event, value, reason, details) => {
              if (value.length) {
                const newSelected = value.map((i) => i.title);
                handleFilter({ carriers: newSelected });
              } else {
                handleFilter({ carriers: [] });
              }
              // Reset page
              setPage(0);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{ border: '1px solid #ccc', overflow: 'hidden' }}
          >
            {filterResults}

            <Divider />

            <TableContainer sx={{ height: '50dvh' }}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableColumnHeaders
                      sort={sort}
                      sortBy={sortBy}
                      tableColumns={tableColumns}
                      sortHandler={handleSort}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderItems.map((row) => {
                    return (
                      <ManageContractsRow
                        key={row.AgtDocID}
                        row={row}
                        tableColumns={tableColumns}
                        carrier={props.carriers.find(
                          (i) => i.CarrierID === row.CarrierID
                        )}
                        carriers={props.carriers}
                        onClick={() => props.onSelect(row)}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
