import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as AllianceLogo } from '../../assets/alliance-logo.svg';
import { AuthContext } from '../../contexts/auth-context';
import { materialTheme } from '../../theme';

export function SignIn() {
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const navigate = useNavigate();

  React.useEffect(() => {
    if (state.user !== undefined) {
      navigate('/home');
    }
  }, [state.user, navigate]);

  // Forgot Password Link
  const linkForForgot = `https://${process.env.REACT_APP_AWS_DOMAIN}/forgotPassword?client_id=${process.env.REACT_APP_AWS_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_AWS_REDIRECT_SIGN_IN}`;

  return (
    <Box
      sx={{
        height: '100dvh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper sx={{ overflow: 'hidden' }}>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: materialTheme.palette.primary.main,
            px: 4,
            py: 2,
            minWidth: 320,
            minHeight: 180,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          }}
        >
          <AllianceLogo height={102} width={256} />
        </Paper>

        <Stack spacing={2} sx={{ p: 2 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ textAlign: 'center', fontWeight: 'bold', color: '#b26500' }}
          >
            Your contracting dashboard
          </Typography>

          <Paper
            elevation={0}
            component="button"
            onClick={() => Auth.federatedSignIn()}
            sx={{
              p: 1,
              minHeight: 52,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecorationLine: 'none',
              fontFamily: 'Roboto',
              width: '100%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#00C868aa',
              backgroundColor: '#00C86877',
              cursor: 'pointer',
              transition: 'all 0.2s',
              ':hover': {
                transform: 'scale(1.01)',
                borderColor: '#03182Ecc',
                backgroundColor: '#00C86855',
              },
            }}
          >
            <Box sx={{ fontSize: 22, fontWeight: 'bold' }}>Sign In</Box>
          </Paper>

          <Paper
            elevation={0}
            component="a"
            href={linkForForgot}
            sx={{
              p: 1,
              minHeight: 52,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecorationLine: 'none',
              fontFamily: 'Roboto',
              width: '100%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#03182E22',
              backgroundColor: '#fff',
              cursor: 'pointer',
              transition: 'all 0.2s',
              ':hover': {
                transform: 'scale(1.01)',
                borderColor: '#03182Ecc',
              },
            }}
          >
            <Box sx={{ fontSize: 22, fontWeight: 'bold' }}>Forgot Password</Box>
          </Paper>

          <Paper
            elevation={0}
            component={Link}
            to="/sign-up/the-alliance"
            sx={{
              p: 1,
              minHeight: 52,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecorationLine: 'none',
              fontFamily: 'Roboto',
              width: '100%',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: '#2196f3aa',
              backgroundColor: '#2196f377',
              cursor: 'pointer',
              transition: 'all 0.2s',
              ':hover': {
                transform: 'scale(1.01)',
                borderColor: '#03182Ecc',
                backgroundColor: '#2196f355',
              },
            }}
          >
            <Box sx={{ fontSize: 22, fontWeight: 'bold' }}>Sign Up</Box>
          </Paper>
        </Stack>
      </Paper>
    </Box>
  );
}
