import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

const DocIframe = React.memo(function DocIframe(props: { title: string }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress color="info" /> : null}

      <iframe
        className="embed-responsive-item"
        id="load_profile"
        name="load_profile"
        title={props.title}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

export function CustomerProfileIframe(props: {
  action:
    | '/customer/manage'
    | '/customer/addPayment'
    | '/customer/addShipping'
    | '/customer/editPayment'
    | '/customer/editShipping';
  iframeToken: string;
  title: string;
  paymentProfileId?: string;
  shippingAddressId?: string;
  onReceiveCommunication: (action: 'cancel' | 'successfulSave') => void;
}) {
  // Ref
  const formEl = React.useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    (window as any).CommunicationHandler = {};
    (window as any).CommunicationHandler.onReceiveCommunication = (data: {
      qstr: string;
      parent: string;
    }) => {
      if (data.qstr && data.qstr === 'action=cancel') {
        props.onReceiveCommunication('cancel');
      } else if (data.qstr && data.qstr === 'action=successfulSave') {
        props.onReceiveCommunication('successfulSave');
      }
    };
  }, [props]);

  React.useEffect(() => {
    if (formEl && formEl.current) {
      formEl.current.submit();
    }
  }, [formEl]);

  const action = process.env.REACT_APP_ACCEPT_PROFILES + props.action;

  return (
    <Box
      sx={{
        minHeight: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DocIframe title={props.title} />

      <form
        action={action}
        id="send_token"
        method="post"
        ref={formEl}
        target="load_profile"
      >
        <input name="token" type="hidden" value={props.iframeToken} />

        {props.paymentProfileId ? (
          <input
            name="paymentProfileId"
            type="hidden"
            value={props.paymentProfileId}
          />
        ) : null}

        {props.shippingAddressId ? (
          <input
            name="shippingAddressId"
            type="hidden"
            value={props.shippingAddressId}
          />
        ) : null}
      </form>
    </Box>
  );
}
