import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { AgentWrtNoData, CarrierData } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { WrtNoItem } from './wrt-no-item';
import { WrtNoItemCreate } from './wrt-no-item-create';

export function WrtNoCarrier(props: {
  AgtNo: string | undefined;
  carrier: CarrierData;
  numbers: AgentWrtNoData[];
  isAdmin?: boolean;
}) {
  // Props
  const { carrier, numbers } = props;
  // State
  const [open, setOpen] = React.useState(false);
  const [creating, setCreating] = React.useState(false);

  React.useEffect(() => {
    if (numbers.length === 0) {
      setCreating(true);
    }
  }, [numbers]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isActive = numbers.length;

  const canAdd = !creating && numbers.length !== carrier.MaxWrtCarNo;

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        tabIndex={0}
        onClick={isAgt(props.AgtNo) ? () => handleClickOpen() : () => {}}
        onKeyDown={(event) => {
          if (isAgt(props.AgtNo) && event.key === 'Enter') {
            handleClickOpen();
          }
        }}
        sx={{
          cursor: isAgt(props.AgtNo) ? 'pointer' : 'default',
          p: 1,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderWidth: isActive ? 2 : 1,
          borderStyle: 'solid',
          borderColor: isActive ? '#00C86888' : '#03182E22',
          backgroundColor: isActive ? '#00C86804' : '#fff',
          transition: 'all 0.2s',
          ':hover': {
            borderColor: '#03182E77',
          },
          ':hover .empty-writing-no': {
            color: '#d1d1d1',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 66,
            minHeight: 44,
          }}
        >
          {carrier.Logo ? (
            <img
              loading="lazy"
              src={carrier.Logo}
              alt={carrier.Carrier || 'Carrier'}
              style={{ maxWidth: 66, maxHeight: 44 }}
            />
          ) : (
            <Box sx={{ width: 66, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                  fontSize: 14,
                  color: '#b26500',
                  px: 1,
                }}
              >
                {carrier.Carrier}
              </Box>
              <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
            </Box>
          )}
        </Box>

        <Box sx={{ pl: 1, flex: 1 }}>
          <Box sx={{ fontSize: 22, fontFamily: 'Roboto Mono' }}>
            {numbers.length === 0 ? (
              <Box
                className="empty-writing-no"
                sx={{ color: '#efefef', transition: 'all 0.2s' }}
              >
                000000000
              </Box>
            ) : (
              numbers.map((wrtNo) => {
                return <Box key={wrtNo.AgtCarInfoId}>{wrtNo.WrtCarNo}</Box>;
              })
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                mr: 0.6,
                fontSize: 11,
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
              }}
            >
              {carrier.Carrier}
            </Box>

            <Box sx={{ fontSize: 12 }}>{carrier.FullName}</Box>
          </Box>
        </Box>

        {isAgt(props.AgtNo) ? (
          <Tooltip
            title={isActive ? 'Edit writing number' : 'Add writing number'}
            placement="left"
            arrow
          >
            <Box
              sx={{
                p: 0.6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #00000011',
                borderRadius: 2,
                color: '#a1a1a1',
                transition: 'all 0.2s',
                ':hover': { color: '#212121' },
              }}
            >
              <EditIcon fontSize="small" />
            </Box>
          </Tooltip>
        ) : null}
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'inline',
              fontFamily: 'Roboto Mono',
              fontSize: 14,
              fontWeight: 'bold',
              mr: 1,
            }}
          >
            {carrier.Carrier}
          </Box>

          <Box sx={{ flex: 1 }}>{carrier.FullName}</Box>

          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingBottom={2}>
            Manually add/edit {props.isAdmin ? "agent's" : 'your'} writing
            number for this Carrier. Any changes made here may be overwritten by
            updates received from the Carrier.
          </DialogContentText>

          <Stack spacing={1}>
            {numbers.map((number) => {
              return (
                <WrtNoItem
                  key={number.AgtCarInfoId}
                  AgtNo={props.AgtNo}
                  carrier={props.carrier}
                  number={number}
                />
              );
            })}

            {creating ? (
              <WrtNoItemCreate
                AgtNo={props.AgtNo}
                carrier={props.carrier}
                onSuccess={() => setCreating(false)}
              />
            ) : canAdd ? (
              <Button
                fullWidth
                size="small"
                variant="outlined"
                onClick={() => setCreating(true)}
              >
                Add writing number
              </Button>
            ) : null}
          </Stack>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
