import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { materialTheme } from '../../theme';
import { LicenseData } from '../../types';
import { isAgt } from '../../utils/is-agent';
import { LicenseInfoDelete } from './license-info-delete';
import { LicenseInfoDetails } from './license-info-details';
import { LicenseInfoEdit } from './license-info-edit';

export function LicenseInfo(props: {
  AgtNo: string | undefined;
  license: LicenseData | undefined;
  onClose: () => void;
}) {
  // Props
  const { license } = props;
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [status, setStatus] = React.useState<'updating' | 'deleting'>();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDrawer = () => {
    setStatus(undefined);

    props.onClose();
  };

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={license !== undefined}
      onClose={handleCloseDrawer}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: 600, lg: 720 },
          height: '100dvh',
        },
      }}
    >
      {license !== undefined ? (
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton
              aria-label="close"
              sx={{ mr: 2 }}
              onClick={handleCloseDrawer}
            >
              <CloseIcon />
            </IconButton>

            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" noWrap component="div">
                {license.State}
              </Typography>

              {license.ResStatus === 'R' ? (
                <Box sx={{ paddingLeft: 1 }}>
                  <Chip
                    label="Resident"
                    size="small"
                    variant="outlined"
                    color="secondary"
                    icon={<HomeIcon />}
                    sx={{
                      borderRadius: 1,
                      color: materialTheme.palette.primary.main,
                    }}
                  />
                </Box>
              ) : null}
            </Box>

            <Box
              sx={{
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
                fontSize: 14,
                color: '#b26500',
              }}
            >
              {license.LicNo}
            </Box>

            {status === 'updating' ? (
              <Box sx={{ pl: 1 }}>
                <Tooltip title="Cancel" placement="left" arrow>
                  <IconButton size="small" onClick={() => setStatus(undefined)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : license.IsNIPR || !isAgt(props.AgtNo) ? null : (
              <Box sx={{ pl: 1 }}>
                <Tooltip title="Manage license" placement="left" arrow>
                  <IconButton
                    size="small"
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                >
                  <MenuItem
                    onClick={() => {
                      setStatus('updating');
                      handleClose();
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatus('deleting');
                      handleClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>

          <Divider />

          {status === 'deleting' ? (
            <LicenseInfoDelete
              AgtNo={props.AgtNo}
              license={license}
              onClose={() => setStatus(undefined)}
              onSuccess={() => handleCloseDrawer()}
            />
          ) : null}

          {status === 'updating' ? (
            <LicenseInfoEdit
              AgtNo={props.AgtNo}
              license={license}
              onSuccess={() => setStatus(undefined)}
            />
          ) : (
            <LicenseInfoDetails license={license} />
          )}
        </Box>
      ) : null}
    </Drawer>
  );
}
