// Validate Email Address
export function validateEmail(params: { email: string }) {
  const invalidEmail =
    params.email !== undefined &&
    params.email.length > 0 &&
    !/([a-zA-Z0-9_]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$)*/.test(
      params.email,
    );

  return invalidEmail;
}

// Validate Password
export function validatePassword(params: { password: string }) {
  const invalidPassword =
    params.password.length > 0 &&
    !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])[A-Za-z\d^$*.[\]{}()?"!@#%&/\\,><':;|_~`]{14,}$/.test(
      params.password,
    );

  return invalidPassword;
}

// Validate SSN
export function validateSSN(params: { ssn: string }) {
  const invalidSSN =
    params.ssn === '123-45-6789' ||
    params.ssn === '111-11-1111' ||
    params.ssn === '222-22-2222' ||
    params.ssn === '333-33-3333' ||
    params.ssn === '444-44-4444' ||
    params.ssn === '555-55-5555' ||
    params.ssn === '666-66-6666' ||
    params.ssn === '777-77-7777' ||
    params.ssn === '888-88-8888' ||
    params.ssn === '999-99-9999';

  return invalidSSN;
}

// Validate DOB for Registation's MM/DD format
export function validateDOB(params: { dob: string }) {
  const month = parseInt(params.dob.slice(0, 2));
  const day = parseInt(params.dob.slice(3, 5));
  const thirtyDays = [4, 6, 9, 11];
  const thirtyOneDays = [1, 3, 5, 7, 8, 10, 12];
  const invalidDate =
    params.dob.slice(0, 2) === '00' ||
    month > 12 ||
    (thirtyDays.includes(month) && day > 30) ||
    (thirtyOneDays.includes(month) && day > 31) ||
    (month === 2 && day > 29);
  return invalidDate;
}

// Validate States
interface State {
  name: string;
  abbrev: string;
}
export const getStates: State[] = [
  { name: 'Alabama', abbrev: 'AL' },
  { name: 'Alaska', abbrev: 'AK' },
  // { name: 'American Samoa', abbrev: 'AS' },
  { name: 'Arizona', abbrev: 'AZ' },
  { name: 'Arkansas', abbrev: 'AR' },
  { name: 'California', abbrev: 'CA' },
  { name: 'Colorado', abbrev: 'CO' },
  { name: 'Connecticut', abbrev: 'CT' },
  { name: 'Delaware', abbrev: 'DE' },
  { name: 'District of Columbia', abbrev: 'DC' },
  // { name: 'Federated States of Micronesia', abbrev: 'FM' },
  { name: 'Florida', abbrev: 'FL' },
  { name: 'Georgia', abbrev: 'GA' },
  // { name: 'Guam', abbrev: 'GU' },
  { name: 'Hawaii', abbrev: 'HI' },
  { name: 'Idaho', abbrev: 'ID' },
  { name: 'Illinois', abbrev: 'IL' },
  { name: 'Indiana', abbrev: 'IN' },
  { name: 'Iowa', abbrev: 'IA' },
  { name: 'Kansas', abbrev: 'KS' },
  { name: 'Kentucky', abbrev: 'KY' },
  { name: 'Louisiana', abbrev: 'LA' },
  { name: 'Maine', abbrev: 'ME' },
  // { name: 'Marshall Islands', abbrev: 'MH' },
  { name: 'Maryland', abbrev: 'MD' },
  { name: 'Massachusetts', abbrev: 'MA' },
  { name: 'Michigan', abbrev: 'MI' },
  { name: 'Minnesota', abbrev: 'MN' },
  { name: 'Mississippi', abbrev: 'MS' },
  { name: 'Missouri', abbrev: 'MO' },
  { name: 'Montana', abbrev: 'MT' },
  { name: 'Nebraska', abbrev: 'NE' },
  { name: 'Nevada', abbrev: 'NV' },
  { name: 'New Hampshire', abbrev: 'NH' },
  { name: 'New Jersey', abbrev: 'NJ' },
  { name: 'New Mexico', abbrev: 'NM' },
  { name: 'New York', abbrev: 'NY' },
  { name: 'North Carolina', abbrev: 'NC' },
  { name: 'North Dakota', abbrev: 'ND' },
  // { name: 'Northern Mariana Islands', abbrev: 'MP' },
  { name: 'Ohio', abbrev: 'OH' },
  { name: 'Oklahoma', abbrev: 'OK' },
  { name: 'Oregon', abbrev: 'OR' },
  // { name: 'Palau', abbrev: 'PW' },
  { name: 'Pennsylvania', abbrev: 'PA' },
  // { name: 'Puerto Rico', abbrev: 'PR' },
  { name: 'Rhode Island', abbrev: 'RI' },
  { name: 'South Carolina', abbrev: 'SC' },
  { name: 'South Dakota', abbrev: 'SD' },
  { name: 'Tennessee', abbrev: 'TN' },
  { name: 'Texas', abbrev: 'TX' },
  { name: 'Utah', abbrev: 'UT' },
  { name: 'Vermont', abbrev: 'VT' },
  // { name: 'Virgin Islands', abbrev: 'VI' },
  { name: 'Virginia', abbrev: 'VA' },
  { name: 'Washington', abbrev: 'WA' },
  { name: 'West Virginia', abbrev: 'WV' },
  { name: 'Wisconsin', abbrev: 'WI' },
  { name: 'Wyoming', abbrev: 'WY' },
];

export function validateState(params: { state: string }) {
  return (
    params.state !== undefined &&
    params.state.length === 2 &&
    !getStates.find((state) => state.abbrev === params.state.toUpperCase())
  );
}

// Validate Corp Tax ID/EIN
export function validateTaxID(params: { taxID: string }) {
  if (params.taxID === '00-0000000' || params.taxID === '12-3456789') {
    return true;
  }
}
