import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { LicenseData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function LicenseInfoDelete(props: {
  AgtNo: string | undefined;
  license: LicenseData | undefined;
  onClose: () => void;
  onSuccess: () => void;
}) {
  const queryClient = useQueryClient();
  // Mutation - Delete License for Agent
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/licenses/delete', {
        body: { AgtNo: props.AgtNo, LicId: props.license?.LicId },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/licenses', { AgtNo: props.AgtNo }],
      });

      props.onSuccess();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  return (
    <Box sx={{ p: 2, backgroundColor: '#fafafa' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            flex: 1,
            fontFamily: 'Roboto Mono',
            fontSize: 22,
            textDecoration: 'line-through',
            color: '#9b9b9b',
          }}
        >
          {props.license?.LicNo}
        </Box>

        <Box>Delete license?</Box>

        <Box sx={{ px: 1 }}>
          <Tooltip title="Delete license" placement="left" arrow>
            <span>
              <IconButton
                disabled={mutation.isLoading}
                size="small"
                onClick={handleSubmit}
              >
                {mutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <DeleteIcon color="error" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Box>

        <Tooltip title="Cancel" placement="bottom" arrow>
          <span>
            <IconButton
              disabled={mutation.isLoading}
              size="small"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      {mutation.isError ? (
        <Box sx={{ pt: 1 }}>
          <Alert
            onClose={() => mutation.reset()}
            severity="error"
            sx={{ width: '100%' }}
          >
            <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
            <small>Please refresh the page and try again.</small>
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
}
