import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import React from 'react';
import { BackgroundData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { BackgroundQuestion } from './background-question';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box
      sx={{
        backgroundColor: '#f1f1f1',
        border: '1px solid #2196f344',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {props.value === 100 ? (
            <CheckCircleIcon color="secondary" />
          ) : (
            <Box sx={{ fontSize: 12, fontWeight: 'bold' }}>{`${Math.round(
              props.value,
            )}%`}</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

const responseCount = (data: BackgroundData) => {
  let countResponse = 0;

  // 1. License revoked or suspended
  // "LicRevSus": 0,
  if (data.LicRevSus !== null) {
    countResponse = countResponse + 1;
  }

  // 2. Consumer Complaint
  // "ConsumerComplaint": 0,
  // "ConsumerComplaintExpl": null,
  if (data.ConsumerComplaint === 1) {
    if (data.ConsumerComplaintExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.ConsumerComplaint !== null) {
    countResponse = countResponse + 1;
  }

  // 3. Terminated for Cause
  // "TermforCause": 0,
  // "TermforCauseExpl": null,
  if (data.TermforCause === 1) {
    if (data.TermforCauseExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.TermforCause !== null) {
    countResponse = countResponse + 1;
  }

  // 4. Lein or Judgement
  // "LeinOrJudgement": 0,
  // "LeinOrJudgementExpl": null,
  if (data.LeinOrJudgement === 1) {
    if (data.LeinOrJudgementExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.LeinOrJudgement !== null) {
    countResponse = countResponse + 1;
  }

  // 5. Bankruptcy
  // "Bankrupt": 1,
  // "BankruptAct": 0,
  // "BankruptDist": null,
  // "BankruptDate": "2023-06-19",
  // "BankruptExpl": "Testing Bankruptcy",
  if (data.Bankrupt === 1) {
    if (data.BankruptAct === 0) {
      if (
        data.BankruptDist &&
        data.BankruptDist.length === 4 &&
        data.BankruptDate &&
        data.BankruptExpl
      ) {
        countResponse = countResponse + 1;
      }
    } else if (data.BankruptAct && data.BankruptDate && data.BankruptExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.Bankrupt !== null) {
    countResponse = countResponse + 1;
  }

  // 6. Carrier Debt
  // "CarDebt": 0,
  // "CarDebtDate": null,
  // "CarDebtExpl": null,
  if (data.CarDebt === 1) {
    if (data.CarDebtDate && data.CarDebtExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.CarDebt !== null) {
    countResponse = countResponse + 1;
  }

  // 7. Crimes
  // "CrimAny": 1,
  // "CrimExpl": "test",
  if (data.CrimAny === 1) {
    if (data.CrimExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.CrimAny !== null) {
    countResponse = countResponse + 1;
  }

  // 8. Misdemeanors
  // "Misdemeanor": 1,
  // "MisdemeanorExpl": "Testing Misdemeanor",
  if (data.Misdemeanor === 1) {
    if (data.MisdemeanorExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.Misdemeanor !== null) {
    countResponse = countResponse + 1;
  }

  // 9. Felonies
  // "Felony": 0,
  // "FelonyDate": null,
  // "FelonyExpl": null,
  if (data.Felony === 1) {
    if (data.FelonyDate && data.FelonyExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.Felony !== null) {
    countResponse = countResponse + 1;
  }

  // 10. Refused Bond
  // "RefuBond": 0,
  // "RefuBondExpl": null,
  if (data.RefuBond === 1) {
    if (data.RefuBondExpl) {
      countResponse = countResponse + 1;
    }
  } else if (data.RefuBond !== null) {
    countResponse = countResponse + 1;
  }

  return countResponse;
};

export function BackgroundInfo(props: {
  AgtNo: string | undefined;
  data: BackgroundData;
}) {
  // State
  const [data, setData] = React.useState<BackgroundData>();
  const [editing, setEditing] = React.useState(false);

  React.useEffect(() => {
    if (props.data) {
      setData(props.data);
      setEditing(false);
    }
  }, [props.data]);

  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy
  const mutation = useMutation({
    mutationFn: async () => {
      if (isAgt(props.AgtNo)) {
        await API.post('ContractingAPI', '/agent/background/upsert', {
          body: { ...data },
        });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/background', { AgtNo: props.AgtNo }],
      });

      // Reset State
      setEditing(false);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setData((currentState) => {
      if (currentState !== undefined) {
        return { ...currentState, [name]: value };
      } else {
        return undefined;
      }
    });
  };

  const handleResponse = (newData: Object) => {
    setData((currentState) => {
      if (currentState !== undefined) {
        return { ...currentState, ...newData };
      } else {
        return undefined;
      }
    });
  };

  if (data !== undefined) {
    const backgroundQuestions: {
      id: string;
      Question: string;
      Example: string;
      hasOccurred: 0 | 1 | null;
      handleResponse: (newResponse: 0 | 1 | null) => void;
      addDescription?: JSX.Element;
    }[] = [
      {
        id: 'data.LicRevSus',
        Question: 'Has your insurance license ever been revoked or suspended?',
        Example:
          "If there was a time when your license was temporarily or permanently taken away due to an infraction or violation, you would answer 'yes'.",
        hasOccurred: data.LicRevSus,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ LicRevSus: 0 });
          } else if (newResponse === 1) {
            handleResponse({ LicRevSus: 1 });
          } else {
            handleResponse({ LicRevSus: null });
          }
        },
      },
      {
        id: 'data.ConsumerComplaint',
        Question:
          'Have you ever been the subject of any complaint related to the solicitation or sale of any insurance product(s), securities or any financial product or service, in any jurisdiction?',
        Example:
          "For instance, if a client or a regulatory body has ever filed a complaint against you because they believed you misrepresented an insurance product, security, or other financial product or service, you should answer 'yes'.",
        hasOccurred: data.ConsumerComplaint,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({
              ConsumerComplaint: 0,
              ConsumerComplaintExpl: null,
            });
          } else if (newResponse === 1) {
            handleResponse({ ConsumerComplaint: 1, ConsumerComplaintExpl: '' });
          } else {
            handleResponse({
              ConsumerComplaint: null,
              ConsumerComplaintExpl: null,
            });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.ConsumerComplaint === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-ConsumerComplaintExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="ConsumerComplaintExpl"
                        value={data.ConsumerComplaintExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.ConsumerComplaintExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.TermforCause',
        Question:
          'Have you ever been terminated from any contract for a reason other than lack of sales?',
        Example:
          "If you have ever had a contract with an insurance company or other financial institution terminated for reasons such as a breach of contract, ethical violations, or regulatory issues, and not simply due to lack of sales, you should answer 'yes'.",
        hasOccurred: data.TermforCause,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ TermforCause: 0, TermforCauseExpl: null });
          } else if (newResponse === 1) {
            handleResponse({ TermforCause: 1, TermforCauseExpl: '' });
          } else {
            handleResponse({ TermforCause: null, TermforCauseExpl: null });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.TermforCause === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-TermforCauseExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="TermforCauseExpl"
                        value={data.TermforCauseExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.TermforCauseExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.LeinOrJudgement',
        Question:
          'Do you have past due financial obligations, unsatisfied judgments, or liens, including any delinquent state or federal tax obligations?',
        Example:
          "If you have any overdue bills, unpaid court judgments, property subject to a claim by a creditor for unpaid debt, or unpaid state or federal taxes, you should answer 'yes'.",
        hasOccurred: data.LeinOrJudgement,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ LeinOrJudgement: 0, LeinOrJudgementExpl: null });
          } else if (newResponse === 1) {
            handleResponse({ LeinOrJudgement: 1, LeinOrJudgementExpl: '' });
          } else {
            handleResponse({
              LeinOrJudgement: null,
              LeinOrJudgementExpl: null,
            });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.LeinOrJudgement === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-LeinOrJudgementExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="LeinOrJudgementExpl"
                        value={data.LeinOrJudgementExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.LeinOrJudgementExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.Bankrupt',
        Question: 'Have you ever declared bankruptcy?',
        Example:
          "If you have ever filed for bankruptcy due to insurmountable debt, you should answer 'yes'.",
        hasOccurred: data.Bankrupt,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({
              Bankrupt: 0,
              BankruptAct: 0,
              BankruptDist: null,
              BankruptDate: null,
              BankruptExpl: null,
            });
          } else if (newResponse === 1) {
            handleResponse({
              Bankrupt: 1,
              BankruptAct: null,
              BankruptDist: null,
              BankruptDate: format(new Date(), 'yyyy-MM-dd'),
              BankruptExpl: '',
            });
          } else {
            handleResponse({
              Bankrupt: null,
              BankruptAct: null,
              BankruptDist: null,
              BankruptDate: null,
              BankruptExpl: null,
            });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.Bankrupt === 1 ? (
              <Box sx={{ border: '1px solid #2196f388', borderRadius: 2 }}>
                <Box sx={{ p: 1 }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Box>
                        Please provide a date for when the statement above
                        occurred along with a description of the occurrence.
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={1}>
                        <TextField
                          id="background-info-BankruptDate"
                          fullWidth
                          label="Date"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          name="BankruptDate"
                          value={data.BankruptDate}
                          onChange={handleChange}
                        />

                        <TextField
                          id="background-info-BankruptExpl"
                          fullWidth
                          label="Description"
                          placeholder="Provide a description of the occurrence..."
                          InputLabelProps={{ shrink: true }}
                          multiline
                          rows={4}
                          name="BankruptExpl"
                          value={data.BankruptExpl}
                          onChange={handleChange}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>

                <Divider />

                <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1, fontSize: 18 }}>
                    Is the bankruptcy active or pending?
                  </Box>

                  <ToggleButtonGroup
                    disabled={mutation.isLoading}
                    color="info"
                    exclusive
                    value={data.BankruptAct}
                    onChange={(event, newValue) => {
                      if (newValue === 0) {
                        handleResponse({
                          BankruptAct: 0,
                          BankruptDist: new Date().getFullYear().toString(),
                        });
                      } else if (newValue === 1) {
                        handleResponse({ BankruptAct: 1, BankruptDist: null });
                      } else {
                        handleResponse({
                          BankruptAct: null,
                          BankruptDist: null,
                        });
                      }
                    }}
                  >
                    <ToggleButton value={1}>Yes</ToggleButton>
                    <ToggleButton value={0}>No</ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                {data.BankruptAct === 0 ? (
                  <React.Fragment>
                    <Divider />

                    <Box sx={{ p: 1 }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12} md={6}>
                          <Box sx={{ fontSize: 18 }}>
                            In what year was the bankruptcy discharged?
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            id="background-info-BankruptDist"
                            fullWidth
                            label="Year (yyyy)"
                            placeholder="0000"
                            type="number"
                            name="BankruptDist"
                            value={data.BankruptDist}
                            onChange={(event) => {
                              const { value } = event.target;
                              if (value.trim().length <= 4) {
                                handleResponse({ BankruptDist: value });
                              }
                            }}
                            inputProps={{
                              style: { fontFamily: 'Roboto Mono' },
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </React.Fragment>
                ) : null}
              </Box>
            ) : (
              <Box sx={{ border: '1px solid #00000022', borderRadius: 2 }}>
                <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box
                      sx={{ whiteSpace: 'nowrap', fontFamily: 'Roboto Mono' }}
                    >
                      {data.BankruptDate}
                    </Box>
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.BankruptExpl}
                    </Box>
                  </Box>
                </Box>

                <Divider />

                {data.BankruptAct === 0 ? (
                  <Box sx={{ p: 1, fontSize: 18 }}>
                    Discharged{' '}
                    <Box component="span" sx={{ fontFamily: 'Roboto Mono' }}>
                      {data.BankruptDist}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ p: 1, fontSize: 16, fontWeight: 'bold' }}>
                    Active/Pending
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.CarDebt',
        Question:
          'Do you have any outstanding debt with any insurance carriers?',
        Example:
          "If you owe any insurance carriers money due to things like chargebacks, you would answer 'yes'.",
        hasOccurred: data.CarDebt,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({
              CarDebt: 0,
              CarDebtDate: null,
              CarDebtExpl: null,
            });
          } else if (newResponse === 1) {
            handleResponse({
              CarDebt: 1,
              CarDebtDate: format(new Date(), 'yyyy-MM-dd'),
              CarDebtExpl: '',
            });
          } else {
            handleResponse({
              CarDebt: null,
              CarDebtDate: null,
              CarDebtExpl: null,
            });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.CarDebt === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a date for when the statement above
                      occurred along with a description of the occurrence.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-CarDebtDate"
                        fullWidth
                        label="Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        name="CarDebtDate"
                        value={data.CarDebtDate}
                        onChange={handleChange}
                      />

                      <TextField
                        id="background-info-CarDebtExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="CarDebtExpl"
                        value={data.CarDebtExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box
                      sx={{ whiteSpace: 'nowrap', fontFamily: 'Roboto Mono' }}
                    >
                      {data.CarDebtDate}
                    </Box>
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.CarDebtExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.CrimAny',
        Question: 'Have you ever been charged with any crimes?',
        Example:
          "If you have ever been formally accused of committing any crime, regardless of the final outcome or verdict, you would answer 'yes'.",
        hasOccurred: data.CrimAny,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ CrimAny: 0, CrimExpl: null });
          } else if (newResponse === 1) {
            handleResponse({ CrimAny: 1, CrimExpl: '' });
          } else {
            handleResponse({ CrimAny: null, CrimExpl: null });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.CrimAny === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-CrimExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="CrimExpl"
                        value={data.CrimExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.CrimExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.Misdemeanor',
        Question: 'Have you ever been charged with any misdemeanors?',
        Example:
          "If you have ever been accused of a less serious crime that typically carries a lighter sentence, you would answer 'yes'.",
        hasOccurred: data.Misdemeanor,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ Misdemeanor: 0, MisdemeanorExpl: null });
          } else if (newResponse === 1) {
            handleResponse({ Misdemeanor: 1, MisdemeanorExpl: '' });
          } else {
            handleResponse({ Misdemeanor: null, MisdemeanorExpl: null });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.Misdemeanor === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-MisdemeanorExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="MisdemeanorExpl"
                        value={data.MisdemeanorExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.MisdemeanorExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.Felony',
        Question: 'Have you ever been charged with any felonies?',
        Example:
          "If you have ever been accused of a serious crime that typically carries a sentence of more than one year in prison, you would answer 'yes'.",
        hasOccurred: data.Felony,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ Felony: 0, FelonyDate: null, FelonyExpl: null });
          } else if (newResponse === 1) {
            handleResponse({
              Felony: 1,
              FelonyDate: format(new Date(), 'yyyy-MM-dd'),
              FelonyExpl: '',
            });
          } else {
            handleResponse({
              Felony: null,
              FelonyDate: null,
              FelonyExpl: null,
            });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.Felony === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a date for when the statement above
                      occurred along with a description of the occurrence.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-FelonyDate"
                        fullWidth
                        label="Date"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        name="FelonyDate"
                        value={data.FelonyDate}
                        onChange={handleChange}
                      />

                      <TextField
                        id="background-info-FelonyExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="FelonyExpl"
                        value={data.FelonyExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box
                      sx={{ whiteSpace: 'nowrap', fontFamily: 'Roboto Mono' }}
                    >
                      {data.FelonyDate}
                    </Box>
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.FelonyExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
      {
        id: 'data.RefuBond',
        Question: 'Have you ever been refused bond for a crime?',
        Example:
          "If a court has ever denied you bail or bond after being charged with a crime, you would answer 'yes'.",
        hasOccurred: data.RefuBond,
        handleResponse: (newResponse) => {
          if (newResponse === 0) {
            handleResponse({ RefuBond: 0, RefuBondExpl: null });
          } else if (newResponse === 1) {
            handleResponse({ RefuBond: 1, RefuBondExpl: '' });
          } else {
            handleResponse({ RefuBond: null, RefuBondExpl: null });
          }
        },
        addDescription: (
          <Box sx={{ p: 1 }}>
            {editing && data.RefuBond === 1 ? (
              <Box
                sx={{ p: 1, border: '1px solid #2196f388', borderRadius: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      Please provide a description of the occurrence for the
                      statement above.
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="background-info-RefuBondExpl"
                        fullWidth
                        label="Description"
                        placeholder="Provide a description of the occurrence..."
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        name="RefuBondExpl"
                        value={data.RefuBondExpl}
                        onChange={handleChange}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ p: 1, border: '1px solid #00000022', borderRadius: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: { xs: 'column', md: 'row' },
                      alignItems: { xs: 'flex-start', md: 'center' },
                    }}
                  >
                    <Box sx={{ px: 1, flex: 1, fontSize: 13 }}>
                      {data.RefuBondExpl}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        ),
      },
    ];

    const resCount = responseCount(data);
    const questionCount = backgroundQuestions.length;
    const progress = (resCount / questionCount) * 100;
    let toggleEditing = (
      <Box sx={{ p: 1, borderTop: '1px solid #00000011' }}>
        <Button
          fullWidth
          size="large"
          color="info"
          variant={progress === 100 ? 'outlined' : 'contained'}
          disableElevation
          endIcon={<FactCheckIcon />}
          onClick={() => setEditing(true)}
        >
          Respond to questions
        </Button>
      </Box>
    );
    if (editing) {
      toggleEditing = (
        <Box sx={{ p: 1, borderTop: '1px solid #00000011' }}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Button
              disabled={mutation.isLoading}
              size="large"
              variant="outlined"
              startIcon={<CloseIcon />}
              onClick={() => {
                setEditing(false);
                setData(props.data);
              }}
            >
              Cancel
            </Button>

            <Button
              disabled={
                mutation.isLoading || mutation.isError || progress !== 100
              }
              fullWidth
              size="large"
              color="info"
              variant="contained"
              disableElevation
              endIcon={
                mutation.isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <DoneAllIcon />
                )
              }
              onClick={() => mutation.mutate()}
            >
              {mutation.isLoading ? 'Saving...' : 'Save answers'}
            </Button>

            <CircularProgressWithLabel value={progress} color="info" />
          </Stack>

          {mutation.isError ? (
            <Box sx={{ pt: 1 }}>
              <Alert onClose={() => mutation.reset()} severity="error">
                <strong style={{ marginRight: 10 }}>
                  An error has occurred!
                </strong>
                <small>Please refresh the page and try again.</small>
              </Alert>
            </Box>
          ) : null}
        </Box>
      );
    }

    return (
      <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
        <Box sx={{ p: 1, display: 'flex', alignItems: 'start' }}>
          <Box sx={{ pl: 1, flex: 1, fontSize: 28, fontWeight: 'bold' }}>
            Background Information
          </Box>
        </Box>

        {toggleEditing}

        {backgroundQuestions.map((item) => {
          return (
            <BackgroundQuestion
              key={item.id}
              question={item.Question}
              example={item.Example}
              editing={editing}
              disabled={mutation.isLoading}
              hasOccurred={item.hasOccurred}
              onResponse={item.handleResponse}
            >
              {item.addDescription}
            </BackgroundQuestion>
          );
        })}

        {toggleEditing}
      </Paper>
    );
  } else {
    return null;
  }
}
