import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { DocumentCarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { TaxDoc } from './tax-doc';
import { TaxDocBtn } from './tax-doc-btn';

export function AgentW9(props: {
  open?: boolean;
  hideIfCompleted?: boolean;
  onClose?: () => void;
}) {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [viewAgree, setViewAgree] = React.useState(false);

  React.useEffect(() => {
    if (props.open) {
      setViewAgree(props.open);
    }
  }, [props.open]);

  // Query - Agent Agreement
  const pathAgreement = '/documents/list';
  const DocTitle = 'Agent W9';
  const queryAgreement = useQuery({
    enabled:
      impersonatedAgent === null &&
      user !== undefined &&
      isAgt(user.getUsername()),
    queryKey: [pathAgreement, { DocTitle }],
    queryFn: async () => {
      const response: {
        data: DocumentCarrierData;
      } = await API.post('ContractingAPI', pathAgreement, {
        body: { DocTitle },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  if (
    queryAgreement.data &&
    queryAgreement.data.Signed &&
    props.hideIfCompleted
  ) {
    return null;
  } else if (queryAgreement.data) {
    return (
      <React.Fragment>
        <TaxDocBtn
          completed={Boolean(queryAgreement.data && queryAgreement.data.Signed)}
          onClick={() => setViewAgree(true)}
        />

        <TaxDoc
          open={viewAgree}
          doc={queryAgreement.data || undefined}
          onClose={() => {
            setViewAgree(false);
            if (props.onClose) {
              props.onClose();
            }
          }}
          onSuccess={() => setViewAgree(false)}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
}
