import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteError } from '../../components/route-container/route-error';
import { captureError } from '../../utils/capture-error';

const DocIframe = React.memo(function DocIframe(props: {
  title: string;
  data: any;
}) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title={props.title}
        src={window.URL.createObjectURL(
          new Blob([props.data], { type: 'application/pdf' })
        )}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

export function AgreementPDF(props: { AgtDocID: number }) {
  // Props
  const { AgtDocID } = props;

  // Query - Download Document to display as PDF
  const path = '/documents/download';
  const query = useQuery({
    queryKey: [path, { AgtDocID }],
    queryFn: async () => {
      const response = await API.post('ContractingAPI', path, {
        responseType: 'arraybuffer',
        body: { AgtDocID },
      });

      if (response) {
        return response;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  if (query.isFetching) {
    return <LinearProgress />;
  } else if (query.isError) {
    return <RouteError />;
  } else {
    return (
      <Box
        sx={{
          minHeight: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {query.data ? (
          <DocIframe title="Agent Agreement PDF" data={query.data} />
        ) : null}
      </Box>
    );
  }
}
