import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { DisplayMarkup } from '../../components/display-markup';
import { HideFrom } from '../../components/in-progress';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { captureError } from '../../utils/capture-error';
import { QuestionData, commonQuestions } from './data';

function QuestionItem(props: { item: QuestionData }) {
  // Props
  const { item } = props;
  // State
  const [open, setOpen] = React.useState(false);

  return (
    <Paper key={item.id} elevation={0} sx={{ border: '1px solid #00000022' }}>
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1, fontSize: 22 }}>{item.question}</Box>

        <Tooltip title={open ? 'Collapse' : 'Expand'} placement="left" arrow>
          <IconButton onClick={() => setOpen((currentState) => !currentState)}>
            {open ? (
              <ExpandLessIcon color="info" />
            ) : (
              <ExpandMoreIcon color="info" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {open ? (
        <Box sx={{ px: 2 }}>
          <DisplayMarkup
            markup={item.answer}
            sx={{ fontSize: 18, color: '#4a4a4a' }}
          />
        </Box>
      ) : null}
    </Paper>
  );
}

export function Help() {
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 600));
      } catch (error) {
        captureError({ data: { error } });
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Searching
  const dataSearched = searchBy(commonQuestions, searchString);

  return (
    <RouteContainer
      routeTitle="Help and Support"
      withContainer
      loading={loading}
      hasError={hasError}
    >
      <HideFrom branch="master">
        <Stack spacing={2} sx={{ pt: 2, pb: 16 }}>
          <SearchField
            id="search-questions"
            fullWidth
            size="medium"
            placeholder="Search questions"
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
          />

          {dataSearched.map((item) => {
            return <QuestionItem key={item.id} item={item} />;
          })}
        </Stack>
      </HideFrom>
    </RouteContainer>
  );
}
