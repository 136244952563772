import SportsScoreIcon from '@mui/icons-material/SportsScore';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { orderBy } from 'lodash';
import React from 'react';
import { PaperExpandable } from '../../../components/paper-expandable';
import { searchBy } from '../../../components/search-field';
import { GettingStartedTeamData } from '../../../types';
import { tasks } from '../../home/data';
import { ManageProgressItem } from './manage-progress-item';

export function ManageProgress(props: {
  data: GettingStartedTeamData[];
  onSelect: (item: GettingStartedTeamData) => void;
}) {
  // State
  const [searchString, setSearchString] = React.useState('');
  const [viewing, setViewing] = React.useState<string>('All');

  let progressData = props.data;
  if (viewing !== 'All') {
    progressData = props.data.filter((i) => {
      if (viewing in i) {
        // @ts-ignore
        return !Boolean(i[viewing]);
      } else {
        return false;
      }
    });
  }
  // Ordering
  const orderedItems = orderBy(progressData, 'AgtNo', 'desc');
  // Searching
  const searchedItems = searchBy(orderedItems, searchString);

  return (
    <PaperExpandable
      open
      title="Getting Started"
      description="These Agents have not yet contracted with a Carrier."
      count={props.data.length}
      searchString={searchString}
      onChangeSearch={(event) => setSearchString(event.target.value)}
    >
      <Box>
        <Box sx={{ p: 1 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ transform: 'rotate(-12deg)', pr: 1 }}>
                  <SportsScoreIcon fontSize="large" />
                </Box>

                <Box
                  sx={{
                    flex: { xs: 0, md: 1 },
                    borderTop: '1px solid #00000044',
                  }}
                />

                <Box sx={{ px: 1, fontSize: 18 }}>
                  View users who have not completed
                </Box>

                <Box
                  sx={{
                    flex: { xs: 1, md: 0 },
                    borderTop: '1px solid #00000044',
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="not-completed-step-select-label">
                  Step not completed
                </InputLabel>
                <Select
                  labelId="not-completed-step-select-label"
                  id="not-completed-step-select"
                  label="Step not completed"
                  value={viewing}
                  onChange={(event) => setViewing(event.target.value)}
                >
                  <MenuItem value="All">Any step</MenuItem>
                  {tasks.map((task) => {
                    return (
                      <MenuItem
                        disabled={task.key === 'Fastract'}
                        key={task.Title}
                        value={task.key}
                      >
                        {task.Title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>

        {searchedItems.length > 0 ? (
          <Box component="ul" sx={{ p: 0, m: 0 }}>
            {searchedItems.map((item) => {
              return (
                <ManageProgressItem
                  key={item.AgtNo}
                  item={item}
                  onClick={() => props.onSelect(item)}
                />
              );
            })}
          </Box>
        ) : (viewing !== 'All' || searchString !== '') &&
          searchedItems.length === 0 ? (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              fontFamily: 'Roboto Mono',
              color: '#9b9b9b',
            }}
          >
            No Agents found
          </Box>
        ) : (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              fontFamily: 'Roboto Mono',
              color: '#9b9b9b',
            }}
          >
            No Agents in downline are Getting Started
          </Box>
        )}
      </Box>
    </PaperExpandable>
  );
}
