import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { CustomerProfileIframe } from '../../../components/customer-profile-iframe';
import { CustomerPaymentData } from '../../../types';
import { captureError } from '../../../utils/capture-error';

export function CardProfileManage(props: {
  data: CustomerPaymentData | undefined;
  queryKey: QueryFilters['queryKey'];
  onClose: () => void;
}) {
  // State
  const [closing, setClosing] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    // Not using react-query because the response token should not be cached
    const fetchData = async () => {
      try {
        setLoading(true);
        setToken('');
        const response: { token: string } = await API.post(
          'ContractingAPI',
          '/billing/authorize/customer-profile/token',
          {
            body: {
              hostedIframeReturnUrl:
                window.location.origin + '/IFrameCommunicator.html',
            },
          },
        );
        setToken(response.token);
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    if (props.data !== undefined) {
      fetchData();
    }
  }, [props.data]);

  const queryClient = useQueryClient();
  const handleClose = async () => {
    if (!closing) {
      try {
        setClosing(true);

        // Refetch data
        await queryClient.invalidateQueries({ queryKey: props.queryKey });

        props.onClose();
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setClosing(false);
      }
    }
  };

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.data !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            {loading || closing ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          <Typography variant="h6" noWrap component="div">
            Edit Card
          </Typography>
        </Toolbar>

        <Divider />

        {loading ? (
          <LinearProgress />
        ) : props.data !== undefined && token !== '' ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CustomerProfileIframe
              action="/customer/editPayment"
              iframeToken={token}
              title="Edit Card"
              paymentProfileId={props.data.customerPaymentProfileId || ''}
              onReceiveCommunication={(action) => {
                if (action) {
                  handleClose();
                }
              }}
            />
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
