import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { captureError } from '../../utils/capture-error';

interface LicenseLOAData {
  LOAId: number | string;
  LOA: string;
  DateIss: string;
}

export function LicenseInfoLOADelete(props: {
  children: JSX.Element;
  AgtNo: string | undefined;
  line: LicenseLOAData;
}) {
  // State
  const [deleting, setDeleting] = React.useState(false);

  const queryClient = useQueryClient();
  // Mutation - Delete Line of Authority from license
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/loa/delete', {
        body: { AgtNo: props.AgtNo, LOAId: props.line.LOAId },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/licenses', { AgtNo: props.AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  return (
    <Paper
      elevation={0}
      sx={{ border: '2px solid #2196f3', backgroundColor: '#fafafa' }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.children}

          <Box sx={{ pl: 1 }}>
            <Tooltip
              title="Remove existing Line of Authority"
              placement="left"
              arrow
            >
              <IconButton disabled={deleting} onClick={() => setDeleting(true)}>
                <DeleteIcon color={deleting ? undefined : 'error'} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {deleting ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                flex: 1,
                fontFamily: 'Roboto Mono',
                fontSize: 18,
                textDecoration: 'line-through',
                color: '#9b9b9b',
              }}
            >
              {props.line.LOA}
            </Box>

            <Box>Delete existing Line of Authority?</Box>

            <Box sx={{ px: 1 }}>
              <Tooltip title="Delete LOA" placement="left" arrow>
                <span>
                  <IconButton
                    disabled={mutation.isLoading}
                    size="small"
                    onClick={handleSubmit}
                  >
                    {mutation.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DeleteIcon color="error" />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </Box>

            <Tooltip title="Cancel" placement="bottom" arrow>
              <span>
                <IconButton
                  disabled={mutation.isLoading}
                  size="small"
                  onClick={() => setDeleting(false)}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ) : null}

        {mutation.isError ? (
          <Alert
            onClose={() => mutation.reset()}
            severity="error"
            sx={{ width: '100%' }}
          >
            <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
            <small>Please refresh the page and try again.</small>
          </Alert>
        ) : null}
      </Stack>
    </Paper>
  );
}
