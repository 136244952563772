import ForwardIcon from '@mui/icons-material/Forward';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { CarrierData, DocumentCarrierData } from '../../types';

export function CarrierListItem(props: {
  carrier: CarrierData;
  doc: DocumentCarrierData;
  onClick: () => void;
}) {
  // Props
  const { carrier } = props;

  const isActive = props.doc.DocStatus === 'Active';

  return (
    <Paper
      elevation={0}
      component="button"
      disabled={!isActive}
      onClick={props.onClick}
      sx={{
        fontFamily: 'Roboto',
        padding: 0,
        width: '100%',
        minHeight: 64,
        display: 'flex',
        alignItems: 'center',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#03182E22',
        backgroundColor: '#fff',
        cursor: isActive ? 'pointer' : 'inherit',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.01)',
          borderColor: '#03182E',
        },
        ':hover .request-item-forward-icon': {
          color: '#03182E',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 88,
            }}
          >
            {carrier.Logo ? (
              <img
                loading="lazy"
                src={carrier.Logo}
                alt={carrier.Carrier || 'Carrier'}
                style={{ maxWidth: 88, maxHeight: 52 }}
              />
            ) : (
              <Box sx={{ width: 88, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
                <Box
                  sx={{
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    fontSize: 18,
                    color: '#b26500',
                    px: 1,
                  }}
                >
                  {carrier.Carrier}
                </Box>
                <Box sx={{ flex: 1, borderBottom: '1px solid #b2650044' }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ padding: 1, fontSize: { xs: 18, md: 22 } }}>
          {carrier.FullName}
        </Box>
      </Box>

      <Box sx={{ flex: 1 }} />

      {props.doc.Message ? <Box sx={{ px: 1 }}>{props.doc.Message}</Box> : null}

      {isActive ? (
        <Box
          sx={{
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ForwardIcon
            className="request-item-forward-icon"
            sx={{ transition: 'all 0.2s', color: '#03182E22' }}
          />
        </Box>
      ) : null}
    </Paper>
  );
}
