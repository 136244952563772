import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadType } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formDataRequest } from '../../utils/form-data-request';

export function PolicyUpload(props: { AgtNo: string | undefined }) {
  // State
  const [open, setOpen] = React.useState(false);
  const [EOFile, setEOFile] = React.useState<File>();
  const [FileDisplayName, setFileDisplayName] = React.useState('');

  React.useEffect(() => {
    if (open) {
      setEOFile(undefined);
      setFileDisplayName('');
    }
  }, [open]);

  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy Certificate
  const mutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      formData.append('FileUploadType', FileUploadType['Errors and Omissions']);
      formData.append('FileDisplayName', FileDisplayName);
      if (props.AgtNo) {
        formData.append('AgtNo', props.AgtNo);
      }
      if (EOFile) {
        formData.append('File', EOFile);
      }

      // Add E&O file
      await formDataRequest({ path: '/agent/file/upload', formData });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/eo', { AgtNo: props.AgtNo }],
      });

      setOpen(false);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length) {
        const newFile = acceptedFiles[0];

        setFileDisplayName(newFile.name);
        setEOFile(newFile);
      }
    },
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
      'application/msword': ['.doc', '.docx'],
    },
  });

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Box
        onClick={() => setOpen(true)}
        component="button"
        sx={{
          cursor: 'pointer',
          width: '100%',
          textAlign: 'left',
          py: 1,
          px: 2,
          backgroundColor: '#fcfcfc',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#eaeaea',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            backgroundColor: '#fff',
            borderColor: '#03182E',
          },
        }}
      >
        <InsertDriveFileIcon sx={{ color: '#b26500' }} />

        <Box sx={{ flex: 1, pl: 1 }}>
          <Box sx={{ fontSize: 18 }}>Upload a copy of your certificate</Box>
        </Box>

        <UploadIcon sx={{ color: '#7a7a7a' }} />
      </Box>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontSize: 22, fontWeight: 'normal' }}
              >
                Errors & Omissions Certificate
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          {EOFile !== undefined ? (
            <Box>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: '#fafafa',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <UploadFileIcon sx={{ color: '#b26500' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ pl: 1, fontSize: 18, fontWeight: 'bold' }}>
                      Upload
                    </Box>
                    <Box
                      sx={{ pl: 1, fontFamily: 'Roboto Mono', fontSize: 14 }}
                    >
                      {EOFile.name}
                    </Box>
                    <Box sx={{ pl: 1, fontSize: 18, fontWeight: 'bold' }}>
                      ?
                    </Box>
                  </Box>
                </Box>

                <TextField
                  id="policy-upload-name"
                  required
                  fullWidth
                  label="File Name"
                  value={FileDisplayName}
                  onChange={(event) => setFileDisplayName(event.target.value)}
                />

                {mutation.isError ? (
                  <Alert
                    onClose={() => mutation.reset()}
                    severity="error"
                    sx={{ width: '100%' }}
                  >
                    <strong style={{ marginRight: 10 }}>
                      An error has occurred!
                    </strong>
                    <small>Please refresh the page and try again.</small>
                  </Alert>
                ) : null}

                <Button
                  disabled={mutation.isLoading || !FileDisplayName}
                  fullWidth
                  variant="contained"
                  size="large"
                  startIcon={<UploadIcon />}
                  onClick={() => mutation.mutate()}
                >
                  {mutation.isLoading ? 'Uploading...' : 'Upload file'}
                </Button>

                <Button
                  disabled={mutation.isLoading}
                  fullWidth
                  size="small"
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setEOFile(undefined);
                    setFileDisplayName('');
                  }}
                >
                  Change file
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                px: 4,
                py: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  borderWidth: 8,
                  borderStyle: 'dashed',
                  borderColor: isDragActive ? '#2196f3' : '#cacaca',
                  borderRadius: 4,
                  color: '#7a7a7a',
                  transition: 'all 0.2s',
                  ':hover': {
                    borderColor: '#a1a1a1',
                    backgroundColor: '#fafafa',
                    color: '#4a4a4a',
                  },
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      Drop the certificate here ...
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      Drag 'n' drop a copy of your certificate here
                    </Box>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      or click to select your certificate
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
