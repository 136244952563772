import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { AuthContext } from '../../contexts/auth-context';
import { LicenseData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { LicenseAdd } from './license-add';
import { LicenseInfo } from './license-info';
import { LicenseTile } from './license-tile';

export function Licenses() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [selected, setSelected] = React.useState<LicenseData['ClientKey']>();
  const [searchString, setSearchString] = React.useState('');

  // Query - Licenses
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const path = '/agent/licenses';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: LicenseData[];
        } = await API.post('ContractingAPI', path, {
          body: { AgtNo },
        });

        if (response.data.length) {
          return response.data.map((i) => {
            const ClientKey = `${i.LicId}-${i.LicNo}-${i.State}-${i.ClassId}`;
            return { ...i, ClientKey };
          });
        }
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const data = query.data !== undefined ? query.data : [];
  // Ordering
  const orderedStateItems = orderBy(data, [(i) => i.State?.toLowerCase()]);
  const orderedItems = orderBy(orderedStateItems, 'ResStatus', 'desc');
  // Searching
  const searchedItems = searchBy(orderedItems, searchString);

  const selectedLicense = data.find((i) => i.ClientKey === selected);

  return (
    <RouteContainer
      routeTitle="Licenses"
      withContainer
      loading={query.isLoading}
      hasError={query.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={isAgt(AgtNo) ? 8 : 12}>
            <SearchField
              id="licenses-search"
              fullWidth
              size="medium"
              placeholder="Search licenses"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
            />
          </Grid>
          {isAgt(AgtNo) ? (
            <Grid item xs={12} md={4}>
              <LicenseAdd AgtNo={AgtNo} />
            </Grid>
          ) : null}
        </Grid>

        <Box sx={{ pt: 2, pb: 2 }}>
          <Divider />
        </Box>

        {data.length === 0 ? (
          <Paper elevation={0} sx={{ p: 8, border: '1px solid #eee' }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontFamily: 'Roboto Mono',
                color: '#9b9b9b',
              }}
            >
              No licenses
            </Typography>
          </Paper>
        ) : (
          <Grid container spacing={2}>
            {searchedItems.map((item) => {
              return (
                <Grid key={item.ClientKey} item xs={12} md={6} lg={4}>
                  <LicenseTile
                    item={item}
                    onClick={() => setSelected(item.ClientKey)}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}

        <LicenseInfo
          AgtNo={AgtNo}
          license={selectedLicense}
          onClose={() => setSelected(undefined)}
        />
      </Box>
    </RouteContainer>
  );
}
