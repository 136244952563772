import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { QueryFilters, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import {
  CarrierData,
  DocumentAgentData,
  DocumentCarrierData,
  ProcStatus,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { RouteError } from '../route-container/route-error';
import { ContractAdobe } from './contract-adobe';
import { ContractIntro } from './contract-intro';
import { ContractPDF } from './contract-pdf';
import { ContractQuestions } from './contract-questions';
import { ContractStartedQuestions } from './contract-started-questions';
import { ContractStartedReasons } from './contract-started-reasons';

export function Contract(props: {
  carrier: CarrierData | undefined;
  AgtDocID: number | null;
  doc?: DocumentCarrierData;
  queryKey: QueryFilters['queryKey'];
  onClose: () => void;
}) {
  // Props
  const { carrier, AgtDocID } = props;
  // State
  const [closing, setClosing] = React.useState(false);

  // Query - Get data for Document
  const path = '/documents/get';
  const query = useQuery({
    enabled: AgtDocID !== null,
    retry: 2,
    retryDelay: 3000,
    queryKey: [path, { AgtDocID }],
    queryFn: async () => {
      const response: {
        data: DocumentAgentData | null;
      } = await API.post('ContractingAPI', path, {
        body: { AgtDocID },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onSuccess: (data) => {},
    onError: (error) => captureError({ data: { error } }),
  });

  const docLoading = Boolean(query.isLoading && query.fetchStatus !== 'idle');

  let content = <Box />;
  if (docLoading) {
    content = <LinearProgress />;
  } else if (query.isError) {
    content = <RouteError />;
  } else if (
    query.data &&
    query.data.ProcStatus === ProcStatus['Invite Sent']
  ) {
    content = (
      <Box sx={{ p: 1, px: 2 }}>
        <Alert severity="info">
          The invite for your contract has been sent. Check your
          email(spam/junk) for the contract link. If you have not received the
          link email{' '}
          <a href="contracting@naaleads.com">contracting@naaleads.com</a>.
        </Alert>
      </Box>
    );
  } else if (
    query.data &&
    query.data.ProcStatus === ProcStatus['Pending (legacy)']
  ) {
    content = (
      <Box sx={{ p: 1, px: 2 }}>
        <Alert severity="info">
          We will attempt to process existing requests in the legacy contracting
          system for 30 days. Otherwise you'll need to start a new request here.
        </Alert>
      </Box>
    );
  } else if (query.data && query.data.SignerUrl) {
    content = <ContractAdobe doc={query.data} />;
  } else if (query.data && query.data.Filename && AgtDocID && carrier) {
    content = (
      <ContractPDF AgtDocID={AgtDocID} CarrierInitials={carrier.Carrier} />
    );
  } else if (!AgtDocID && props.doc && props.doc.HasFormQuestions) {
    content = <ContractQuestions doc={props.doc} queryKey={props.queryKey} />;
  } else if (!AgtDocID && props.doc) {
    content = <ContractIntro doc={props.doc} queryKey={props.queryKey} />;
  }

  // Display Pending Reasons for contract
  let pendingReasons: JSX.Element | null = null;
  if (query.data && query.data.PendingReasons.length) {
    pendingReasons = <ContractStartedReasons data={query.data} />;
  }

  // Display Form Questions for contract
  let formQuestions: JSX.Element | null = null;
  if (query.data && query.data.HasFormQuestions) {
    formQuestions = <ContractStartedQuestions data={query.data} />;
  }

  const isWorking = docLoading || closing;

  const queryClient = useQueryClient();
  const handleClose = async () => {
    if (!isWorking) {
      try {
        setClosing(true);

        // The user may have completed the document and the database is being updated
        // Wait for any possible changes to be written before refetching
        await new Promise((resolve) => setTimeout(resolve, 3000));

        // Refetch data
        await queryClient.invalidateQueries({ queryKey: props.queryKey });

        props.onClose();
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setClosing(false);
      }
    }
  };

  const open = carrier !== undefined;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '85vw', lg: '75vw', xl: '65vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton disabled={isWorking} sx={{ mr: 2 }} onClick={handleClose}>
            {isWorking ? <CircularProgress size={24} /> : <CloseIcon />}
          </IconButton>

          {carrier !== undefined ? (
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ paddingRight: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 88,
                  }}
                >
                  {carrier.Logo ? (
                    <img
                      src={carrier.Logo}
                      alt={carrier.Carrier || 'Carrier'}
                      style={{ maxWidth: 88, maxHeight: 52 }}
                    />
                  ) : (
                    <Box
                      sx={{ width: 88, display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        sx={{ flex: 1, borderBottom: '1px solid #b2650044' }}
                      />
                      <Box
                        sx={{
                          fontFamily: 'Roboto Mono',
                          fontWeight: 'bold',
                          fontSize: 22,
                          color: '#b26500',
                          px: 1,
                        }}
                      >
                        {carrier.Carrier}
                      </Box>
                      <Box
                        sx={{ flex: 1, borderBottom: '1px solid #b2650044' }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ px: 1, flex: 1 }}>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    fontSize: { xs: 14, sm: 18, md: 22 },
                    fontWeight: 'normal',
                  }}
                >
                  {carrier.FullName}
                </Typography>
              </Box>
            </Box>
          ) : null}
        </Toolbar>

        <Divider />

        {pendingReasons}

        {formQuestions}

        {content}
      </Box>
    </Drawer>
  );
}
