import React, { Dispatch } from 'react';

// State
type AdminAgentState = {
  AgtNo: string;
  tab: string;
};
const initialState: AdminAgentState = {
  AgtNo: '',
  tab: '',
};

// Actions
type AdminAgentActions =
  | { type: 'RESET' }
  | { type: 'CHANGE_AGT'; payload: { AgtNo: string } }
  | { type: 'CHANGE_TAB'; payload: { AgtNo: string; tab: string } };

const reducer = (state: AdminAgentState, action: AdminAgentActions) => {
  switch (action.type) {
    case 'RESET': {
      return { ...state, AgtNo: '', tab: '' };
    }
    case 'CHANGE_AGT': {
      const { AgtNo } = action.payload;
      return { ...state, AgtNo };
    }
    case 'CHANGE_TAB': {
      const { AgtNo, tab } = action.payload;
      return { ...state, AgtNo, tab };
    }
    default: {
      return { ...state };
    }
  }
};

export const AdminAgentContext = React.createContext(
  {} as {
    state: AdminAgentState;
    dispatch: Dispatch<AdminAgentActions>;
  }
);

export function AdminAgentProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AdminAgentContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AdminAgentContext.Provider>
  );
}
