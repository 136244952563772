import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { AgentWrtNoData, CarrierData } from '../../types';
import { WrtNoItemDelete } from './wrt-no-item-delete';
import { WrtNoItemUpdate } from './wrt-no-item-update';

export function WrtNoItem(props: {
  AgtNo: string | undefined;
  carrier: CarrierData;
  number: AgentWrtNoData;
}) {
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [status, setStatus] = React.useState<'updating' | 'deleting'>();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ p: 1, border: '1px solid #e1e1e1', borderRadius: 2 }}>
      {status === 'updating' ? (
        <WrtNoItemUpdate
          AgtNo={props.AgtNo}
          carrier={props.carrier}
          number={props.number}
          onClose={() => setStatus(undefined)}
        />
      ) : status === 'deleting' ? (
        <WrtNoItemDelete
          AgtNo={props.AgtNo}
          number={props.number}
          onClose={() => setStatus(undefined)}
        />
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ flex: 1, fontFamily: 'Roboto Mono', fontSize: 22 }}>
            {props.number.WrtCarNo}
          </Box>

          <Tooltip title="Manage writing number" placement="left" arrow>
            <IconButton
              size="small"
              id={`basic-button-${props.carrier.Carrier}`}
              aria-controls={
                open ? `basic-menu-${props.carrier.Carrier}` : undefined
              }
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>

          <Menu
            id={`basic-menu-${props.carrier.Carrier}`}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': `basic-button-${props.carrier.Carrier}`,
            }}
          >
            <MenuItem
              onClick={() => {
                setStatus('updating');
                handleClose();
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setStatus('deleting');
                handleClose();
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}
