import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Alert from '@mui/material/Alert';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import React from 'react';
import { captureError } from '../../utils/capture-error';
import { stateNames } from '../../utils/states';

interface StateOption {
  label: string;
  State: string;
}

const stateOptions: StateOption[] = Object.keys(stateNames).map((key) => ({
  label: stateNames[key],
  State: key,
}));

interface LicenseLOAData {
  LOAId: number;
  LOA: string;
  DateIss: string;
}

export function LicenseAdd(props: { AgtNo: string | undefined }) {
  // State
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    LicNo: '',
    ResStatus: '',
    DateIss: '',
    DateExp: '',
  });
  const [LicState, setLicState] = React.useState<StateOption | null>(null);
  const [LOAs, setLOAs] = React.useState<LicenseLOAData[]>([]);
  const [errMsg, setErrMsg] = React.useState('');

  React.useEffect(() => {
    if (open) {
      setData({
        LicNo: '',
        ResStatus: 'NR',
        DateIss: format(new Date(), 'yyyy-MM-dd'),
        DateExp: format(new Date(), 'yyyy-MM-dd'),
      });
      setLicState(null);
      setLOAs([
        {
          LOAId: new Date().getTime(),
          LOA: '',
          DateIss: format(new Date(), 'yyyy-MM-dd'),
        },
      ]);
      setErrMsg('');
    }
  }, [open]);

  // Query - Available Lines of Authority
  const path = '/agent/loa/types';
  const query = useQuery({
    enabled: open,
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: { LOA: string }[];
      } = await API.post('ContractingAPI', path, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy
  const mutation = useMutation({
    mutationFn: async () => {
      const LOA = LOAs.map((i) => ({ LOA: i.LOA, DateIss: i.DateIss }));

      await API.post('ContractingAPI', '/agent/licenses/upsert', {
        body: {
          AgtNo: props.AgtNo,
          LicNo: data.LicNo,
          State: LicState?.State,
          ResStatus: data.ResStatus,
          DateIss: data.DateIss,
          DateExp: data.DateExp,
          LOA,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/licenses', { AgtNo: props.AgtNo }],
      });

      setOpen(false);
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'invalid'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
      setErrMsg('');
    }
  };

  // Make sure data has been added for LOAs
  let invalidLOAs = false;
  LOAs.forEach((i) => {
    if (!i.LOA || !i.DateIss) {
      invalidLOAs = true;
    }
  });

  const submitDisabled =
    !data.LicNo ||
    !data.ResStatus ||
    !data.DateIss ||
    !data.DateExp ||
    LicState === null ||
    LOAs.length === 0 ||
    invalidLOAs;

  return (
    <React.Fragment>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<NoteAddIcon />}
        onClick={() => setOpen(true)}
      >
        Add License
      </Button>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" noWrap component="div">
                Add Insurance License
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          <Box sx={{ flex: 1, overflow: 'auto', padding: 2 }}>
            <Stack spacing={2}>
              <TextField
                id="license-add-LicNo"
                fullWidth
                required
                label="Insurance License Number"
                name="LicNo"
                value={data.LicNo}
                onChange={handleChange}
              />

              <Autocomplete
                id="license-add-LicState"
                value={LicState}
                onChange={(event, newValue) => {
                  setLicState(newValue);
                  setData((currentState) => ({
                    ...currentState,
                    ResStatus: 'NR',
                  }));
                }}
                options={stateOptions}
                filterOptions={createFilterOptions({
                  stringify: (option) => option.State + ' ' + option.label,
                })}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          fontFamily: 'Roboto Mono',
                          fontWeight: 'bold',
                          color: '#b26500',
                          mr: 1,
                        }}
                      >
                        {option.State}
                      </Box>
                      <Box sx={{ fontSize: 18 }}>{option.label}</Box>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} required label="State" />
                )}
              />

              <Box sx={{ pl: 1 }}>
                <FormControlLabel
                  disabled={LicState === null}
                  control={
                    <Checkbox
                      color="success"
                      checked={data.ResStatus === 'R'}
                      onChange={(event, checked) => {
                        setData((currentState) => ({
                          ...currentState,
                          ResStatus: checked ? 'R' : 'NR',
                        }));
                      }}
                    />
                  }
                  label={`I am a resident of ${LicState?.label || '...'}`}
                />
              </Box>

              <TextField
                id="license-add-DateIss"
                fullWidth
                required
                label="Effective Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="DateIss"
                value={data.DateIss}
                onChange={handleChange}
              />

              <TextField
                id="license-add-DateExp"
                fullWidth
                required
                label="Expiration Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="DateExp"
                value={data.DateExp}
                onChange={handleChange}
              />

              <Box sx={{ p: 1, border: '1px solid #e1e1e1', borderRadius: 1 }}>
                <Stack spacing={2}>
                  {LOAs.map((line) => {
                    return (
                      <Paper
                        key={line.LOAId}
                        elevation={0}
                        sx={{
                          p: 2,
                          display: 'flex',
                          alignItems: 'center',
                          border: '2px solid #2196f3',
                          backgroundColor: '#fafafa',
                        }}
                      >
                        <Stack sx={{ flex: 1 }} spacing={2}>
                          <Autocomplete
                            options={query.data?.map((i) => i.LOA) || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label="Line of Authority"
                              />
                            )}
                            value={line.LOA}
                            onChange={(event, newValue) => {
                              setLOAs((currentState) =>
                                currentState.map((i) => {
                                  if (i.LOAId === line.LOAId) {
                                    return { ...i, LOA: newValue || '' };
                                  } else {
                                    return i;
                                  }
                                })
                              );
                            }}
                          />

                          <TextField
                            fullWidth
                            required
                            size="small"
                            label="Effective Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            name="DateIss"
                            value={line.DateIss}
                            onChange={(event) => {
                              setLOAs((currentState) =>
                                currentState.map((i) => {
                                  if (i.LOAId === line.LOAId) {
                                    return {
                                      ...i,
                                      DateIss: event.target.value,
                                    };
                                  } else {
                                    return i;
                                  }
                                })
                              );
                            }}
                          />
                        </Stack>

                        <Box sx={{ pl: 1 }}>
                          <Tooltip
                            title="Remove Line of Authority"
                            placement="left"
                            arrow
                          >
                            <IconButton
                              onClick={() => {
                                setLOAs((currentState) =>
                                  currentState.filter(
                                    (i) => i.LOAId !== line.LOAId
                                  )
                                );
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Paper>
                    );
                  })}

                  <Button
                    fullWidth
                    variant="outlined"
                    color="info"
                    onClick={() => {
                      setLOAs((currentState) => [
                        ...currentState,
                        {
                          LOAId: new Date().getTime(),
                          LOA: '',
                          DateIss: format(new Date(), 'yyyy-MM-dd'),
                        },
                      ]);
                    }}
                  >
                    Add Line of Authority
                  </Button>
                </Stack>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                  sx={{
                    maxWidth: 420,
                    textAlign: 'center',
                    fontSize: 12,
                    color: '#5a5a5a',
                  }}
                >
                  Any changes made here may be overwritten by updates received
                  from the National Insurance Producer Registry (NIPR).
                </Box>
              </Box>

              {mutation.isError ? (
                <Box sx={{ pt: 1 }}>
                  <Alert
                    onClose={() => {
                      mutation.reset();
                      setErrMsg('');
                    }}
                    severity="error"
                    sx={{ width: '100%' }}
                  >
                    <strong style={{ marginRight: 10 }}>
                      An error has occurred!
                    </strong>
                    {errMsg ? (
                      <small>{errMsg}</small>
                    ) : (
                      <small>Please refresh the page and try again.</small>
                    )}
                  </Alert>
                </Box>
              ) : null}

              <Button
                disabled={submitDisabled || mutation.isLoading}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {mutation.isLoading
                  ? 'Adding License...'
                  : 'Add Insurance License'}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
