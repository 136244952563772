import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { captureError } from '../../utils/capture-error';

export function PolicyDelete(props: {
  AgtNo: string | undefined;
  open: boolean;
  onClose: () => void;
}) {
  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/eo/delete', {
        body: { AgtNo: props.AgtNo },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/eo', { AgtNo: props.AgtNo }],
      });

      props.onClose();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  const handleClose = () => {
    if (!mutation.isLoading) {
      props.onClose();
    }
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>Delete E&O Policy</Box>

        <IconButton aria-label="close" onClick={handleClose}>
          {mutation.isLoading ? <CircularProgress size={24} /> : <CloseIcon />}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete your E&O Policy?
        </DialogContentText>
        {mutation.isError ? (
          <Box sx={{ pt: 2 }}>
            <Alert
              onClose={() => mutation.reset()}
              severity="error"
              sx={{ width: '100%' }}
            >
              <strong style={{ marginRight: 10 }}>
                An error has occurred!
              </strong>
              <small>Please refresh the page and try again.</small>
            </Alert>
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Delete E&O Policy</Button>
      </DialogActions>
    </Dialog>
  );
}
