import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HideSourceIcon from '@mui/icons-material/HideSource';
import HomeIcon from '@mui/icons-material/Home';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import addMonths from 'date-fns/addMonths';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isWithinInterval from 'date-fns/isWithinInterval';
import { ReactComponent as NIPRLogo } from '../../assets/nipr-logo.svg';
import { materialTheme } from '../../theme';
import { LicenseData } from '../../types';

export function LicenseTile(props: { item: LicenseData; onClick: () => void }) {
  // Props
  const { item } = props;

  let isExpiring = false;
  if (item.DateExp) {
    isExpiring =
      isWithinInterval(new Date(item.DateExp + 'T00:00'), {
        start: new Date(),
        end: addMonths(new Date(), 3),
      }) || false;
  }

  return (
    <Paper
      elevation={0}
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#fefefe',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#03182E22',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.01)',
          borderColor: '#03182E',
          backgroundColor: '#fff',
        },
      }}
    >
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ fontWeight: 'bold', fontSize: 22, pr: 1 }}>{item.State}</Box>

        {item.Active === 'Yes' ? (
          <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
            <CheckCircleIcon fontSize="small" color="secondary" />
          </Box>
        ) : item.Active === 'No' ? (
          <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
            <HideSourceIcon fontSize="small" sx={{ color: '#03182E44' }} />
          </Box>
        ) : null}

        {item.ResStatus === 'R' ? (
          <Box>
            <Chip
              label="Resident"
              size="small"
              variant="outlined"
              color="secondary"
              icon={<HomeIcon />}
              sx={{
                borderRadius: 1,
                color: materialTheme.palette.primary.main,
              }}
            />
          </Box>
        ) : null}

        <Box sx={{ flex: 1 }} />

        <Box
          sx={{
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            fontSize: 14,
            color: '#b26500',
          }}
        >
          {item.LicNo}
        </Box>
      </Box>

      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 11,
            letterSpacing: 1,
            color: '#9b9b9b',
          }}
        >
          Class
        </Box>

        <Box>{item.Class || 'Insurance Producer'}</Box>
      </Box>

      <Box sx={{ flex: 1, padding: 1 }}>
        <Box
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 11,
            letterSpacing: 1,
            color: '#9b9b9b',
          }}
        >
          Lines of Authority
        </Box>

        {item.Details.map((line) => {
          return <Box key={line.LOAId}>{line.LOA}</Box>;
        })}
      </Box>

      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 11,
            letterSpacing: 1,
            color: '#9b9b9b',
          }}
        >
          Effective
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontFamily: 'Roboto Mono' }}>{item.DateIss || '---'}</Box>

          {item.DateIss ? (
            <Box
              sx={{
                paddingLeft: 1,
                fontSize: 14,
                color: '#7a7a7a',
              }}
            >
              {formatDistanceToNowStrict(new Date(item.DateIss + 'T00:00'), {
                addSuffix: true,
              })}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box sx={{ padding: 1 }}>
        <Box
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 11,
            letterSpacing: 1,
            color: '#9b9b9b',
          }}
        >
          Expiring
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontFamily: 'Roboto Mono' }}>{item.DateExp || '---'}</Box>

          {item.DateExp ? (
            <Box
              sx={{
                paddingLeft: 1,
                fontSize: 14,
                color: '#7a7a7a',
              }}
            >
              {formatDistanceToNowStrict(new Date(item.DateExp + 'T00:00'), {
                addSuffix: true,
              })}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 8,
          right: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {isExpiring ? (
          <Chip
            label="Expiring soon"
            size="small"
            variant="outlined"
            color="warning"
            icon={<WarningAmberIcon />}
            sx={{ borderRadius: 1 }}
          />
        ) : null}

        {item.IsNIPR ? (
          <Box sx={{ pl: 1 }}>
            <NIPRLogo style={{ height: 24, width: 24 }} />
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
}
