import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import NumbersIcon from '@mui/icons-material/Numbers';
import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { ReactComponent as NIPRLogo } from '../../assets/nipr-logo.svg';
import { AgentNPNData, CarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';

interface VerifyNPNData {
  NPN: string;
  NAME_LAST: string;
  NAME_FIRST: string;
  NAME_MIDDLE: string;
  DATE_BIRTH: string;
}

export function WrtNPNItem(props: {
  AgtNo: string | undefined;
  carrier: CarrierData;
  number: AgentNPNData | undefined;
}) {
  // Props
  const { carrier, number } = props;
  // State
  const [open, setOpen] = React.useState(false);
  const [NPN, setNPN] = React.useState('');
  const [verify, setVerify] = React.useState<VerifyNPNData>();
  const [tryAgain, setTryAgain] = React.useState(false);
  // Hooks
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (open && number && number.NPN) {
      setNPN(number.NPN.toString());
      setVerify(undefined);
      setTryAgain(false);
    }
  }, [number, open]);

  // Mutation - Update Agent's National Producer Number
  const mutation = useMutation({
    mutationFn: async (Confirmed: boolean) => {
      const response: {
        data: VerifyNPNData | 1 | null;
      } = await API.post('ContractingAPI', '/agent/info/npn/update', {
        body: { NPN, AgtNo: props.AgtNo, Confirmed },
      });

      if (
        response.data &&
        typeof response.data === 'object' &&
        'NPN' in response.data
      ) {
        setVerify(response.data);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/info', { AgtNo: props.AgtNo }],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    setTryAgain(false);
    mutation.mutate(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
      mutation.reset();
    }
  };

  const isActive = Boolean(props.number && props.number.NPN);

  const submitDisabled = !NPN.trim();

  // User is an agent or impersonating an Agent and their NPN has not been locked
  // The NPN is locked after it has been retrieved from NIPR
  const disableEdit = (number && number.LockNPN === 1) || !isAgt(props.AgtNo);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        tabIndex={0}
        onClick={!disableEdit ? () => handleClickOpen() : () => {}}
        onKeyDown={(event) => {
          if (!disableEdit && event.key === 'Enter') {
            handleClickOpen();
          }
        }}
        sx={{
          cursor: !disableEdit ? 'pointer' : 'default',
          p: 1,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          borderWidth: isActive ? 2 : 1,
          borderStyle: 'solid',
          borderColor: isActive ? '#00C86888' : '#03182E22',
          backgroundColor: isActive ? '#00C86804' : '#fff',
          transition: 'all 0.2s',
          ':hover': {
            borderColor: '#03182E77',
          },
          ':hover #empty-writing-no': {
            color: '#d1d1d1',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 66,
            minHeight: 44,
          }}
        >
          <NumbersIcon fontSize="large" sx={{ color: '#b26500' }} />
        </Box>

        <Box sx={{ pl: 1, flex: 1 }}>
          <Box sx={{ fontSize: 22, fontFamily: 'Roboto Mono' }}>
            {number?.NPN || (
              <Box
                id="empty-writing-no"
                sx={{ color: '#efefef', transition: 'all 0.2s' }}
              >
                000000000
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                mr: 0.6,
                fontSize: 11,
                fontFamily: 'Roboto Mono',
                fontWeight: 'bold',
              }}
            >
              {carrier.Carrier}
            </Box>

            <Box sx={{ fontSize: 12 }}>{carrier.FullName || '---'}</Box>
          </Box>
        </Box>

        {number?.LockNPN === 1 ? (
          <Tooltip
            title="Your NPN has been retrieved from NIPR"
            placement="left"
            arrow
          >
            <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
              <NIPRLogo style={{ height: 24, width: 24 }} />
            </Box>
          </Tooltip>
        ) : isAgt(props.AgtNo) ? (
          <Tooltip
            title={
              isActive
                ? 'Edit National Producer Number'
                : 'Add National Producer Number'
            }
            placement="left"
            arrow
          >
            <Box
              sx={{
                p: 0.6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #00000011',
                borderRadius: 2,
                color: '#a1a1a1',
                transition: 'all 0.2s',
                ':hover': { color: '#212121' },
              }}
            >
              <EditIcon fontSize="small" />
            </Box>
          </Tooltip>
        ) : null}
      </Paper>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'inline',
              fontFamily: 'Roboto Mono',
              fontSize: 14,
              fontWeight: 'bold',
              mr: 1,
            }}
          >
            {carrier.Carrier}
          </Box>

          <Box sx={{ flex: 1 }}>{carrier.FullName}</Box>

          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText paddingBottom={2}>
            Any changes made here may be overwritten by updates received from
            the National Insurance Producer Registry (NIPR).
          </DialogContentText>

          <Box sx={{ p: 1, border: '1px solid #e1e1e1', borderRadius: 2 }}>
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="writing-number-npn"
                  autoFocus
                  fullWidth
                  variant="outlined"
                  label="National Producer Number"
                  placeholder="000000000"
                  inputProps={{
                    style: { fontFamily: 'Roboto Mono', fontSize: 22 },
                  }}
                  InputLabelProps={{ shrink: true }}
                  value={NPN}
                  onChange={(event) => setNPN(event.target.value)}
                />

                <Box sx={{ pl: 1 }}>
                  <Tooltip title="Save changes" placement="left" arrow>
                    <span>
                      <IconButton
                        disabled={
                          submitDisabled ||
                          mutation.isLoading ||
                          mutation.isSuccess
                        }
                        size="small"
                        onClick={handleSubmit}
                      >
                        {mutation.isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <SaveIcon
                            color={
                              submitDisabled ||
                              mutation.isLoading ||
                              mutation.isSuccess
                                ? 'disabled'
                                : 'info'
                            }
                          />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>

              {tryAgain ? (
                <Alert severity="warning" onClose={() => setTryAgain(false)}>
                  <AlertTitle>Check NPN</AlertTitle>
                  Make sure you’ve entered your National Producer Number and try
                  again.
                </Alert>
              ) : null}

              {mutation.isSuccess && number?.LockNPN === 0 && verify ? (
                <Alert severity="info">
                  <AlertTitle>Confirm NIPR Info</AlertTitle>
                  <Stack spacing={2}>
                    <Box>
                      Does this information retrieved from NIPR look correct?
                    </Box>
                    <Box sx={{ fontFamily: 'Roboto Mono' }}>
                      {Object.keys(verify).map((key) => {
                        const value = verify[key as keyof VerifyNPNData];
                        return (
                          <Box key={key}>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <Box
                                sx={{
                                  flex: 1,
                                  fontWeight: 'bold',
                                }}
                              >
                                {key}
                              </Box>
                              <Box sx={{ flex: 2 }}>{value}</Box>
                            </Stack>
                          </Box>
                        );
                      })}
                    </Box>

                    <Stack
                      spacing={1}
                      direction={{ xs: 'column', sm: 'row' }}
                      alignItems="center"
                    >
                      <Box sx={{ flex: 1, width: '100%' }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => {
                            setTryAgain(true);
                            // Reset the success state of the mutation
                            mutation.reset();
                          }}
                        >
                          Try again
                        </Button>
                      </Box>

                      <Box sx={{ flex: 1.5, width: '100%' }}>
                        <Button
                          fullWidth
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            // Call the mutation again with a Confirmed value of true
                            mutation.mutate(true);
                          }}
                        >
                          Confirm Info
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                </Alert>
              ) : null}

              {mutation.isSuccess && number?.LockNPN === 1 ? (
                <Alert severity="success" onClose={handleClose}>
                  <AlertTitle>NIPR Info Saved</AlertTitle>
                  Your information has been retrieved from NIPR and has been
                  saved.
                </Alert>
              ) : null}

              {mutation.isError ? (
                <Alert severity="error" onClose={() => mutation.reset()}>
                  <AlertTitle>Error</AlertTitle>
                  {
                    // @ts-ignore
                    mutation.error?.response?.data?.error ||
                      'Please refresh the page and try again.'
                  }
                </Alert>
              ) : null}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
