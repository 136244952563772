import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HideSourceIcon from '@mui/icons-material/HideSource';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import addMonths from 'date-fns/addMonths';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isWithinInterval from 'date-fns/isWithinInterval';
import { ReactComponent as NIPRLogo } from '../../assets/nipr-logo.svg';
import { LicenseData } from '../../types';
import { getStateName } from '../../utils/states';

export function LicenseInfoDetails(props: { license: LicenseData }) {
  // Props
  const { license } = props;

  let isExpiring = false;
  if (license.DateExp) {
    isExpiring =
      isWithinInterval(new Date(license.DateExp + 'T00:00'), {
        start: new Date(),
        end: addMonths(new Date(), 3),
      }) || false;
  }

  return (
    <Box sx={{ flex: 1, overflow: 'auto', pb: 8 }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        {license.IsNIPR ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <NIPRLogo style={{ height: 24, width: 24 }} />

            <Box sx={{ fontSize: 12, color: '#5a5a5a', paddingLeft: 1 }}>
              The information for this license was provided by the National
              Insurance Producer Registry (NIPR).
            </Box>
          </Box>
        ) : null}

        {license.Active === 'No' ? null : isExpiring ? (
          <Alert color="warning" severity="warning">
            This License is Expiring soon!
          </Alert>
        ) : null}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ fontSize: 24, pr: 1 }}>{getStateName(license.State)}</Box>
          {license.Active === 'Yes' ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon fontSize="small" color="secondary" />
              <Box
                sx={{
                  pl: 1,
                  fontSize: 12,
                  fontStyle: 'italic',
                  color: '#9b9b9b',
                }}
              >
                Active
              </Box>
            </Box>
          ) : license.Active === 'No' ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <HideSourceIcon fontSize="small" sx={{ color: '#03182E44' }} />
              <Box
                sx={{
                  pl: 1,
                  fontSize: 12,
                  fontStyle: 'italic',
                  color: '#9b9b9b',
                }}
              >
                Inactive
              </Box>
            </Box>
          ) : null}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              pr: 1,
              minWidth: 80,
              textAlign: 'right',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 11,
              letterSpacing: 1,
              color: '#2196f3',
            }}
          >
            License
          </Box>

          <Box sx={{ fontFamily: 'Roboto Mono' }}>{license.LicNo}</Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              pr: 1,
              minWidth: 80,
              textAlign: 'right',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 11,
              letterSpacing: 1,
              color: '#2196f3',
            }}
          >
            State
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {license.State}
            {license.ResStatus === 'R' ? (
              <Box
                sx={{
                  pl: 1,
                  fontSize: 12,
                  fontStyle: 'italic',
                  color: '#9b9b9b',
                }}
              >
                Resident
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              pr: 1,
              minWidth: 80,
              textAlign: 'right',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 11,
              letterSpacing: 1,
              color: '#2196f3',
            }}
          >
            Class
          </Box>

          <Box>{license.Class || 'Insurance Producer'}</Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              pr: 1,
              minWidth: 80,
              textAlign: 'right',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 11,
              letterSpacing: 1,
              color: '#2196f3',
            }}
          >
            Effective
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ fontFamily: 'Roboto Mono' }}>
              {license.DateIss || '---'}
            </Box>

            {license.DateIss ? (
              <Box
                sx={{
                  paddingLeft: 1,
                  fontSize: 14,
                  color: '#7a7a7a',
                }}
              >
                {formatDistanceToNowStrict(
                  new Date(license.DateIss + 'T00:00'),
                  { addSuffix: true }
                )}
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              pr: 1,
              minWidth: 80,
              textAlign: 'right',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: 11,
              letterSpacing: 1,
              color: '#2196f3',
            }}
          >
            Expiring
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ fontFamily: 'Roboto Mono' }}>
              {license.DateExp || '---'}
            </Box>

            {license.DateExp ? (
              <Box
                sx={{
                  paddingLeft: 1,
                  fontSize: 14,
                  color: '#7a7a7a',
                }}
              >
                {formatDistanceToNowStrict(
                  new Date(license.DateExp + 'T00:00'),
                  { addSuffix: true }
                )}
              </Box>
            ) : null}
          </Box>
        </Box>

        {license.Details.map((detail) => {
          let borderColor = '#03182E22';
          let backgroundColor = '#fff';
          if (detail.LOAStatus === 'Active') {
            borderColor = '#00C86888';
            backgroundColor = '#00C86804';
          } else if (detail.LOAStatus === 'Inactive') {
            backgroundColor = '#fafafa';
          }

          return (
            <Box key={detail.LOAId}>
              <Paper
                elevation={0}
                sx={{
                  p: 1,
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor,
                  backgroundColor,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        fontSize: 12,
                        fontFamily: 'Roboto Mono',
                        color: '#4a4a4a',
                      }}
                    >
                      Line of Authority issued {detail.DateIss}
                    </Box>

                    <Box
                      sx={{
                        fontSize: 20,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {detail.LOA}
                    </Box>

                    <Box
                      sx={{
                        fontSize: 12,
                        fontStyle: 'italic',
                        color: '#7a7a7a',
                      }}
                    >
                      {detail.LOAStatus} {detail.ReasonDate} {detail.Reason}
                    </Box>
                  </Box>

                  {detail.LOAStatus?.toLowerCase() === 'active' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <CheckCircleIcon
                        fontSize="small"
                        color="secondary"
                        sx={{ mr: 0.4 }}
                      />
                    </Box>
                  ) : detail.LOAStatus?.toLowerCase() === 'inactive' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <HideSourceIcon
                        fontSize="small"
                        sx={{ color: '#03182E22', mr: 0.4 }}
                      />
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
}
