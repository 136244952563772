import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GroupsIcon from '@mui/icons-material/Groups';
import SummarizeIcon from '@mui/icons-material/Summarize';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { PaperExpandable } from '../../components/paper-expandable';
import { ActionItemData } from '../../types';

export function ActionItems(props: { data: ActionItemData[] }) {
  return (
    <PaperExpandable title="Action Items" count={props.data.length}>
      <Box>
        {props.data.length === 0 ? (
          <Box
            sx={{
              p: 4,
              textAlign: 'center',
              fontFamily: 'Roboto Mono',
              color: '#9b9b9b',
            }}
          >
            No items require your attention
          </Box>
        ) : (
          props.data.map((item, index) => {
            let icon = <div />;
            let path = '';
            if (item.Type === 'Approve Agent') {
              icon = <GroupsIcon fontSize="small" />;
              path = '/team';
            } else if (item.Type === 'Contract') {
              icon = <SummarizeIcon fontSize="small" />;
              path = '/contracts';
            }

            const borderTop = index === 0 ? 'none' : '1px solid #00000011';

            const isEven = index % 2 === 0;

            return (
              <Box
                component={Link}
                to={path}
                key={item.Message}
                sx={{
                  borderTop,
                  display: 'block',
                  backgroundColor: isEven ? '#fff' : '#fcfcfc',
                  textDecorationLine: 'none',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: '#f5f5f5',
                  },
                  ':hover #chevron-icon': {
                    color: '#03182E',
                    mr: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                  <Box
                    sx={{
                      padding: 1,
                      height: 28,
                      width: 28,
                      borderRadius: 3,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#fafafa',
                      color: '#b26500',
                    }}
                  >
                    {icon}
                  </Box>

                  <Box sx={{ flex: 1, padding: 1 }}>
                    <Box
                      sx={{
                        fontSize: 16,
                        color: '#000',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.Title}
                    </Box>
                    <Box sx={{ fontSize: 12, color: '#333' }}>
                      {item.Message}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      minWidth: 38,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ChevronRightIcon
                      id="chevron-icon"
                      sx={{
                        color: '#03182E44',
                        transition: 'all 0.2s',
                        mr: 0.8,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </Box>
    </PaperExpandable>
  );
}
