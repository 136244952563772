import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../contexts/auth-context';
import { DocumentAgentData, DocumentCarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { RouteError } from '../route-container/route-error';
import { TaxDocPDF } from './tax-doc-pdf';

const DocIframe = React.memo(function DocIframe(props: { src: string }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title="Tax Document (W-9)"
        src={props.src}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

export function TaxDoc(props: {
  open: boolean;
  doc: DocumentCarrierData | undefined;
  onClose: () => void;
  onSuccess: () => void;
}) {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [fetching, setFetching] = React.useState({
    Closing: false,
  });
  const [AgtDocID, setAgtDocID] = React.useState<
    DocumentAgentData['AgtDocID'] | null
  >();

  React.useEffect(() => {
    if (props.open && props.doc) {
      setAgtDocID(props.doc.AgtDocID);
      setFetching({ Closing: false });
    }
  }, [props.open, props.doc]);

  const queryClient = useQueryClient();

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const handleClose = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, Closing: true }));

      // Check to see if the Agreement has been signed
      // Adobe may have not called the API webhook right away
      // so wait one second before checking if the Agreement has been signed
      await new Promise((resolve) => setTimeout(resolve, 5000));

      // Refetch Agreement status
      await queryClient.invalidateQueries({
        queryKey: ['/documents/list', { DocTitle: 'Agent W9' }],
      });

      // Refetch Getting Started
      await queryClient.invalidateQueries({
        queryKey: ['/agent/getting-started', { AgtNo }],
      });

      props.onClose();
      setAgtDocID(undefined);
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, Closing: false }));
    }
  };

  // Query - Get Agent Agreement
  const path = '/documents/get';
  const query = useQuery({
    enabled: Boolean(
      props.open && props.doc && AgtDocID !== undefined && AgtDocID !== null,
    ),
    retry: 2,
    retryDelay: 3000,
    queryKey: [path, { AgtDocID }],
    queryFn: async () => {
      const response: {
        data: DocumentAgentData | null;
      } = await API.post('ContractingAPI', path, {
        body: { AgtDocID },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Create Agent Agreement
  const pathCreate = '/documents/create';
  const DocID = props.doc?.DocID;
  const queryCreate = useQuery({
    enabled: Boolean(
      props.open &&
        props.doc &&
        props.doc.Signed === null &&
        AgtDocID !== undefined &&
        AgtDocID === null,
    ),
    retry: 2,
    retryDelay: 3000,
    queryKey: [pathCreate, { DocID }],
    queryFn: async () => {
      const response: {
        data: DocumentAgentData | null;
      } = await API.post('ContractingAPI', pathCreate, {
        body: { DocID },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onSuccess: async (data) => {
      if (data) {
        setAgtDocID(data.AgtDocID);
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  let content = <Box />;
  if (fetching.Closing || query.isFetching || queryCreate.isFetching) {
    content = <LinearProgress />;
  } else if (query.isError || queryCreate.isError) {
    content = <RouteError />;
  } else if (query.data && query.data.SignerUrl) {
    content = (
      <Box
        sx={{ minHeight: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        {query.data.SignerUrl ? <DocIframe src={query.data.SignerUrl} /> : null}
      </Box>
    );
  } else if (query.data && query.data.Filename && AgtDocID) {
    content = <TaxDocPDF AgtDocID={AgtDocID} />;
  }

  const isWorking =
    fetching.Closing ||
    Boolean(queryCreate.isFetching) ||
    Boolean(AgtDocID && query.isFetching);

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '85vw', lg: '75vw', xl: '65vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton disabled={isWorking} sx={{ mr: 2 }} onClick={handleClose}>
            {isWorking ? <CircularProgress size={24} /> : <CloseIcon />}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ px: 1, flex: 1 }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontSize: { xs: 14, sm: 18, md: 22 },
                  fontWeight: 'normal',
                }}
              >
                Tax Document (W-9)
              </Typography>
            </Box>
          </Box>
        </Toolbar>

        <Divider />

        {content}
      </Box>
    </Drawer>
  );
}
