import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { AnimateIn } from '../../../components/animate-in';
import { PaperExpandable } from '../../../components/paper-expandable';
import { RouteError } from '../../../components/route-container/route-error';
import { SearchField, searchBy } from '../../../components/search-field';
import { NotificationData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { isAgt } from '../../../utils/is-agent';
import { subarray } from '../../../utils/subarray';
import { NotificationItem } from './notification-item';

export function Notifications(props: { AgtNo: string | undefined }) {
  // Props
  const { AgtNo } = props;
  // State
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [search, setSearch] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  // Query - Notifications
  const path = '/notifications/all';
  const query = useQuery({
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: NotificationData[];
        } = await API.post('ContractingAPI', path, { body: { AgtNo } });

        if (response.data) {
          return response.data;
        }
      }

      return [];
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'forbidden'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  // Used by TablePagination
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formattedQuery = query.data?.map((i) => {
    const data = {
      ...i,
      CreatedDate: format(new Date(i.CreatedDate || ''), 'MM/dd/yyyy'),
    };
    return data;
  });
  // Searching
  const searchedItems = searchBy(formattedQuery || [], search);
  const sortedItems = orderBy(searchedItems, 'CreatedDate', 'desc');
  const start = page * rowsPerPage;
  const end = start + rowsPerPage;
  const renderItems = subarray({ data: sortedItems, start, end });

  const tablePagination = (
    <TablePagination
      rowsPerPageOptions={[50, 100]}
      component="div"
      count={query.data?.length || 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  if (Boolean(query.isLoading && query.fetchStatus !== 'idle')) {
    return <LinearProgress color="info" />;
  } else if (query.isError && errMsg) {
    return (
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Alert severity="warning">
          Your are not able to view{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            Notifications
          </Box>{' '}
          for the impersonated Agent{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {props.AgtNo}
          </Box>
          .
        </Alert>
      </Container>
    );
  } else if (query.isError) {
    return <RouteError />;
  } else {
    return (
      <AnimateIn to="top">
        <Container maxWidth="md" sx={{ pt: 2 }}>
          <PaperExpandable
            title="Notifications"
            count={query.data?.length || 0}
            open
          >
            {query.data?.length === 0 ? (
              <Box
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontFamily: 'Roboto Mono',
                  color: '#9b9b9b',
                }}
              >
                No notifications at this time
              </Box>
            ) : (
              <Stack>
                <Box
                  sx={{
                    borderBottom: '1px solid #00000011',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    px: 1,
                  }}
                >
                  <SearchField
                    id="search-notifications"
                    value={search}
                    onChange={(event) => setSearch(event.target.value)}
                  />
                  {tablePagination}
                </Box>
                {renderItems?.map((item, index) => {
                  const borderTop =
                    index === 0 ? 'none' : '1px solid #00000011';

                  const isEven = index % 2 === 0;

                  return (
                    <Box
                      key={item.NotificationId}
                      sx={{
                        borderTop,
                        backgroundColor: isEven ? '#fff' : '#fcfcfc',
                      }}
                    >
                      <NotificationItem data={item} />
                    </Box>
                  );
                })}{' '}
                <Box sx={{ borderTop: '1px solid #00000011', px: 1 }}>
                  {tablePagination}
                </Box>
              </Stack>
            )}
          </PaperExpandable>
        </Container>
      </AnimateIn>
    );
  }
}
