import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteError } from '../../components/route-container/route-error';
import { EOPolicyData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { PolicyDocDelete } from './policy-doc-delete';

export const PolicyIframe = React.memo(function DocIframe(props: {
  policy: EOPolicyData;
  data: any;
}) {
  return (
    <iframe
      title={props.policy.Filename || 'Contracting Document'}
      src={window.URL.createObjectURL(
        new Blob([props.data], {
          type: props.policy.MimeType || 'application/pdf',
        })
      )}
      style={{ flex: 1, width: '100%', border: 'none' }}
    />
  );
});

export function PolicyView(props: {
  AgtNo: string | undefined;
  policy: EOPolicyData;
}) {
  // Props
  const { AgtNo, policy } = props;
  // State
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    if (props.policy) {
      setAnchorEl(null);
      setDeleting(false);
    }
  }, [props.policy]);

  // Query - Errors & Omissions File
  const path = '/agent/file';
  const FileUploadID = props.policy.FileUploadID;
  const query = useQuery({
    enabled: open,
    queryKey: [path, { AgtNo, FileUploadID }],
    queryFn: async () => {
      const response = await API.post('ContractingAPI', path, {
        responseType: 'arraybuffer',
        body: { AgtNo, FileUploadID },
      });

      if (response) {
        return response;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDownload = () => {
    const url = window.URL.createObjectURL(
      new Blob([query.data], { type: policy?.MimeType || '' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      policy?.Filename || policy?.StreamID || 'document'
    );
    document.body.appendChild(link);
    link.click();
  };

  let displayFile = <Box />;
  if (policy && policy.MimeType) {
    if (policy.MimeType.includes('pdf')) {
      displayFile = <PolicyIframe policy={policy} data={query.data} />;
    } else if (policy.MimeType.includes('image')) {
      displayFile = (
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#9b9b9b',
            overflow: 'auto',
          }}
        >
          <Box
            component="img"
            src={window.URL.createObjectURL(
              new Blob([query.data], { type: policy.MimeType })
            )}
            sx={{ width: { xs: '80%', md: '60%' } }}
          />
        </Box>
      );
    } else {
      displayFile = (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            endIcon={<DownloadIcon />}
            onClick={handleDownload}
          >
            Download file
          </Button>
        </Box>
      );
    }
  }

  const handleClose = async () => {
    if (!loading) {
      setOpen(false);
    }
  };

  const loading = query.isLoading || query.isFetching;

  return (
    <React.Fragment>
      <Box
        component="button"
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          width: '100%',
          textAlign: 'left',
          py: 1,
          px: 2,
          backgroundColor: '#00C86804',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#00C86888',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            borderColor: '#00C868',
          },
        }}
      >
        <InsertDriveFileIcon sx={{ color: '#b26500' }} />

        <Box sx={{ flex: 1, pl: 1 }}>
          <Box sx={{ fontSize: { xs: 12, md: 18 } }}>
            View a copy of your certificate
          </Box>
        </Box>

        <DownloadIcon sx={{ color: '#7a7a7a' }} />
      </Box>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', md: '85vw', lg: '75vw', xl: '65vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton disabled={loading} sx={{ mr: 2 }} onClick={handleClose}>
              {loading ? <CircularProgress size={24} /> : <CloseIcon />}
            </IconButton>

            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  flex: 1,
                  fontSize: { xs: 16, md: 18 },
                  fontWeight: 'normal',
                }}
              >
                Errors & Omissions Certificate
              </Typography>

              {deleting ? (
                <Tooltip title="Cancel" placement="left" arrow>
                  <IconButton size="small" onClick={() => setDeleting(false)}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Manage document" placement="left" arrow>
                  <IconButton
                    size="small"
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClickMenu}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              )}

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
              >
                <MenuItem
                  onClick={() => {
                    handleDownload();
                    handleCloseMenu();
                  }}
                >
                  Download
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setDeleting(true);
                    handleCloseMenu();
                  }}
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>

          <Divider />

          {deleting ? (
            <PolicyDocDelete
              AgtNo={props.AgtNo}
              policy={props.policy}
              onSuccess={() => setOpen(false)}
            />
          ) : null}

          {loading ? (
            <LinearProgress />
          ) : query.isError ? (
            <RouteError />
          ) : query.data !== null ? (
            <Box
              sx={{
                minHeight: 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {displayFile}
            </Box>
          ) : null}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
