import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForwardIcon from '@mui/icons-material/Forward';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

export function AgreementBtn(props: {
  completed?: boolean;
  onClick: () => void;
}) {
  return (
    <Paper
      elevation={0}
      component="button"
      onClick={props.onClick}
      sx={{
        position: 'relative',
        fontFamily: 'Roboto',
        padding: 0,
        width: '100%',
        minHeight: 64,
        display: 'flex',
        alignItems: 'center',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: props.completed ? '#00C868cc' : '#2196f3cc',
        backgroundColor: '#fff',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.01)',
          borderColor: props.completed ? '#00C868' : '#2196f3',
        },
        ':hover #forward-icon': {
          color: '#03182E',
        },
      }}
    >
      {props.completed ? null : (
        <Box
          sx={{
            position: 'absolute',
            top: -10,
            left: 8,
            px: 0.8,
            py: 0.4,
            backgroundColor: '#2196f3',
            borderRadius: 1,
            color: '#fff',
            fontSize: 10,
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          Important
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ px: 2, display: 'flex', alignItems: 'center' }}>
          <LabelImportantIcon fontSize="large" sx={{ color: '#b26500' }} />
        </Box>
        {props.completed ? (
          <Box sx={{ fontSize: { sm: 14, md: 18 } }}>Your Agent Agreement</Box>
        ) : (
          <Box sx={{ fontSize: { sm: 14, md: 18 } }}>
            Click here to complete your Agent Agreement
          </Box>
        )}
      </Box>

      <Box sx={{ flex: 1 }} />

      <Box
        sx={{
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.completed ? (
          <CheckCircleIcon color="secondary" />
        ) : (
          <ForwardIcon
            id="forward-icon"
            sx={{ transition: 'all 0.2s', color: '#03182E22' }}
          />
        )}
      </Box>
    </Paper>
  );
}
