import localForage from 'localforage';

const REDIRECT_KEY = 'contracting-redirect-path';

/**
 * Add the route to storage for use after logging in
 */
export async function storeRedirectPath(params: { pathname: string }) {
  await localForage.setItem(REDIRECT_KEY, params.pathname);
}

/**
 * Remove redirect path data from storage
 */
async function removeRedirectPath() {
  await localForage.removeItem(REDIRECT_KEY);
}

/**
 * Check if there is a path stored in the browser to redirect the user to after logging in
 */
export async function checkForRedirectPath() {
  const pathname = await localForage.getItem(REDIRECT_KEY);

  // If there was anything stored in the browser it is now in the "pathname" variable
  // so we can safely remove the item that was stored with the REDIRECT_KEY
  await removeRedirectPath();

  if (pathname && typeof pathname === 'string') {
    // return the pathname of the route the user was on
    return pathname;
  } else {
    return null;
  }
}
