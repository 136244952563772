import ForwardIcon from '@mui/icons-material/Forward';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { DisplayMarkup } from '../../components/display-markup';
import { AuthContext } from '../../contexts/auth-context';
import { AuthorizeData, DocumentCarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function ContractIntro(props: {
  doc: DocumentCarrierData;
  queryKey: QueryFilters['queryKey'];
}) {
  // Context
  const {
    state: { impersonatedAgent, authorize },
    dispatch,
  } = React.useContext(AuthContext);
  // State
  const [fetching, setFetching] = React.useState({
    Authorize: false,
    NIPR: false,
  });

  // Fetch Authorize info after NIPR data
  const fetchAuthorize = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, Authorize: true }));
      const pathAuth = '/authorize';
      const response: {
        data: AuthorizeData;
      } = await API.post('ContractingAPI', pathAuth, {});
      if (response.data) {
        // Update cache
        queryClient.setQueryData([pathAuth], response.data);
        // Update Context
        dispatch({ type: 'SET_AUTHORIZE', payload: response.data });
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, Authorize: false }));
    }
  };

  // Fetch NIPR data on close
  const fetchNIPR = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, NIPR: true }));
      await API.post('ContractingAPI', '/nipr/info/initiate', {});
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, NIPR: false }));
    }
  };

  const queryClient = useQueryClient();
  // Mutation - Create new Contract for Carrier
  const mutation = useMutation({
    mutationFn: async () => {
      if (
        impersonatedAgent === null &&
        authorize &&
        authorize.Agent &&
        authorize.Agent.HasNIPRData !== '1'
      ) {
        // Fetch NIPR info
        await fetchNIPR();
        // Fetch Authorize info to see if "HasNIPRData" or "HasSignedICA" has been updated
        await fetchAuthorize();
      }

      // Create Contract
      const response: {
        data: { AgtDocID: number; SignerUrl: string | null };
      } = await API.post('ContractingAPI', '/documents/create', {
        body: { DocID: props.doc.DocID },
      });

      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: props.queryKey });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isInvite = props.doc.DocType === 'Invite';

  // Disable Invite button for these Carriers
  // For these only the Instructions are needed which have a link to where they can contract
  const isLinkOnly =
    props.doc.Carrier === 'HMA' ||
    props.doc.Carrier === 'PHM' ||
    props.doc.Carrier === 'NAC' ||
    props.doc.Carrier === 'SAG' ||
    props.doc.Carrier === 'AUS' ||
    props.doc.Carrier === 'GAC';

  return (
    <Box
      sx={{
        minHeight: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ p: 2, flex: 1, overflow: 'auto' }}>
        <DisplayMarkup markup={props.doc.Instructions} />
      </Box>

      <Divider />

      <Box
        sx={{
          backgroundColor: '#fffbf3',
          px: { xs: 4, md: 8 },
          py: { xs: 2, md: 6 },
        }}
      >
        {isLinkOnly ? null : fetching.NIPR ? (
          <Box>
            <Box
              sx={{
                fontSize: 12,
                fontStyle: 'italic',
                color: '#7a7a7a',
                textAlign: 'center',
                p: 1,
              }}
            >
              Please wait while we check the National Insurance Producer
              Registry (NIPR) for your license information...
            </Box>

            <LinearProgress color="info" />
          </Box>
        ) : fetching.Authorize ? (
          <LinearProgress />
        ) : mutation.isLoading ? (
          <Box>
            <Box
              sx={{
                fontSize: 12,
                fontStyle: 'italic',
                color: '#7a7a7a',
                textAlign: 'center',
                p: 1,
              }}
            >
              {isInvite
                ? 'Your request to contract is being created...'
                : 'Retrieving the document from Adobe Acrobat Sign...'}
            </Box>

            <LinearProgress color="info" />
          </Box>
        ) : (
          <Button
            disabled={mutation.isLoading}
            fullWidth
            size="large"
            color="info"
            variant="contained"
            endIcon={isInvite ? <SendIcon /> : <ForwardIcon />}
            onClick={() => mutation.mutate()}
          >
            {isInvite
              ? 'Request invite to contract'
              : 'View and Complete Contract'}
          </Button>
        )}
      </Box>
    </Box>
  );
}
