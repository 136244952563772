import CloseIcon from '@mui/icons-material/Close';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { formatDistanceToNowStrict } from 'date-fns';
import React from 'react';
import { SelectAgent } from '../../../components/select-agent';
import { AgentSelect, FastractPendingData } from '../../../types';
import { captureError } from '../../../utils/capture-error';

export function AgentApproval(props: {
  AgtNo: string | undefined;
  approval: FastractPendingData | undefined;
  onClose: () => void;
  isContAuth: boolean;
}) {
  // Props
  const { approval } = props;
  // State
  const [Level, setLevel] = React.useState('55');
  const [agent, setAgent] = React.useState<AgentSelect | null>(null);
  const [defaultUpline, setDefaultUpline] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (approval) {
      setLevel('55');
      setAgent(null);
      setDefaultUpline(approval.Recruiter);
    }
  }, [approval]);

  // Query - Agent Levels
  const pathLevels = '/agent/fastract/levels';
  const queryLevels = useQuery({
    enabled: approval !== undefined,
    queryKey: [pathLevels],
    queryFn: async () => {
      const response: {
        data: string[];
      } = await API.post('ContractingAPI', pathLevels, {
        body: {},
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const queryClient = useQueryClient();
  // Mutation - Approve Fastract Agent
  const mutation = useMutation({
    mutationFn: async () => {
      const Upline = defaultUpline || agent?.AgtNo;
      await API.post('ContractingAPI', '/agent/fastract/approve', {
        body: {
          Recruit: approval?.AgtNo,
          Manager: props.AgtNo,
          Upline,
          Level,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/fastract/pending', { AgtNo: props.AgtNo }],
      });

      props.onClose();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleClose = () => {
    props.onClose();
  };

  let OrigActivationDate = approval?.OrigActivationDate;
  if (
    OrigActivationDate &&
    OrigActivationDate.includes(' ') &&
    !OrigActivationDate.includes('T')
  ) {
    // Convert "2023-06-12 15:47:42.3483176" to "2023-06-12T15:47:42.3483176"
    OrigActivationDate = OrigActivationDate.replace(' ', 'T');
  }

  const submitDisabled = (agent === null && defaultUpline === null) || !Level;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={approval !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton
            disabled={mutation.isLoading}
            sx={{ mr: 2 }}
            onClick={handleClose}
          >
            {mutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          {approval !== undefined ? (
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontSize: { xs: 16, md: 22 },
                  flex: 1,
                  fontWeight: 'normal',
                }}
              >
                Approve {approval.Fname} {approval.Lname}
              </Typography>

              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                  fontSize: 14,
                  color: '#b26500',
                }}
              >
                {approval.AgtNo}
              </Box>
            </Box>
          ) : null}
        </Toolbar>

        <Divider />

        {approval !== undefined ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack spacing={1} sx={{ p: 2 }}>
              <Box
                component="a"
                href={`mailto:${approval.Email}?subject=Agent Hierarchy&body=Hello ${approval.Fname} ${approval.Lname} (${approval.AgtNo}),`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  textDecorationLine: 'none',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: '#2196f344',
                  borderRadius: 2,
                  color: '#2196f3',
                  backgroundColor: '#2196f304',
                  transition: 'all 0.2s',
                  ':hover': {
                    color: '#1769aa',
                    borderColor: '#1769aa',
                    backgroundColor: '#1769aa08',
                  },
                }}
              >
                <ContactMailIcon fontSize="small" />

                <Box sx={{ flex: 1, px: 1 }}>{approval.Email}</Box>

                <Box sx={{ px: 1, fontSize: 12 }}>Open new message</Box>

                <OpenInNewIcon fontSize="small" />
              </Box>

              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #fafafa',
                  borderRadius: 2,
                }}
              >
                <ScheduleIcon fontSize="small" />

                {OrigActivationDate ? (
                  <Box sx={{ px: 1, fontSize: 16 }}>
                    Completed registration on{' '}
                    {new Date(OrigActivationDate).toDateString()}
                  </Box>
                ) : null}

                {OrigActivationDate ? (
                  <Box
                    sx={{ fontSize: 12, fontStyle: 'italic', color: '#9b9b9b' }}
                  >
                    {formatDistanceToNowStrict(new Date(OrigActivationDate), {
                      addSuffix: true,
                    })}
                  </Box>
                ) : null}
              </Box>

              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #fafafa',
                  borderRadius: 2,
                }}
              >
                <PersonIcon fontSize="small" />

                <Box sx={{ px: 1, fontSize: 16 }}>Recruited by</Box>

                <Box
                  sx={{ fontSize: 18, fontWeight: 'bold', color: '#2196f3' }}
                >
                  {approval.RecFname} {approval.RecLname}
                </Box>

                <Box
                  sx={{
                    pl: 1,
                    fontFamily: 'Roboto Mono',
                    fontWeight: 'bold',
                    fontSize: 14,
                    color: '#b26500',
                  }}
                >
                  {approval.Recruiter}
                </Box>
              </Box>

              {props.isContAuth ? (
                <Box
                  sx={{ p: 2, border: '2px solid #f1f1f1', borderRadius: 2 }}
                >
                  <Stack spacing={2}>
                    <Box>
                      Select an upline and level for {approval.Fname}{' '}
                      {approval.Lname}
                    </Box>

                    {defaultUpline ? (
                      <Stack spacing={1} direction="row" alignItems="center">
                        <Tooltip title="New search" placement="bottom" arrow>
                          <ButtonBase
                            sx={{
                              flex: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              backgroundColor: '#ffffff77',
                              border: '1px solid #ccc',
                              borderRadius: 1,
                              minHeight: 40,
                              transition: 'all 0.2s',
                              ':hover': {
                                backgroundColor: '#fff',
                              },
                            }}
                            onClick={() => setDefaultUpline(null)}
                          >
                            <Box
                              sx={{
                                px: 1.2,
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PersonIcon
                                fontSize="small"
                                sx={{ color: '#7a7a7a' }}
                              />
                            </Box>
                            <Typography
                              noWrap
                              sx={{ width: 175, textAlign: 'left' }}
                            >
                              {approval.RecFname} {approval.RecLname}
                            </Typography>
                          </ButtonBase>
                        </Tooltip>

                        <Tooltip title="Clear" placement="bottom" arrow>
                          <IconButton
                            sx={{
                              ml: 0.6,
                              backgroundColor: '#fff',
                              border: '1px solid #c1c1c1',
                              borderRadius: 2,
                            }}
                            onClick={() => setDefaultUpline(null)}
                          >
                            <PersonOffIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    ) : (
                      <SelectAgent
                        autoFocus
                        onSelectAgent={(newAgent) => setAgent(newAgent)}
                      />
                    )}

                    <FormControl fullWidth size="small">
                      <InputLabel id="approval-level-select-label">
                        Level
                      </InputLabel>
                      <Select
                        labelId="approval-level-select-label"
                        id="approval-level-select"
                        value={Level}
                        label="Level"
                        onChange={(event) => setLevel(event.target.value)}
                      >
                        {queryLevels.data?.map((level) => {
                          return (
                            <MenuItem key={level} value={level}>
                              {level}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {mutation.isError ? (
                      <Alert
                        onClose={() => mutation.reset()}
                        severity="error"
                        sx={{ width: '100%' }}
                      >
                        <strong style={{ marginRight: 10 }}>
                          An error has occurred!
                        </strong>
                        <small>Please refresh the page and try again.</small>
                      </Alert>
                    ) : null}

                    <Button
                      disabled={
                        mutation.isLoading || mutation.isError || submitDisabled
                      }
                      fullWidth
                      variant="contained"
                      disableElevation
                      startIcon={<GroupAddIcon />}
                      onClick={() => mutation.mutate()}
                    >
                      {mutation.isLoading
                        ? 'Setting...'
                        : 'Set Upline and Level'}
                    </Button>
                  </Stack>
                </Box>
              ) : null}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
