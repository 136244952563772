import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { WalletAccountData, WalletData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { testAccount, testRouting } from '../utils';

export function BillingAccountManage(props: {
  data: WalletAccountData | undefined;
  queryKey: QueryFilters['queryKey'];
  HasAgreed: boolean;
  onClose: () => void;
}) {
  // State
  const [deleting, setDeleting] = React.useState(false);
  const [data, setData] = React.useState({
    Nickname: '',
    Routing: '',
    Account: '',
    AccountType: 'Checking',
    ForComms: false,
    ForLeadBill: false,
    IsLeadBillPrimary: false,
  });
  const [errors, setErrors] = React.useState({
    Routing: false,
    Account: false,
  });
  // Hooks
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (props.data !== undefined) {
      setData({
        Nickname: props.data.Nickname || '',
        Routing: props.data.Routing || '',
        Account: props.data.Account || '',
        AccountType: props.data.AccountType || 'Checking',
        ForComms: props.data.ForComms || false,
        ForLeadBill: props.data.ForLeadBill || false,
        IsLeadBillPrimary: props.data.IsLeadBillPrimary || false,
      });
      setErrors({ Routing: false, Account: false });
      setDeleting(false);
    }
  }, [props.data]);

  // Mutation - Update Account
  const mutationUpdate = useMutation({
    mutationFn: async () => {
      const response: {
        data: WalletData;
      } = await API.post('ContractingAPI', '/billing/bank/upsert', {
        body: { ...data, AgtACHId: props.data?.AgtACHId },
      });

      // Update cache with response
      if (props.queryKey) {
        queryClient.setQueryData(props.queryKey, response.data);
      }
    },
    onError: (error) => captureError({ data: { error }, removeData: true }),
  });

  // Mutation - Delete Account
  const mutationDelete = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/billing/bank/delete', {
        body: { AgtACHId: props.data?.AgtACHId },
      });
    },
    onSuccess: async () => {
      // Refetch Coupons
      await queryClient.invalidateQueries({ queryKey: props.queryKey });
      // Close Drawer
      handleClose();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleClose = async () => {
    if (!mutationUpdate.isLoading && !mutationDelete.isLoading) {
      props.onClose();
      // Reset
      mutationUpdate.reset();
      mutationDelete.reset();
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const isLoading = mutationUpdate.isLoading || mutationDelete.isLoading;

  const submitDisabled =
    !data.Nickname ||
    !data.Routing ||
    !testRouting(data.Routing) ||
    !data.Account ||
    !testAccount(data.Account);

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.data !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '60vw', lg: '50vw', xl: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            {isLoading ? <CircularProgress size={24} /> : <CloseIcon />}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Edit Account
            </Typography>

            <Box sx={{ flex: 1 }} />

            <Tooltip title="Delete Coupon" placement="left" arrow>
              <IconButton size="small" onClick={() => setDeleting(true)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>

        <Divider />

        {mutationDelete.isError ? (
          <Alert severity="error" onClose={() => mutationDelete.reset()}>
            <AlertTitle>Error</AlertTitle>
            {
              // @ts-ignore
              mutationDelete?.error?.response?.data?.error ||
                'Please refresh the page and try again.'
            }
          </Alert>
        ) : null}

        {deleting ? (
          <Alert
            severity="warning"
            action={
              <Stack spacing={1} direction="row" alignItems="center">
                <Button
                  disabled={isLoading}
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={() => setDeleting(false)}
                >
                  Cancel
                </Button>

                <Button
                  disabled={isLoading || mutationDelete.isError}
                  size="small"
                  color="error"
                  variant="contained"
                  disableElevation
                  onClick={() => mutationDelete.mutate()}
                >
                  {mutationDelete.isLoading ? 'Deleting...' : 'Delete Account'}
                </Button>
              </Stack>
            }
          >
            <AlertTitle>Confirm Delete</AlertTitle>
            Are you sure you want to delete this Account?
          </Alert>
        ) : null}

        <Box
          sx={{
            minHeight: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
          }}
        >
          <Box sx={{ p: 2 }}>
            <Stack spacing={2}>
              <TextField
                id="account-manage-Nickname"
                required
                fullWidth
                label="Nickname"
                name="Nickname"
                value={data.Nickname}
                onChange={handleChange}
                placeholder="My Bank Account"
              />

              <ToggleButtonGroup
                fullWidth
                color="info"
                exclusive
                value={data.AccountType}
                onChange={(event, newValue) => {
                  setData((currentState) => ({
                    ...currentState,
                    AccountType: newValue || 'Checking',
                  }));
                }}
              >
                {['Checking', 'Savings'].map((AccountType) => {
                  return (
                    <ToggleButton key={AccountType} value={AccountType}>
                      {AccountType}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>

              <TextField
                id="account-manage-Routing"
                required
                fullWidth
                label="Routing"
                name="Routing"
                value={data.Routing}
                onChange={(event) => {
                  handleChange(event);
                  // Potentially reset error if user is updating a previous error
                  if (errors.Routing) {
                    setErrors((currentState) => ({
                      ...currentState,
                      Routing: !testRouting(event.target.value),
                    }));
                  }
                }}
                placeholder="000000000"
                inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                error={errors.Routing}
                onBlur={() => {
                  setErrors((currentState) => ({
                    ...currentState,
                    Routing: !testRouting(data.Routing),
                  }));
                }}
              />

              <TextField
                id="account-manage-Account"
                required
                fullWidth
                label="Account"
                name="Account"
                value={data.Account}
                onChange={(event) => {
                  handleChange(event);
                  // Potentially reset error if user is updating a previous error
                  if (errors.Account) {
                    setErrors((currentState) => ({
                      ...currentState,
                      Account: !testAccount(event.target.value),
                    }));
                  }
                }}
                placeholder="000000000"
                inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                error={errors.Account}
                onBlur={() => {
                  setErrors((currentState) => ({
                    ...currentState,
                    Account: !testAccount(data.Account),
                  }));
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.ForComms}
                    onChange={(event, checked) => {
                      setData((currentState) => ({
                        ...currentState,
                        ForComms: checked,
                      }));
                    }}
                  />
                }
                label="Deposit Commissions to this Account"
              />

              {props.HasAgreed ? null : (
                <Alert severity="warning">
                  This Account cannot be used for Lead Billing until the
                  Agreement has been accepted.
                </Alert>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!props.HasAgreed}
                    checked={data.ForLeadBill}
                    onChange={(event, checked) => {
                      setData((currentState) => ({
                        ...currentState,
                        ForLeadBill: checked,
                        IsLeadBillPrimary: checked
                          ? currentState.IsLeadBillPrimary
                          : false,
                      }));
                    }}
                  />
                }
                label="This Account should be used for Lead Billing"
              />

              <FormControlLabel
                disabled={!props.HasAgreed || !data.ForLeadBill}
                control={
                  <Checkbox
                    checked={data.IsLeadBillPrimary}
                    onChange={(event, checked) => {
                      setData((currentState) => ({
                        ...currentState,
                        IsLeadBillPrimary: checked,
                      }));
                    }}
                  />
                }
                label="Use this Account for Lead Billing rather than using a Credit Card"
              />
            </Stack>
          </Box>
        </Box>

        <Divider />

        {mutationUpdate.isSuccess ? (
          <Alert severity="success" onClose={handleClose}>
            <AlertTitle>Success!</AlertTitle>
            Your Account has been updated!
          </Alert>
        ) : null}

        {mutationUpdate.isError ? (
          <Alert severity="error" onClose={() => mutationUpdate.reset()}>
            <AlertTitle>Error</AlertTitle>
            {
              // @ts-ignore
              mutationUpdate?.error?.response?.data?.error ||
                'Please refresh the page and try again.'
            }
          </Alert>
        ) : null}

        <Button
          disabled={
            isLoading ||
            mutationUpdate.isError ||
            mutationUpdate.isSuccess ||
            submitDisabled
          }
          fullWidth
          size="large"
          color="info"
          variant="contained"
          disableElevation
          sx={{ borderRadius: 0, minHeight: 64 }}
          onClick={() => mutationUpdate.mutate()}
        >
          {mutationUpdate.isLoading ? 'Updating...' : 'Update Account'}
        </Button>
      </Box>
    </Drawer>
  );
}
