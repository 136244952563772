import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import blue from '@mui/material/colors/blue';
import { styled } from '@mui/material/styles';
import { useWindowWidth } from '@react-hook/window-size';
import React from 'react';
import Crop from 'react-easy-crop';
import { AgentProfileData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formDataRequest } from '../../utils/form-data-request';
import { getCroppedImg } from './create-crop-file';

// Custom Slider
const PrettoSlider = styled(Slider)({
  root: {
    color: blue[600],
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
});

export function CropPhoto(props: {
  admin?: boolean;
  agent?: AgentProfileData['AgtNo'] | null;
  newPhoto: any;
  user: any;
  setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  saved: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // Props
  const { admin, agent, newPhoto, user, setUploadSuccess, saved } = props;
  // Hooks
  const width = useWindowWidth();
  // State
  const isMobile = width < 550;
  const [loading, setLoading] = React.useState(false);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [rotation, setRotation] = React.useState(0);
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (newPhoto !== '') {
      setOpen(true);
      setCrop({ x: 0, y: 0 });
    }
  }, [newPhoto]);

  const onCropComplete = React.useCallback(
    (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [],
  );

  const savePhoto = async () => {
    try {
      setLoading(true);
      const croppedImage: any = await getCroppedImg(
        newPhoto,
        croppedAreaPixels,
        rotation,
      );
      const formData = new FormData();
      formData.append('Agentno', admin ? agent : user.username);
      formData.append('UploadedPhoto', croppedImage);

      const response = await formDataRequest({
        path: admin
          ? '/admin/agent-admin/write/profile/photo/upsert'
          : '/profile/photo/upsert',
        formData,
        baseURL: process.env.REACT_APP_DASHBOARD_API_URL,
      });
      if (response.status === 200) {
        setUploadSuccess(true);
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setLoading(false);
      setOpen(!open);
      saved((currentState) => !currentState);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(!open);
      }}
      fullScreen
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {loading && <LinearProgress color="secondary" style={{ top: 76 }} />}
        <div
          style={{
            position: isMobile ? 'absolute' : undefined,
            boxSizing: 'border-box',
            bottom: 0,
            left: '0%',
            width: '100%',
            padding: '0 50px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'space-between',
          }}
        >
          {!isMobile && (
            <Typography variant={isMobile ? 'h5' : 'h4'}>
              {loading ? 'Saving...' : 'Crop Image'}
            </Typography>
          )}
          <div>
            <Button
              variant="contained"
              size={isMobile ? 'small' : 'medium'}
              disabled={loading}
              style={{ margin: 10 }}
              onClick={() => {
                setOpen(!open);
                saved((currentState) => !currentState);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size={isMobile ? 'small' : 'medium'}
              color="info"
              disabled={loading}
              style={{ margin: 10 }}
              onClick={savePhoto}
            >
              Save
            </Button>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 80,
            left: 0,
            right: 0,
            bottom: 80,
          }}
        >
          <Crop
            image={newPhoto}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={4 / 4}
            restrictPosition={false}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
            cropShape="round"
            objectFit="contain"
          />
        </div>
        <div
          style={{
            boxSizing: 'border-box',
            position: isMobile ? undefined : 'absolute',
            bottom: 0,
            left: '0%',
            width: '100%',
            padding: '0 50px',
            height: '80px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PrettoSlider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(Number(zoom))}
            style={{ width: isMobile ? '90%' : '80%' }}
          />
        </div>
      </div>
    </Dialog>
  );
}
