import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { DocumentAgentData } from '../../types';

const DocIframe = React.memo(function DocIframe(props: { src: string }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title="Contract for Carrier"
        src={props.src}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

export function ContractAdobe(props: { doc: DocumentAgentData }) {
  // Props
  const {
    doc: { SignerUrl },
  } = props;

  return (
    <Box
      sx={{
        minHeight: 0,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {SignerUrl ? <DocIframe src={SignerUrl} /> : null}
    </Box>
  );
}
