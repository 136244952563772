// Using JavaScript's default toFixed function will round decimals
// (1.225).toFixed(2) = 1.23
// (-1.225).toFixed(2) = -1.23
// This function will remove decimals after 2 places
// toFixed({ value: 1.225 }) = 1.22
// toFixed({ value: -1.225 }) = -1.22
export function toFixed(params: { value: number }) {
  const decimals = 2;
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (decimals || -1) + '})?');
  const value = params.value.toString().match(re);
  if (value !== null) {
    return Number.parseFloat(value[0]);
  } else {
    return params.value;
  }
}

export function toCurrency(params: {
  value: number | null;
  displayAllDigits?: boolean;
}) {
  if (params.value !== null) {
    if (params.displayAllDigits) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 4,
        maximumFractionDigits: 8,
      });
      return formatter.format(params.value);
    } else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      const fixedValue = toFixed({ value: params.value });
      return formatter.format(fixedValue);
    }
  } else {
    return null;
  }
}
