import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, {
  BaseTextFieldProps,
  StandardTextFieldProps,
} from '@mui/material/TextField';

export function searchBy<T>(
  collection: Array<T>,
  searchString: string
): Array<T> {
  if (searchString.trim() !== '') {
    return collection.filter((i) => {
      let valueString = '';

      // Combines all values of an object into a single string
      // @ts-ignore
      Object.values(i).forEach((value) => {
        if (value !== null) {
          valueString = valueString + ' ' + value?.toString();
        }
      });

      return valueString.toLowerCase().includes(searchString.toLowerCase());
    });
  } else {
    return collection;
  }
}

export function SearchField(props: {
  id: BaseTextFieldProps['id'];
  value: BaseTextFieldProps['value'];
  onChange: StandardTextFieldProps['onChange'];
  fullWidth?: BaseTextFieldProps['fullWidth'];
  placeholder?: BaseTextFieldProps['placeholder'];
  label?: BaseTextFieldProps['label'];
  size?: BaseTextFieldProps['size'];
}) {
  return (
    <TextField
      id={props.id}
      variant="outlined"
      size={props.size || 'small'}
      label={props.label}
      placeholder={props.placeholder}
      type="search"
      fullWidth={props.fullWidth}
      value={props.value}
      onChange={props.onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
