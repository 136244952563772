import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { AuthContext } from '../../contexts/auth-context';
import { AgentNPNData, AgentWrtNoData, CarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { WrtNoCarrier } from './wrt-no-carrier';
import { WrtNPNItem } from './wrt-npn-item';

export function WritingNumbers() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [searchString, setSearchString] = React.useState('');

  // Query - Carriers
  const pathCarriers = '/carrier';
  const queryCarriers = useQuery({
    queryKey: [pathCarriers],
    queryFn: async () => {
      const response: {
        data: CarrierData[];
      } = await API.post('ContractingAPI', pathCarriers, {
        body: {},
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();

  // Query - Agent NPN
  const pathNPN = '/agent/info';
  const queryNPN = useQuery({
    queryKey: [pathNPN, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: AgentNPNData;
        } = await API.post('ContractingAPI', pathNPN, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Agent Writing Numbers
  const pathWrtNos = '/agent/carrier-info';
  const queryWrtNos = useQuery({
    queryKey: [pathWrtNos, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: AgentWrtNoData[];
        } = await API.post('ContractingAPI', pathWrtNos, {
          body: { AgtNo },
        });

        if (response.data) {
          return response.data;
        }
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Ordering
  const orderedItems = orderBy(queryCarriers.data, [
    (i) => i.FullName.toLowerCase(),
  ]);
  // Searching
  const items = searchBy(orderedItems, searchString);

  const isLoading =
    queryCarriers.isLoading || queryNPN.isLoading || queryWrtNos.isLoading;
  const isError =
    queryCarriers.isError || queryNPN.isError || queryWrtNos.isError;

  return (
    <RouteContainer
      routeTitle="My Carrier Writing #'s"
      withContainer
      loading={isLoading}
      hasError={isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          <WrtNPNItem
            AgtNo={AgtNo}
            carrier={{
              CarrierID: 0,
              Carrier: 'NPN',
              FullName: 'National Producer Number',
              CommStatus: null,
              ProcComs: null,
              ContrStatus: null,
              IMOUpline: null,
              FEIN: null,
              Logo: null,
              AgtSpec: null,
              Notes: null,
              IMOWrtNo: null,
              MaxWrtCarNo: 1,
            }}
            number={queryNPN.data || undefined}
          />

          <Divider />

          <Alert variant="outlined" severity="info">
            Click on a Carrier to add your Writing Number!
          </Alert>

          <SearchField
            id="carriers-search"
            fullWidth
            size="medium"
            placeholder="Search carriers"
            value={searchString}
            onChange={(event) => setSearchString(event.target.value)}
          />

          <Box>
            <Grid container spacing={2}>
              {items.map((item) => {
                const numbers =
                  queryWrtNos.data?.filter(
                    (i) =>
                      i.CarrierID === item.CarrierID &&
                      i.WrtCarNo !== item.Carrier + i.AgtNo.slice(-6)
                  ) || [];

                if (item.AgtSpec === 1 && numbers.length === 0) {
                  return null;
                } else {
                  return (
                    <Grid key={item.CarrierID} item xs={12} md={6} lg={4}>
                      <WrtNoCarrier
                        AgtNo={AgtNo}
                        carrier={item}
                        numbers={numbers}
                        isAdmin={impersonatedAgent !== null}
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
        </Stack>
      </Box>
    </RouteContainer>
  );
}
