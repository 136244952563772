import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../../../contexts/auth-context';
import { captureError } from '../../../utils/capture-error';
import { isAgt } from '../../../utils/is-agent';
import { testAccount, testRouting } from '../utils';

export function BillingAccountAdd(props: {
  queryKey: QueryFilters['queryKey'];
  HasAgreed: boolean;
}) {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    Nickname: '',
    Routing: '',
    Account: '',
    AccountType: 'Checking',
    ForComms: false,
    ForLeadBill: false,
    IsLeadBillPrimary: false,
  });
  const [errors, setErrors] = React.useState({
    Routing: false,
    Account: false,
  });
  // Hooks
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (open) {
      setData({
        Nickname: '',
        Routing: '',
        Account: '',
        AccountType: 'Checking',
        ForComms: false,
        ForLeadBill: false,
        IsLeadBillPrimary: false,
      });
      setErrors({ Routing: false, Account: false });
    }
  }, [open]);

  // Mutation - Add Account
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/billing/bank/upsert', {
        body: { ...data },
      });
    },
    onSuccess: async () => {
      // Refetch data
      await queryClient.invalidateQueries({ queryKey: props.queryKey });
    },
    onError: (error) => captureError({ data: { error }, removeData: true }),
  });

  const handleClose = async () => {
    if (!mutation.isLoading) {
      setOpen(false);
      mutation.reset();
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const submitDisabled =
    !data.Nickname ||
    !data.Routing ||
    !testRouting(data.Routing) ||
    !data.Account ||
    !testAccount(data.Account);

  return (
    <React.Fragment>
      <Box
        disabled={!isAgt(user?.getUsername()) || impersonatedAgent !== null}
        component="button"
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fefefe',
          borderRadius: 2,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#03182E22',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            borderColor: '#03182E',
            backgroundColor: '#fff',
          },
          ':disabled': {
            cursor: 'inherit',
            opacity: 0.5,
            transform: 'none',
            borderColor: '#03182E22',
          },
        }}
      >
        <h2 style={{ color: '#4a4a4a', fontSize: 36 }}>
          <AddCircleOutlineIcon fontSize="large" />
          <span style={{ marginLeft: 10 }}>Add Account</span>
        </h2>
      </Box>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              Add Account
            </Typography>
          </Toolbar>

          <Divider />

          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ p: 2 }}>
              <Stack spacing={2}>
                <TextField
                  id="account-add-Nickname"
                  required
                  fullWidth
                  label="Nickname"
                  name="Nickname"
                  value={data.Nickname}
                  onChange={handleChange}
                  placeholder="My Bank Account"
                />

                <ToggleButtonGroup
                  fullWidth
                  color="info"
                  exclusive
                  value={data.AccountType}
                  onChange={(event, newValue) => {
                    setData((currentState) => ({
                      ...currentState,
                      AccountType: newValue || 'Checking',
                    }));
                  }}
                >
                  {['Checking', 'Savings'].map((AccountType) => {
                    return (
                      <ToggleButton key={AccountType} value={AccountType}>
                        {AccountType}
                      </ToggleButton>
                    );
                  })}
                </ToggleButtonGroup>

                <TextField
                  id="account-add-Routing"
                  required
                  fullWidth
                  label="Routing"
                  name="Routing"
                  value={data.Routing}
                  onChange={(event) => {
                    handleChange(event);
                    // Potentially reset error if user is updating a previous error
                    if (errors.Routing) {
                      setErrors((currentState) => ({
                        ...currentState,
                        Routing: !testRouting(event.target.value),
                      }));
                    }
                  }}
                  placeholder="000000000"
                  inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                  error={errors.Routing}
                  onBlur={() => {
                    setErrors((currentState) => ({
                      ...currentState,
                      Routing: !testRouting(data.Routing),
                    }));
                  }}
                />

                <TextField
                  id="account-add-Account"
                  required
                  fullWidth
                  label="Account"
                  name="Account"
                  value={data.Account}
                  onChange={(event) => {
                    handleChange(event);
                    // Potentially reset error if user is updating a previous error
                    if (errors.Account) {
                      setErrors((currentState) => ({
                        ...currentState,
                        Account: !testAccount(event.target.value),
                      }));
                    }
                  }}
                  placeholder="000000000"
                  inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                  error={errors.Account}
                  onBlur={() => {
                    setErrors((currentState) => ({
                      ...currentState,
                      Account: !testAccount(data.Account),
                    }));
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={data.ForComms}
                      onChange={(event, checked) => {
                        setData((currentState) => ({
                          ...currentState,
                          ForComms: checked,
                        }));
                      }}
                    />
                  }
                  label="Deposit Commissions to this Account"
                />

                {props.HasAgreed ? null : (
                  <Alert severity="warning">
                    This Account cannot be used for Lead Billing until the
                    Agreement has been accepted.
                  </Alert>
                )}

                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!props.HasAgreed}
                      checked={data.ForLeadBill}
                      onChange={(event, checked) => {
                        setData((currentState) => ({
                          ...currentState,
                          ForLeadBill: checked,
                          IsLeadBillPrimary: checked
                            ? currentState.IsLeadBillPrimary
                            : false,
                        }));
                      }}
                    />
                  }
                  label="This Account should be used for Lead Billing"
                />

                <FormControlLabel
                  disabled={!props.HasAgreed || !data.ForLeadBill}
                  control={
                    <Checkbox
                      checked={data.IsLeadBillPrimary}
                      onChange={(event, checked) => {
                        setData((currentState) => ({
                          ...currentState,
                          IsLeadBillPrimary: checked,
                        }));
                      }}
                    />
                  }
                  label="Use this Account for Lead Billing rather than using a Credit Card"
                />
              </Stack>
            </Box>
          </Box>

          <Divider />

          {mutation.isSuccess ? (
            <Alert severity="success" onClose={handleClose}>
              <AlertTitle>Success!</AlertTitle>
              Your Account has been saved!
            </Alert>
          ) : null}

          {mutation.isError ? (
            <Alert severity="error" onClose={() => mutation.reset()}>
              <AlertTitle>Error</AlertTitle>
              {
                // @ts-ignore
                mutation?.error?.response?.data?.error ||
                  'Please refresh the page and try again.'
              }
            </Alert>
          ) : null}

          <Button
            disabled={
              mutation.isLoading ||
              mutation.isError ||
              mutation.isSuccess ||
              submitDisabled
            }
            fullWidth
            size="large"
            color="info"
            variant="contained"
            disableElevation
            sx={{ borderRadius: 0, minHeight: 64 }}
            onClick={() => mutation.mutate()}
          >
            {mutation.isLoading ? 'Adding...' : 'Add Account'}
          </Button>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
