import React from 'react';
import { FileUploadData } from '../../types';

export const DocIframe = React.memo(function DocIframe(props: {
  doc: FileUploadData | undefined;
  data: any;
}) {
  return (
    <iframe
      title={props.doc?.FileDisplayName || 'Contracting Document'}
      src={window.URL.createObjectURL(
        new Blob([props.data], {
          type: props.doc?.MimeType || 'application/pdf',
        })
      )}
      style={{ flex: 1, width: '100%', border: 'none' }}
    />
  );
});
