export function DisplayOn(props: { branch: string; children: JSX.Element }) {
  if (process.env.REACT_APP_AWS_BRANCH) {
    if (process.env.REACT_APP_AWS_BRANCH === props.branch) {
      return props.children;
    } else {
      return null;
    }
  } else {
    return props.children;
  }
}

export function HideFrom(props: { branch: string; children: JSX.Element }) {
  if (process.env.REACT_APP_AWS_BRANCH) {
    if (process.env.REACT_APP_AWS_BRANCH !== props.branch) {
      return props.children;
    } else {
      return null;
    }
  } else {
    return props.children;
  }
}
