import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import React from 'react';
import { ColumnHeader, TableState } from '../types';

export function TableColumnHeaders(props: {
  tableColumns: ColumnHeader<any>[];
  sort: TableState['sort'];
  sortBy: TableState['sortBy'];
  sortHandler: Function;
  cellProps?: TableCellProps;
  color?: string;
  hideSortIcon?: boolean;
}) {
  // Props
  const { sort, sortBy, tableColumns, sortHandler, color, hideSortIcon } =
    props;

  return (
    <React.Fragment>
      {tableColumns.map((column) => {
        const align = column.align || 'center';
        const value = (column.label || column.key) as string | number;

        return (
          <TableCell
            key={column.key as string}
            sortDirection={sort}
            align={align}
            {...props.cellProps}
            style={{ color: color }}
          >
            <TableSortLabel
              active={sortBy === column.key}
              direction={sort}
              onClick={sortHandler(column.key)}
              style={{ color: color }}
              hideSortIcon={hideSortIcon}
            >
              {value}
            </TableSortLabel>
          </TableCell>
        );
      })}
    </React.Fragment>
  );
}
