import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ForwardIcon from '@mui/icons-material/Forward';
import ReceiptIcon from '@mui/icons-material/Receipt';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { DisplayValue } from '../../../components/display-value';
import { WalletAccountData } from '../../../types';

export function BillingAccount(props: {
  data: WalletAccountData;
  onClick: () => void;
  disableEditing?: boolean;
}) {
  // Props
  const { data } = props;

  return (
    <Paper
      elevation={0}
      onClick={props.onClick}
      component="button"
      disabled={props.disableEditing}
      sx={{
        width: '100%',
        textAlign: 'left',
        fontFamily: 'Roboto',
        overflow: 'hidden',
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        background:
          'linear-gradient(to bottom right, #2196f344, #fff, #00C86833)',
        borderRadius: 2,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#e1e1e1',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.01)',
          borderColor: '#2196f3',
        },
        ':hover .wallet-item-forward-icon': {
          color: '#03182Ecc',
        },
        ':disabled': {
          cursor: 'inherit',
          transform: 'none',
          borderColor: '#e1e1e1',
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Stack
          spacing={1}
          direction={{ xs: 'row', md: 'column' }}
          alignItems={{ xs: 'center', md: 'flex-start' }}
        >
          <AccountBalanceIcon fontSize="large" color="info" />

          {data.ForComms ? (
            <Tooltip
              title="Commissions are deposited to this Account"
              placement="right"
              arrow
            >
              <Chip
                label="Comms"
                color="success"
                size="small"
                variant="outlined"
                icon={<AttachMoneyIcon />}
              />
            </Tooltip>
          ) : null}

          {data.ForLeadBill ? (
            <Tooltip
              title={
                data.IsLeadBillPrimary
                  ? 'This Account is default for Lead Billing'
                  : 'This Account will be used for Lead Billing'
              }
              placement="right"
              arrow
            >
              <Chip
                label="Leads"
                color="warning"
                size="small"
                variant="outlined"
                icon={data.IsLeadBillPrimary ? <StarIcon /> : <ReceiptIcon />}
              />
            </Tooltip>
          ) : null}
        </Stack>
      </Box>

      <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12} md={6}>
            <DisplayValue label="Account Name" value={data.Nickname || null} />
          </Grid>
          <Grid item xs={12} md={6}>
            <DisplayValue
              label="Account Type"
              value={data.AccountType || null}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DisplayValue
              label="Routing Number"
              value={data.Routing || null}
              monospace
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DisplayValue
              label="Account Number"
              value={data.Account || null}
              monospace
            />
          </Grid>
        </Grid>

        {props.disableEditing ? null : (
          <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>
            <ForwardIcon
              fontSize="large"
              className="wallet-item-forward-icon"
              sx={{ transition: 'all 0.2s', color: '#03182E44' }}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
}
