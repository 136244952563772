import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { FastractPendingData } from '../../../types';

export function ManageApprovalsItem(props: {
  item: FastractPendingData;
  onClick: () => void;
}) {
  // Props
  const { item } = props;

  let OrigActivationDate = item?.OrigActivationDate;
  if (
    OrigActivationDate &&
    OrigActivationDate.includes(' ') &&
    !OrigActivationDate.includes('T')
  ) {
    // Convert "2023-06-12 15:47:42.3483176" to "2023-06-12T15:47:42.3483176"
    OrigActivationDate = OrigActivationDate.replace(' ', 'T');
  }

  return (
    <Box
      component="li"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #00000011',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':first-of-type': {
          borderTop: 'none',
        },
        ':nth-of-type(even)': {
          backgroundColor: '#fcfcfc',
        },
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
        ':hover .chevron-icon-item-hover': {
          color: '#03182E',
          mr: 0,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            padding: { xs: 1, md: 2 },
            pb: { xs: 0, md: 2 },
            color: '#b26500',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          {item.AgtNo}
        </Box>

        <Box sx={{ flex: 1, padding: 1, pl: { xs: 1, md: 0 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: {
                xs: 'flex-start',
                lg: 'center',
              },
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
            }}
          >
            <Box sx={{ fontSize: 22, mr: 1 }}>
              {item.Fname} {item.Lname}
            </Box>
            <Box sx={{ fontSize: 10 }}>{item.Email}</Box>
          </Box>
          {OrigActivationDate ? (
            <Box sx={{ fontSize: 12, color: '#7a7a7a' }}>
              Completed registration on{' '}
              {new Date(OrigActivationDate).toDateString()}
            </Box>
          ) : null}
        </Box>

        {item.Recruiter ? (
          <Box sx={{ mb: { xs: 1, md: 0 } }}>
            <Box
              sx={{
                mx: 1,
                mb: { xs: 1, md: 0 },
                py: 0.6,
                px: 1,
                backgroundColor: '#f3f3f3',
                borderRadius: 2,
                border: '1px solid #e1e1e1',
              }}
            >
              <Tooltip title="Recruited by" placement="left" arrow>
                <Box
                  sx={{ fontSize: 14, fontWeight: 'bold', color: '#2196f3' }}
                >
                  {item.RecFname} {item.RecLname}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        ) : null}
      </Box>

      <Box
        sx={{
          minWidth: 38,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChevronRightIcon
          className="chevron-icon-item-hover"
          sx={{
            color: '#03182E44',
            transition: 'all 0.2s',
            mr: 0.8,
          }}
        />
      </Box>
    </Box>
  );
}
