import CampaignIcon from '@mui/icons-material/Campaign';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

export function Greeting() {
  return (
    <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
      <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
        <Box sx={{ transform: 'rotate(-12deg)', pr: 1 }}>
          <CampaignIcon fontSize="large" sx={{ color: '#b26500' }} />
        </Box>

        <Box sx={{ flex: 1, fontWeight: 'bold', fontSize: 18 }}>
          Welcome to The Alliance Contracting Website!
        </Box>
      </Box>

      <Divider />

      <Stack spacing={2} sx={{ p: 2 }}>
        <Box>
          We are thrilled to have YOU as a new member on The Alliance TEAM!{' '}
          <a
            href="https://arc.naaleads.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://arc.naaleads.com/
          </a>{' '}
          is our main website where you'll access important items like your
          Commission Report, CRM, Leads, Dashboards, E-Apps, Carrier Info,
          Resources, Connections, and much much more. Your NAA# and password
          that you created will work on ALL Alliance sites.
        </Box>
        <Box>
          Agent Profile - Any info that you type in that section will be
          automatically added to Carrier Contracts you fill out.
        </Box>
        <Box>
          Agent Agreement - You need to agree for us to pay you every Mon, Wed,
          & Fri. All of your Personal Production, Overrides, GLO Bonuses,
          Renewals, & Other $ will show up under your Alliance Commission
          Report.
        </Box>
        <Box>
          Once your Getting Started section is complete, please talk to your
          Upline to figure out your next step. It will probably be learning the
          phone script, sales presentation, products, getting some leads, and
          making some calls. Excited to have you on our Team. Let's GO!
        </Box>
      </Stack>
    </Paper>
  );
}
