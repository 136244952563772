import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { QueryFilters } from '@tanstack/react-query';
import React from 'react';
import { WalletAccountData, WalletData } from '../../../types';
import { BillingAccount } from './billing-account';
import { BillingAccountAdd } from './billing-account-add';
import { BillingAccountManage } from './billing-account-manage';

export function Billing(props: {
  data: WalletData['billing'] | undefined;
  queryKey: QueryFilters['queryKey'];
  HasAgreed: boolean;
  disableEditing: boolean;
}) {
  // State
  const [selected, setSelected] =
    React.useState<WalletAccountData['AgtACHId']>();

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Bank Accounts
            </Typography>
          </Box>

          <BillingAccountAdd
            queryKey={props.queryKey}
            HasAgreed={props.HasAgreed}
          />

          {props.data?.map((account) => {
            return (
              <BillingAccount
                key={account.AgtACHId}
                data={account}
                onClick={() => setSelected(account.AgtACHId)}
                disableEditing={props.disableEditing}
              />
            );
          })}
        </Stack>
      </Paper>

      <BillingAccountManage
        data={props.data?.find((i) => i.AgtACHId === selected)}
        queryKey={props.queryKey}
        HasAgreed={props.HasAgreed}
        onClose={() => setSelected(undefined)}
      />
    </React.Fragment>
  );
}
