import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteError } from '../../components/route-container/route-error';
import { FileUploadData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { DocDelete } from './doc-delete';
import { DocIframe } from './doc-iframe';

export function DocView(props: {
  AgtNo: string | undefined;
  doc: FileUploadData | undefined;
  onClose: () => void;
}) {
  // Props
  const { AgtNo, doc } = props;
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [deleting, setDeleting] = React.useState(false);

  React.useEffect(() => {
    if (props.doc) {
      setAnchorEl(null);
      setDeleting(false);
    }
  }, [props.doc]);

  // Query - Documents File data
  const path = '/agent/file';
  const FileUploadID = props.doc?.FileUploadID;
  const query = useQuery({
    enabled: props.doc !== undefined,
    queryKey: [path, { AgtNo, FileUploadID }],
    queryFn: async () => {
      const response = await API.post('ContractingAPI', path, {
        responseType: 'arraybuffer',
        body: { AgtNo, FileUploadID },
      });

      if (response) {
        return response;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const open = Boolean(anchorEl);
  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClose = async () => {
    if (!loading) {
      props.onClose();
    }
  };

  const handleDownload = () => {
    const url = window.URL.createObjectURL(
      new Blob([query.data], { type: doc?.MimeType || '' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      doc?.FileDisplayName || doc?.Filename || doc?.StreamID || 'document'
    );
    document.body.appendChild(link);
    link.click();
  };

  let displayFile = <Box />;
  if (doc && doc.MimeType) {
    if (doc.MimeType.includes('pdf')) {
      displayFile = <DocIframe doc={props.doc} data={query.data} />;
    } else if (doc.MimeType.includes('image')) {
      displayFile = (
        <Box
          sx={{
            flex: 1,
            minHeight: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#9b9b9b',
            overflow: 'auto',
          }}
        >
          <Box
            component="img"
            src={window.URL.createObjectURL(
              new Blob([query.data], { type: doc.MimeType })
            )}
            sx={{ width: { xs: '80%', md: '60%' } }}
          />
        </Box>
      );
    } else {
      displayFile = (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            endIcon={<DownloadIcon />}
            onClick={handleDownload}
          >
            Download file
          </Button>
        </Box>
      );
    }
  }

  const loading = query.isLoading || query.isFetching;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.doc !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '85vw', lg: '75vw', xl: '65vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton disabled={loading} sx={{ mr: 2 }} onClick={handleClose}>
            {loading ? <CircularProgress size={24} /> : <CloseIcon />}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                flex: 1,
                fontSize: { xs: 16, md: 18 },
                fontWeight: 'normal',
              }}
            >
              {props.doc?.FileDisplayName}
            </Typography>

            {deleting ? (
              <Tooltip title="Cancel" placement="left" arrow>
                <IconButton size="small" onClick={() => setDeleting(false)}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Manage document" placement="left" arrow>
                <IconButton
                  size="small"
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickMenu}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{ 'aria-labelledby': 'basic-button' }}
            >
              <MenuItem
                onClick={() => {
                  handleDownload();
                  handleCloseMenu();
                }}
              >
                Download
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDeleting(true);
                  handleCloseMenu();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>

        <Divider />

        {deleting ? (
          <DocDelete
            AgtNo={props.AgtNo}
            doc={props.doc}
            onSuccess={() => props.onClose()}
          />
        ) : null}

        {loading ? (
          <LinearProgress />
        ) : query.isError ? (
          <RouteError />
        ) : (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {displayFile}
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
