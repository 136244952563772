import * as React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

/* react-imask's documentation recommends using onAccept rather than onChange
but to avoid console warnings an empty onChange handle function needed to be added to the input elements below*/
export const SSNMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function SSNMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000-00-0000"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const DOBMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function DOBMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00/00"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
        placeholderChar="MM/DD"
      />
    );
  }
);

export const PhoneMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function PhoneMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(000) 000-0000"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const ZipMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function ZipMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00000-0000"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const RecruiterMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function RecruiterMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000000"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const TaxIDMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TaxIDMask(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="00-0000000"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);
