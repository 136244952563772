import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FileUploadType } from '../../types';
import { captureError } from '../../utils/capture-error';
import { formDataRequest } from '../../utils/form-data-request';

export function DocUpload(props: { AgtNo: string | undefined }) {
  // State
  const [open, setOpen] = React.useState(false);
  const [DocFile, setDocFile] = React.useState<File>();
  const [FileDisplayName, setFileDisplayName] = React.useState('');
  const [FileType, setFileType] = React.useState('');

  React.useEffect(() => {
    if (open) {
      setDocFile(undefined);
      setFileDisplayName('');
      setFileType('');
    }
  }, [open]);

  const queryClient = useQueryClient();
  // Mutation - Upload file to Documents
  const mutation = useMutation({
    mutationFn: async () => {
      const formData = new FormData();
      formData.append('FileUploadType', FileType);
      formData.append('FileDisplayName', FileDisplayName);
      if (props.AgtNo) {
        formData.append('AgtNo', props.AgtNo);
      }
      if (DocFile) {
        formData.append('File', DocFile);
      }

      // Add File
      await formDataRequest({ path: '/agent/file/upload', formData });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/file/list', { AgtNo: props.AgtNo }],
      });

      setOpen(false);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles.length) {
        const newFile = acceptedFiles[0];

        setFileDisplayName(newFile.name);
        setDocFile(newFile);
      }
    },
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': [],
      'application/msword': ['.doc', '.docx'],
    },
  });

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
    }
  };

  const submitDisabled = !FileDisplayName || !FileType;

  return (
    <React.Fragment>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<UploadFileIcon />}
        onClick={() => setOpen(true)}
      >
        Upload new document
      </Button>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Box>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontSize: 22, fontWeight: 'normal' }}
              >
                Upload Document
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          {DocFile !== undefined ? (
            <Box>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: '#fafafa',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <UploadFileIcon sx={{ color: '#b26500' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ pl: 1, fontSize: 18, fontWeight: 'bold' }}>
                      Upload
                    </Box>
                    <Box
                      sx={{ pl: 1, fontFamily: 'Roboto Mono', fontSize: 14 }}
                    >
                      {DocFile.name}
                    </Box>
                    <Box sx={{ pl: 1, fontSize: 18, fontWeight: 'bold' }}>
                      ?
                    </Box>
                  </Box>
                </Box>

                <TextField
                  id="doc-upload-name"
                  required
                  fullWidth
                  label="File Name"
                  value={FileDisplayName}
                  onChange={(event) => setFileDisplayName(event.target.value)}
                />

                <FormControl required fullWidth>
                  <InputLabel id="upload-type-select-label">
                    File Category
                  </InputLabel>
                  <Select
                    labelId="upload-type-select-label"
                    id="upload-type-select"
                    value={FileType}
                    label="File Category"
                    onChange={(event) => setFileType(event.target.value)}
                  >
                    {Object.keys(FileUploadType).map((fType) => {
                      return (
                        <MenuItem key={fType} value={fType}>
                          {fType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {mutation.isError ? (
                  <Alert
                    onClose={() => mutation.reset()}
                    severity="error"
                    sx={{ width: '100%' }}
                  >
                    <strong style={{ marginRight: 10 }}>
                      An error has occurred!
                    </strong>
                    <small>Please refresh the page and try again.</small>
                  </Alert>
                ) : null}

                <Button
                  disabled={mutation.isLoading || submitDisabled}
                  fullWidth
                  variant="contained"
                  size="large"
                  startIcon={<UploadIcon />}
                  onClick={() => mutation.mutate()}
                >
                  {mutation.isLoading ? 'Uploading...' : 'Upload file'}
                </Button>

                <Button
                  disabled={mutation.isLoading}
                  fullWidth
                  size="small"
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  onClick={() => {
                    setDocFile(undefined);
                    setFileDisplayName('');
                  }}
                >
                  Change file
                </Button>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                px: 4,
                py: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                component="div"
                sx={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  borderWidth: 8,
                  borderStyle: 'dashed',
                  borderColor: isDragActive ? '#2196f3' : '#cacaca',
                  borderRadius: 4,
                  color: '#7a7a7a',
                  transition: 'all 0.2s',
                  ':hover': {
                    borderColor: '#a1a1a1',
                    backgroundColor: '#fafafa',
                    color: '#4a4a4a',
                  },
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      Drop the file here ...
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ textAlign: 'center' }}>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      Drag 'n' drop your file here
                    </Box>
                    <Box sx={{ p: 1, fontSize: { xs: 16, md: 22 } }}>
                      or click to select file
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
