import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import EmailIcon from '@mui/icons-material/Email';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PinIcon from '@mui/icons-material/Pin';
import SecurityIcon from '@mui/icons-material/Security';
import SendIcon from '@mui/icons-material/Send';
import SmsIcon from '@mui/icons-material/Sms';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import copy from 'copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { captureError } from '../../utils/capture-error';

type MFAOption = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'EMAIL_OTP';
const mfaOptions = ['EMAIL_OTP', 'SMS_MFA', 'SOFTWARE_TOKEN_MFA'];
const getOptionName = (option: string) => {
  switch (option) {
    case 'SMS_MFA':
      return 'SMS';
    case 'SOFTWARE_TOKEN_MFA':
      return 'TOTP';
    case 'EMAIL_OTP':
      return 'EMAIL';
    default:
      return option;
  }
};

const createOtpAuthUrl = (params: {
  issuer: string;
  account: string;
  secret: string;
}): string => {
  // Params
  const { issuer, account, secret } = params;

  const encodedIssuer = encodeURIComponent(issuer);
  const encodedAccount = encodeURIComponent(account);
  const encodedSecret = encodeURIComponent(secret);

  return `otpauth://totp/${encodedIssuer}:${encodedAccount}?secret=${encodedSecret}&issuer=${encodedIssuer}`;
};

export function MultiFactorInfo(props: { user: CognitoUser }) {
  // Props
  const { user } = props;
  // State
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<MFAOption>('SMS_MFA');
  const [UserAttributes, setUserAttributes] = React.useState<
    CognitoUserAttribute[]
  >([]);
  const [phone, setPhone] = React.useState('');
  const [verifySMSCode, setVerifySMSCode] = React.useState('');
  const [verifyTOTPCode, setVerifyTOTPCode] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  // Hooks
  const queryClient = useQueryClient();

  React.useEffect(() => {
    user.getUserAttributes((err, attributes) => {
      setUserAttributes(attributes || []);
    });
  }, [user]);

  React.useEffect(() => {
    if (open) {
      // Reset State when the drawer is opened or they click on a different MFA type
      setPhone('');
      setVerifySMSCode('');
      setVerifyTOTPCode('');
      setCopied(false);
    }
  }, [open, selected]);

  // Query - Preferred MFA
  const queryKey = ['Auth.getPreferredMFA'];
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      // Bypass Cache to always get the latest preferences for the Current User
      const currentUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const preferredMFA = await Auth.getPreferredMFA(currentUser);

      // If the users Preferred MFA is one of the available options set it as the selected tab
      if (mfaOptions.includes(preferredMFA)) {
        setSelected(preferredMFA as MFAOption);
      }

      return preferredMFA;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Get code for Authenticator App
  const queryTOTPCode = useQuery({
    enabled: selected === 'SOFTWARE_TOKEN_MFA',
    queryKey: ['Auth.setupTOTP', { user }],
    queryFn: async () => {
      const secretCode = await Auth.setupTOTP(user);
      return secretCode;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Verify and Set MFA for Authenticator App
  const mutationTOTPVerify = useMutation({
    mutationFn: async () => {
      // Verify code generated from users Authenticator App
      await Auth.verifyTotpToken(user, verifyTOTPCode);
      // Set Authenticator App as their preferred MFA
      await Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');
    },
    onSuccess: async () => {
      // Refetch preferred MFA
      await queryClient.invalidateQueries({ queryKey });
      // Reset code
      setVerifyTOTPCode('');
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Add and Send code for SMS
  const mutationSMSAdd = useMutation({
    mutationFn: async () => {
      // Add the users phone number to their Cognito account
      await Auth.updateUserAttributes(user, { phone_number: `+1${phone}` });
      // Send code to the phone number they added
      await Auth.verifyUserAttribute(user, 'phone_number');
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Verify and Set MFA for SMS
  const mutationSMSVerify = useMutation({
    mutationFn: async () => {
      // Verify code sent to the user's phone
      await Auth.verifyCurrentUserAttributeSubmit(
        'phone_number',
        verifySMSCode,
      );
      // Set SMS as their preferred MFA method
      await Auth.setPreferredMFA(user, 'SMS_MFA');
    },
    onSuccess: async () => {
      // Refetch preferred MFA
      await queryClient.invalidateQueries({ queryKey });
      // Reset phone
      setPhone('');
      setVerifySMSCode('');
      // Reset add mutation
      mutationSMSAdd.reset();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Mutation - Set Preferred MFA
  const mutationEmail = useMutation({
    mutationFn: async () => {
      // Get Token for Request
      const AccessToken = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();

      // Direct call to Cognito API for setting MFA as Email
      await fetch('https://cognito-idp.us-east-1.amazonaws.com/', {
        body: JSON.stringify({
          AccessToken,
          EmailMfaSettings: { Enabled: true, PreferredMfa: true },
        }),
        headers: {
          // Important: These extra headers must be set for AWS to accept the call
          'x-amz-target':
            'AWSCognitoIdentityProviderService.SetUserMFAPreference',
          'x-amz-user-agent': 'aws-amplify/5.0.4 js',
          'Content-Type': 'application/x-amz-json-1.1',
        },
        method: 'POST',
      });
    },
    onSuccess: async () => {
      // Refetch preferred MFA
      await queryClient.invalidateQueries({ queryKey });
    },
  });

  const isPending =
    mutationEmail.isLoading ||
    mutationSMSAdd.isLoading ||
    mutationSMSVerify.isLoading ||
    mutationTOTPVerify.isLoading;

  const handleClose = async () => {
    if (!isPending) {
      // Refetch check in require-auth
      await queryClient.invalidateQueries({
        queryKey: ['check_Auth.getPreferredMFA'],
      });

      setOpen(false);

      // Reset some state when closing the drawer
      if (mfaOptions.includes(query.data || '')) {
        setSelected(query.data as MFAOption);
      } else {
        setSelected('SMS_MFA');
      }
      setPhone('');
      setVerifySMSCode('');
      setVerifyTOTPCode('');
      setCopied(false);

      // Reset mutation functions
      mutationEmail.reset();
      mutationSMSAdd.reset();
      mutationSMSVerify.reset();
      mutationTOTPVerify.reset();
    }
  };

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ p: 1, border: '1px solid #00000022' }}>
        <Stack spacing={1} direction="row" alignItems="center">
          <SecurityIcon fontSize="large" sx={{ color: '#b26500' }} />

          <Box>
            <Box sx={{ fontSize: 28, fontWeight: 'bold', lineHeight: 1 }}>
              MFA Settings
            </Box>
            <Box sx={{ fontSize: 14, color: '#9b9b9b' }}>
              Multi-Factor Authentication
            </Box>
          </Box>

          <Box sx={{ flex: 1 }} />

          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-end', sm: 'center' }}
          >
            {query.isLoading ? (
              <CircularProgress size={32} />
            ) : query.data && mfaOptions.includes(query.data) ? (
              <Chip
                avatar={<TaskAltIcon color="inherit" />}
                label={getOptionName(query.data)}
                size="medium"
                color="success"
                variant="outlined"
              />
            ) : (
              <Chip
                avatar={<WarningAmberIcon color="inherit" />}
                label="None selected"
                size="medium"
                color="warning"
                variant="outlined"
              />
            )}

            <Button
              disabled={query.isLoading || query.isError}
              size="small"
              disableElevation
              variant="contained"
              endIcon={<ArrowForwardIcon fontSize="small" />}
              onClick={() => setOpen(true)}
            >
              Manage
            </Button>
          </Stack>
        </Stack>
      </Paper>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {isPending ? <CircularProgress size={24} /> : <CloseIcon />}
            </IconButton>

            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontSize: 22, fontWeight: 'normal' }}
              >
                Update MFA
              </Typography>
            </Box>

            {query.data && mfaOptions.includes(query.data) ? (
              <Chip
                avatar={<TaskAltIcon fontSize="small" color="inherit" />}
                label={getOptionName(query.data)}
                size="small"
                color="success"
                variant="outlined"
              />
            ) : null}
          </Toolbar>

          <Divider />

          <Box sx={{ flex: 1, overflow: 'auto', padding: 2 }}>
            <Stack spacing={1}>
              {query.data && !mfaOptions.includes(query.data) ? (
                <Box
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 12,
                    textTransform: 'uppercase',
                    textAlign: 'center',
                    color: '#4a4a4a',
                  }}
                >
                  Select from the following options
                </Box>
              ) : null}

              <ToggleButtonGroup
                fullWidth
                exclusive
                color="info"
                value={selected}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelected(newValue);

                    // Reset mutation functions
                    mutationEmail.reset();
                    mutationSMSAdd.reset();
                    mutationSMSVerify.reset();
                    mutationTOTPVerify.reset();
                  }
                }}
              >
                {mfaOptions.map((mfaOption) => {
                  return (
                    <ToggleButton key={mfaOption} value={mfaOption}>
                      {getOptionName(mfaOption)}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>

              {mfaOptions.includes(query.data || '') &&
              query.data === selected ? (
                <Alert severity="success">
                  This option is set as your preferred MFA method
                </Alert>
              ) : null}

              {selected === 'EMAIL_OTP' ? (
                <Stack spacing={1}>
                  <Paper
                    elevation={0}
                    sx={{ p: 1, border: '1px solid #00000022' }}
                  >
                    <Stack spacing={1} direction="row">
                      <HelpOutlineIcon fontSize="small" color="info" />

                      <Box sx={{ fontSize: 14, color: '#212121' }}>
                        Email-based multi-factor authentication involves sending
                        a one-time verification code to the user's registered
                        email address. The user must retrieve the code to
                        confirm their identity during login.
                      </Box>
                    </Stack>
                  </Paper>

                  <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1} direction="row">
                        <Box>
                          <Box
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Roboto Mono',
                              border: '1px solid #000',
                              borderRadius: 50,
                              height: 22,
                              width: 22,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            1
                          </Box>
                        </Box>

                        <Box sx={{ pb: 1 }}>
                          If your email address needs to be updated please
                          contact Help desk for assistance. Otherwise click the
                          button below to set Email as your preferred MFA
                          option.
                        </Box>
                      </Stack>

                      <Box
                        sx={{
                          p: 1,
                          backgroundColor: '#f1f1f1',
                          borderRadius: 2,
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-evenly"
                        >
                          <AlternateEmailIcon color="info" />

                          <Box sx={{ fontFamily: 'Roboto Mono' }}>
                            {
                              UserAttributes.find((i) => i.Name === 'email')
                                ?.Value
                            }
                          </Box>

                          {UserAttributes.find(
                            (i) => i.Name === 'email_verified',
                          )?.Value === 'true' ? (
                            <Tooltip title="Verified" placement="left" arrow>
                              <VerifiedIcon color="success" />
                            </Tooltip>
                          ) : null}
                        </Stack>
                      </Box>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1}>
                        {mutationEmail.isError ? (
                          <Alert
                            severity="error"
                            onClose={() => mutationEmail.reset()}
                          >
                            An error has occured!
                          </Alert>
                        ) : null}

                        <Button
                          disabled={
                            mutationEmail.isLoading ||
                            query.data === 'EMAIL_OTP'
                          }
                          fullWidth
                          variant="contained"
                          disableElevation
                          size="large"
                          color="info"
                          startIcon={<EmailIcon />}
                          onClick={() => mutationEmail.mutate()}
                        >
                          {mutationEmail.isLoading
                            ? 'Updating MFA...'
                            : 'Set email as MFA'}
                        </Button>

                        {mutationEmail.isSuccess ? (
                          <Alert severity="success">
                            Preference has been set!
                          </Alert>
                        ) : null}
                      </Stack>
                    </Box>
                  </Paper>
                </Stack>
              ) : null}

              {selected === 'SMS_MFA' ? (
                <Stack spacing={1}>
                  {query.data &&
                  query.data === 'SMS_MFA' &&
                  UserAttributes.find(
                    (i) => i.Name === 'phone_number_verified',
                  ) ? (
                    <Alert severity="info">
                      You will receive messages at{' '}
                      <Box component="span" sx={{ fontWeight: 'bold' }}>
                        {
                          UserAttributes.find((i) => i.Name === 'phone_number')
                            ?.Value
                        }
                      </Box>
                    </Alert>
                  ) : null}

                  <Paper
                    elevation={0}
                    sx={{ p: 1, border: '1px solid #00000022' }}
                  >
                    <Stack spacing={1} direction="row">
                      <HelpOutlineIcon fontSize="small" color="info" />

                      <Box sx={{ fontSize: 14, color: '#212121' }}>
                        SMS-based multi-factor authentication sends a one-time
                        verification code via text message to the user's
                        registered mobile phone. The user inputs this code to
                        verify their identity.
                      </Box>
                    </Stack>
                  </Paper>

                  <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1}>
                        <Stack spacing={1} direction="row">
                          <Box>
                            <Box
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Mono',
                                border: '1px solid #000',
                                borderRadius: 50,
                                height: 22,
                                width: 22,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              1
                            </Box>
                          </Box>

                          <Box sx={{ pb: 1 }}>
                            Add the phone number where you would like to receive
                            messages while signing in. A code will be sent to
                            your phone to verify that the number is correct.
                          </Box>
                        </Stack>

                        <TextField
                          id="multi-factor-info-phone"
                          fullWidth
                          type="tel"
                          autoComplete="false"
                          placeholder="0000000000"
                          label="Phone Number (+15551234567)"
                          value={phone}
                          onChange={(event) => {
                            setPhone(event.target.value.replace(/\D/g, ''));
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            style: { fontFamily: 'Roboto Mono', fontSize: 18 },
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box
                                  sx={{
                                    fontFamily: 'Roboto Mono',
                                    fontWeight: 'bold',
                                    fontSize: 20,
                                  }}
                                >
                                  +1
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />

                        {mutationSMSAdd.isError ? (
                          <Alert
                            severity="error"
                            onClose={() => mutationSMSAdd.reset()}
                          >
                            An error has occured!
                          </Alert>
                        ) : null}

                        <Button
                          disabled={
                            mutationSMSAdd.isSuccess ||
                            mutationSMSAdd.isLoading ||
                            mutationSMSAdd.isError ||
                            !phone.trim() ||
                            !isMobilePhone(`+1${phone}`, 'en-US')
                          }
                          fullWidth
                          disableElevation
                          variant="contained"
                          color="info"
                          startIcon={<SendIcon />}
                          onClick={() => mutationSMSAdd.mutate()}
                        >
                          {mutationSMSAdd.isLoading
                            ? 'Sending code...'
                            : 'Send code to your phone'}
                        </Button>

                        {mutationSMSAdd.isSuccess ? (
                          <Alert severity="success">
                            Please wait for a code to be sent to your phone
                          </Alert>
                        ) : null}
                      </Stack>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1}>
                        <Stack spacing={1} direction="row">
                          <Box>
                            <Box
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Mono',
                                border: '1px solid #000',
                                borderRadius: 50,
                                height: 22,
                                width: 22,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              2
                            </Box>
                          </Box>

                          <Box sx={{ pb: 1 }}>
                            Once you have received a text message with your code
                            add it here to verify that everything is setup
                            correctly.
                          </Box>
                        </Stack>

                        <TextField
                          disabled={!mutationSMSAdd.isSuccess}
                          id="multi-factor-info-phone-verify"
                          fullWidth
                          placeholder="000000"
                          autoComplete="false"
                          label="Code from SMS message"
                          value={verifySMSCode}
                          onChange={(event) => {
                            setVerifySMSCode(
                              event.target.value.replace(/\D/g, ''),
                            );
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                        />

                        {mutationSMSVerify.isError ? (
                          <Alert
                            severity="error"
                            onClose={() => mutationSMSVerify.reset()}
                          >
                            An error has occured!
                          </Alert>
                        ) : null}

                        <Button
                          disabled={
                            !mutationSMSAdd.isSuccess ||
                            mutationSMSVerify.isLoading ||
                            mutationSMSVerify.isError ||
                            !verifySMSCode.trim()
                          }
                          fullWidth
                          variant="contained"
                          disableElevation
                          size="large"
                          color="info"
                          startIcon={<SmsIcon />}
                          onClick={() => mutationSMSVerify.mutate()}
                        >
                          {mutationSMSVerify.isLoading
                            ? 'Updating MFA...'
                            : 'Verify Code and Set as MFA'}
                        </Button>

                        {mutationSMSVerify.isSuccess ? (
                          <Alert severity="success">
                            Preference has been set!
                          </Alert>
                        ) : null}
                      </Stack>
                    </Box>
                  </Paper>
                </Stack>
              ) : null}

              {selected === 'SOFTWARE_TOKEN_MFA' ? (
                <Stack spacing={1}>
                  <Paper
                    elevation={0}
                    sx={{ p: 1, border: '1px solid #00000022' }}
                  >
                    <Stack spacing={1} direction="row">
                      <HelpOutlineIcon fontSize="small" color="info" />

                      <Box sx={{ fontSize: 14, color: '#212121' }}>
                        Authenticator apps (e.g., Google Authenticator,
                        Microsoft Authenticator) generate time-based, one-time
                        passcodes (TOTP) on a user's smartphone. Users must
                        input the current code displayed in the app to complete
                        the authentication process.
                      </Box>
                    </Stack>
                  </Paper>

                  <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1}>
                        <Stack spacing={1} direction="row">
                          <Box>
                            <Box
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Mono',
                                border: '1px solid #000',
                                borderRadius: 50,
                                height: 22,
                                width: 22,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              1
                            </Box>
                          </Box>

                          <Box>
                            Scan the QR Code or copy and paste the code into
                            your Authenticator App. Once you have added this
                            code to your Authenticator App your App will
                            generate a TOTP code that will need to be verified
                            in the next step.
                          </Box>
                        </Stack>

                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          {queryTOTPCode.isFetching ? (
                            <Box
                              sx={{
                                height: 240,
                                width: 240,
                                backgroundColor: '#000',
                              }}
                            />
                          ) : queryTOTPCode.data ? (
                            <QRCodeSVG
                              value={createOtpAuthUrl({
                                issuer: 'TheAlliance',
                                account: user.getUsername(),
                                secret: queryTOTPCode.data,
                              })}
                              size={240}
                            />
                          ) : null}
                        </Box>

                        <Stack spacing={1} direction="row" alignItems="center">
                          <Tooltip
                            title={
                              copied
                                ? 'Copied to clipboard!'
                                : 'Copy code to clipboard'
                            }
                            placement="right"
                            arrow
                          >
                            <Button
                              variant="outlined"
                              color="info"
                              startIcon={<ContentCopyOutlinedIcon />}
                              onClick={() => {
                                copy(queryTOTPCode.data || '');
                                setCopied(true);
                              }}
                              onMouseLeave={() => setCopied(false)}
                            >
                              Copy
                            </Button>
                          </Tooltip>

                          <Box
                            sx={{
                              flex: 1,
                              fontFamily: 'Roboto Mono',
                              fontSize: 12,
                              wordBreak: 'break-word',
                            }}
                          >
                            {queryTOTPCode.isFetching
                              ? '000000000000000000000'
                              : queryTOTPCode.data}
                          </Box>
                        </Stack>

                        {queryTOTPCode.isError ? (
                          <Alert
                            severity="error"
                            onClose={() => queryTOTPCode.refetch()}
                          >
                            An error has occured!
                          </Alert>
                        ) : null}
                      </Stack>
                    </Box>

                    <Divider />

                    <Box sx={{ p: 1 }}>
                      <Stack spacing={1}>
                        <Stack spacing={1} direction="row">
                          <Box>
                            <Box
                              sx={{
                                fontWeight: 'bold',
                                fontFamily: 'Roboto Mono',
                                border: '1px solid #000',
                                borderRadius: 50,
                                height: 22,
                                width: 22,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              2
                            </Box>
                          </Box>

                          <Box sx={{ pb: 1 }}>
                            Add the TOTP code generated from your Authenticator
                            App to verify that it is setup correctly.
                          </Box>
                        </Stack>

                        <TextField
                          id="multi-factor-info-totp-verify"
                          fullWidth
                          placeholder="000000"
                          autoComplete="false"
                          label="Code from Authenticator App"
                          value={verifyTOTPCode}
                          onChange={(event) => {
                            setVerifyTOTPCode(
                              event.target.value.replace(/\D/g, ''),
                            );
                          }}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ style: { fontFamily: 'Roboto Mono' } }}
                        />

                        {mutationTOTPVerify.isError ? (
                          <Alert
                            severity="error"
                            onClose={() => mutationTOTPVerify.reset()}
                          >
                            An error has occured!
                          </Alert>
                        ) : null}

                        <Button
                          disabled={
                            mutationTOTPVerify.isLoading ||
                            mutationTOTPVerify.isError ||
                            !verifyTOTPCode.trim()
                          }
                          fullWidth
                          variant="contained"
                          disableElevation
                          size="large"
                          color="info"
                          startIcon={<PinIcon />}
                          onClick={() => mutationTOTPVerify.mutate()}
                        >
                          {mutationTOTPVerify.isLoading
                            ? 'Updating MFA...'
                            : 'Verify Code and Set as MFA'}
                        </Button>

                        {mutationTOTPVerify.isSuccess ? (
                          <Alert severity="success">
                            Preference has been set!
                          </Alert>
                        ) : null}
                      </Stack>
                    </Box>
                  </Paper>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
