import CloseIcon from '@mui/icons-material/Close';
import ForwardIcon from '@mui/icons-material/Forward';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import React from 'react';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';

export function PolicyAdd(props: { AgtNo: string | undefined }) {
  // State
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    EOCovAmt: '0',
    EOEffDate: '',
    EOExpDate: '',
    EOCarrier: '',
    EOPolNo: '',
    EOCertNo: '',
  });

  React.useEffect(() => {
    if (open) {
      setData({
        EOCovAmt: '0',
        EOEffDate: format(new Date(), 'yyyy-MM-dd'),
        EOExpDate: format(new Date(), 'yyyy-MM-dd'),
        EOCarrier: '',
        EOPolNo: '',
        EOCertNo: '',
      });
    }
  }, [open]);

  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/eo/upsert', {
        body: { ...data, AgtNo: props.AgtNo },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/eo', { AgtNo: props.AgtNo }],
      });

      setOpen(false);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
    }
  };

  const submitDisabled =
    !isAgt(props.AgtNo) ||
    !data.EOCarrier ||
    !data.EOCertNo ||
    !data.EOCovAmt ||
    Number(data.EOCovAmt) <= 0 ||
    !data.EOEffDate ||
    !data.EOExpDate ||
    !data.EOPolNo;

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        component="button"
        onClick={() => setOpen(true)}
        sx={{
          p: 0,
          width: '100%',
          fontFamily: 'Roboto',
          minHeight: 64,
          display: 'flex',
          alignItems: 'center',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#03182E22',
          cursor: 'pointer',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            borderColor: '#03182E',
          },
          ':hover #forward-icon': {
            color: '#03182E',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            alignItems: {
              xs: 'flex-start',
              md: 'center',
            },
          }}
        >
          <Box sx={{ padding: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minWidth: 88,
                minHeight: 52,
              }}
            >
              <Box sx={{ fontFamily: 'Roboto Mono', color: '#b26500' }}>
                000000000
              </Box>
            </Box>
          </Box>
          <Box sx={{ padding: 1, fontSize: 22 }}>Add existing E&O Policy</Box>
        </Box>

        <Box sx={{ flex: 1 }} />

        <Box
          sx={{
            padding: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ForwardIcon
            id="forward-icon"
            sx={{ transition: 'all 0.2s', color: '#03182E22' }}
          />
        </Box>
      </Paper>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Box sx={{ flex: 1 }}>
              <Typography variant="h6" noWrap component="div">
                Add existing E&O Policy
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          <Box sx={{ flex: 1, overflow: 'auto', padding: 2 }}>
            <Stack spacing={2}>
              <TextField
                id="policy-add-EOPolNo"
                autoFocus
                fullWidth
                required
                label="Policy Number"
                name="EOPolNo"
                value={data.EOPolNo}
                onChange={handleChange}
              />

              <TextField
                id="policy-add-EOCertNo"
                fullWidth
                required
                label="Certificate Number"
                name="EOCertNo"
                value={data.EOCertNo}
                onChange={handleChange}
              />

              <TextField
                id="policy-add-EOCovAmt"
                fullWidth
                required
                label="Coverage Amount"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                name="EOCovAmt"
                value={data.EOCovAmt}
                onChange={handleChange}
              />

              <TextField
                id="policy-add-EOEffDate"
                fullWidth
                required
                label="Effective Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="EOEffDate"
                value={data.EOEffDate}
                onChange={handleChange}
              />

              <TextField
                id="policy-add-EOExpDate"
                fullWidth
                required
                label="Expiration Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                name="EOExpDate"
                value={data.EOExpDate}
                onChange={handleChange}
              />

              <TextField
                id="policy-add-EOCarrier"
                fullWidth
                required
                label="Carrier"
                name="EOCarrier"
                value={data.EOCarrier}
                onChange={handleChange}
              />

              {mutation.isError ? (
                <Alert
                  onClose={() => mutation.reset()}
                  severity="error"
                  sx={{ width: '100%' }}
                >
                  <strong style={{ marginRight: 10 }}>
                    An error has occurred!
                  </strong>
                  <small>Please refresh the page and try again.</small>
                </Alert>
              ) : null}

              <Button
                disabled={submitDisabled || mutation.isLoading}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {mutation.isLoading ? 'Adding Policy...' : 'Add E&O Policy'}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
