import DeleteIcon from '@mui/icons-material/Delete';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { EOPolicyData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function PolicyDocDelete(props: {
  AgtNo: string | undefined;
  policy: EOPolicyData;
  onSuccess: () => void;
}) {
  // Props
  const { policy } = props;

  const queryClient = useQueryClient();
  // Mutation - Delete the Agent's Errors & Omissions Certificate
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/file/delete', {
        body: { AgtNo: props.AgtNo, FileUploadID: policy.FileUploadID },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/eo', { AgtNo: props.AgtNo }],
      });

      props.onSuccess();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <Box sx={{ borderBottom: '1px solid #eee' }}>
      <Box sx={{ py: 2, px: 3, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            flex: 1,
            fontFamily: 'Roboto Mono',
            fontSize: 24,
            textDecoration: 'line-through',
            color: '#9b9b9b',
          }}
        >
          E&O Certificate
        </Box>

        <Box>Confirm delete document?</Box>

        <Box sx={{ pl: 1 }}>
          <Tooltip title="Delete document" placement="left" arrow>
            <span>
              <IconButton
                disabled={mutation.isLoading}
                size="small"
                onClick={() => mutation.mutate()}
              >
                {mutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <DeleteIcon color="error" />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      {mutation.isError ? (
        <Box sx={{ p: 1 }}>
          <Alert severity="error" onClose={() => mutation.reset()}>
            <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
            <small>Please refresh the page and try again.</small>
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
}
