import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import format from 'date-fns/format';
import { DisplayMarkup } from '../../../components/display-markup';
import { AnnouncementData } from '../../../types';

export function AnnouncementsItem(props: { item: AnnouncementData }) {
  // Props
  const { item } = props;

  const date: Date | null = item.StartDate ? new Date(item.StartDate) : null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: { xs: 'center' },
        alignItems: { xs: 'center', md: 'start' },
      }}
    >
      {date ? (
        <Box sx={{ p: 1 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                height: 120,
                width: 120,
                borderRadius: 2,
                backgroundColor: '#999',
                backgroundImage: `url(${item.Image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
            <Box sx={{ display: { xs: 'none', md: 'initial' } }}>
              <Box sx={{ fontFamily: 'Roboto Mono', fontSize: 12 }}>
                {date.getFullYear()}
              </Box>
              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontSize: 22,
                  lineHeight: 1,
                }}
              >
                {format(date, 'MMM').toUpperCase()}
              </Box>
              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontSize: 26,
                  lineHeight: 1,
                }}
              >
                {format(date, 'dd')}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box sx={{ p: 1, flex: 1 }}>
        <Stack spacing={1}>
          <Box sx={{ fontWeight: 'bold' }}>{item.Title}</Box>

          <DisplayMarkup markup={item.Description} sx={{ fontSize: 14 }} />

          <Box sx={{ display: { xs: 'initial', md: 'none' } }}>
            <Box
              sx={{
                fontFamily: 'Roboto Mono',
                fontSize: 12,
                fontStyle: 'italic',
                textAlign: 'right',
              }}
            >
              {date ? format(date, 'MMMM dd yyyy') : null}
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
