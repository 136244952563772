import ErrorIcon from '@mui/icons-material/Error';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { CardIcon } from '../../../components/card-icon';
import { CustomerPaymentData, PYMNT_DFLT, WalletData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { cardExpired } from '../../../utils/card-expired';

const getPymntTitle = (text: PYMNT_DFLT) => {
  switch (text) {
    case PYMNT_DFLT['EVT']: {
      return 'Events';
    }
    case PYMNT_DFLT['LDS']: {
      return 'Leads';
    }
    case PYMNT_DFLT['PRC']: {
      return `President's Club`;
    }
    default: {
      return text;
    }
  }
};

export function CardProfile(props: {
  data: CustomerPaymentData;
  queryKey: QueryFilters['queryKey'];
  onManage: () => void;
  onDelete: () => void;
  disableEditing: boolean;
}) {
  // Props
  const { data } = props;
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [updating, setUpdating] = React.useState<string>();
  // Hooks
  const queryClient = useQueryClient();

  // Mutation - Set Card as Default for an AR
  const mutation = useMutation({
    mutationFn: async (AR: PYMNT_DFLT) => {
      // Show updating on Menu Item
      setUpdating(AR);

      const response: {
        data: WalletData;
      } = await API.post(
        'ContractingAPI',
        '/billing/authorize/customer-profile/payment-profile/set-default',
        {
          body: {
            AR,
            customerPaymentProfileId: data.customerPaymentProfileId,
          },
        },
      );

      // Update cache with response
      if (props.queryKey) {
        queryClient.setQueryData(props.queryKey, response.data);
      }

      // Clear updating
      setUpdating(undefined);
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuId = `account-card-${data.customerPaymentProfileId}`;

  const isDefaultEvents = data.defaultFor?.includes(PYMNT_DFLT['EVT']);

  const isDefaultLeads = data.defaultFor?.includes(PYMNT_DFLT['LDS']);

  return (
    <Box
      sx={{
        width: '100%',
        height: 240,
        position: 'relative',
        border: '1px solid #4a4a4a88',
        display: 'flex',
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: '#fefefe',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: '#03182E22',
        transition: 'all 0.2s',
        ':hover': {
          transform: props.disableEditing ? 'none' : 'scale(1.01)',
          borderColor: props.disableEditing ? '#03182E22' : '#03182E',
          backgroundColor: '#fff',
        },
      }}
    >
      {props.disableEditing ? null : (
        <div style={{ position: 'absolute', top: 5, right: 5 }}>
          <Tooltip title="Manage card" placement="left" arrow>
            <IconButton
              aria-label="more"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon color="primary" />
            </IconButton>
          </Tooltip>

          <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              disabled={mutation.isLoading}
              onClick={() => {
                props.onManage();
                handleMenuClose();
              }}
            >
              Edit Card info
            </MenuItem>

            {Object.keys(PYMNT_DFLT).map((key) => {
              const pymntKey = key as PYMNT_DFLT;
              const isAlreadyDefault = data.defaultFor?.includes(pymntKey);
              const pymntTitle = getPymntTitle(pymntKey);
              return (
                <MenuItem
                  key={pymntKey}
                  disabled={mutation.isLoading || isAlreadyDefault}
                  onClick={() => {
                    mutation.mutate(pymntKey);
                  }}
                >
                  {updating === key
                    ? 'Setting as default...'
                    : isAlreadyDefault
                      ? `This card is default for ${pymntTitle}`
                      : `Set as Default for ${pymntTitle}`}
                </MenuItem>
              );
            })}

            <MenuItem
              disabled={mutation.isLoading || isDefaultEvents || isDefaultLeads}
              onClick={() => {
                props.onDelete();
                handleMenuClose();
              }}
              sx={{ color: 'red' }}
            >
              {isDefaultEvents || isDefaultLeads
                ? 'Cannot Delete while Default'
                : 'Delete'}
            </MenuItem>
          </Menu>
        </div>
      )}

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: `linear-gradient(to bottom, #2196f344, #ffff0022)`,
          borderRight: '1px solid #00000011',
        }}
      >
        <Stack spacing={0.6} alignItems="center">
          <CardIcon cardType={data.cardType || ''} size="6x" />

          {Object.keys(PYMNT_DFLT).map((key) => {
            const pymntKey = key as PYMNT_DFLT;
            const pymntTitle = getPymntTitle(pymntKey);

            if (data.defaultFor?.includes(pymntKey)) {
              return (
                <Tooltip
                  key={pymntKey}
                  title={`Default for ${pymntTitle}`}
                  placement="right"
                  arrow
                >
                  <Chip
                    label={pymntTitle}
                    color="info"
                    size="small"
                    variant="outlined"
                    style={{ fontWeight: 'bold' }}
                  />
                </Tooltip>
              );
            } else {
              return null;
            }
          })}

          {cardExpired({ expirationDate: data.expirationDate }) ? (
            <Chip
              label="Expired"
              variant="outlined"
              color="error"
              size="small"
              icon={<ErrorIcon />}
              style={{ fontWeight: 'bold' }}
            />
          ) : null}
        </Stack>
      </Box>

      <div
        style={{
          flex: 2,
          display: 'flex',
          alignItems: 'center',
          padding: 10,
        }}
      >
        <Stack spacing={2}>
          <strong style={{ fontSize: 16 }}>
            {data.cardType?.toUpperCase()}
          </strong>
          <Box>
            {data.billToFirstName} {data.billToLastName}
          </Box>
          <div>
            <span style={{ fontFamily: 'Roboto Mono' }}>
              **** **** **** {data.cardNumber?.replaceAll('X', '')}
            </span>
          </div>
          <div>
            <span style={{ fontFamily: 'Roboto Mono' }}>
              EXP: {data.expirationDate}
            </span>
          </div>
        </Stack>
      </div>
    </Box>
  );
}
