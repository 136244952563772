import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { EOPolicyData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';

export function PolicyEdit(props: {
  AgtNo: string | undefined;
  data: EOPolicyData;
  open: boolean;
  onClose: () => void;
}) {
  // State
  const [data, setData] = React.useState({
    EOCovAmt: '0',
    EOEffDate: '',
    EOExpDate: '',
    EOCarrier: '',
    EOPolNo: '',
    EOCertNo: '',
  });

  React.useEffect(() => {
    if (props.open) {
      setData({
        EOCovAmt: props.data.EOCovAmt?.toString() || '0',
        EOEffDate: props.data.EOEffDate || '',
        EOExpDate: props.data.EOExpDate || '',
        EOCarrier: props.data.EOCarrier || '',
        EOPolNo: props.data.EOPolNo || '',
        EOCertNo: props.data.EOCertNo || '',
      });
    }
  }, [props.open, props.data]);

  const queryClient = useQueryClient();
  // Mutation - Errors & Omissions Policy
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/eo/upsert', {
        body: { ...data, AgtNo: props.AgtNo },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/eo', { AgtNo: props.AgtNo }],
      });

      props.onClose();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    setData((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleClose = () => {
    if (!mutation.isLoading) {
      props.onClose();
    }
  };

  const submitDisabled =
    !isAgt(props.AgtNo) ||
    !data.EOCarrier ||
    !data.EOCertNo ||
    !data.EOCovAmt ||
    Number(data.EOCovAmt) <= 0 ||
    !data.EOEffDate ||
    !data.EOExpDate ||
    !data.EOPolNo;

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            {mutation.isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <CloseIcon />
            )}
          </IconButton>

          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" noWrap component="div">
              Edit E&O Policy
            </Typography>
          </Box>
        </Toolbar>

        <Divider />

        <Box sx={{ flex: 1, overflow: 'auto', padding: 2 }}>
          <Stack spacing={2}>
            <TextField
              id="policy-edit-EOPolNo"
              autoFocus
              fullWidth
              required
              label="Policy Number"
              name="EOPolNo"
              value={data.EOPolNo}
              onChange={handleChange}
            />

            <TextField
              id="policy-edit-EOCertNo"
              fullWidth
              required
              label="Certificate Number"
              name="EOCertNo"
              value={data.EOCertNo}
              onChange={handleChange}
            />

            <TextField
              id="policy-edit-EOCovAmt"
              fullWidth
              required
              label="Coverage Amount"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              name="EOCovAmt"
              value={data.EOCovAmt}
              onChange={handleChange}
            />

            <TextField
              id="policy-edit-EOEffDate"
              fullWidth
              required
              label="Effective Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="EOEffDate"
              value={data.EOEffDate}
              onChange={handleChange}
            />

            <TextField
              id="policy-edit-EOExpDate"
              fullWidth
              required
              label="Expiration Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              name="EOExpDate"
              value={data.EOExpDate}
              onChange={handleChange}
            />

            <TextField
              id="policy-edit-EOCarrier"
              fullWidth
              required
              label="Carrier"
              name="EOCarrier"
              value={data.EOCarrier}
              onChange={handleChange}
            />

            {mutation.isError ? (
              <Alert
                onClose={() => mutation.reset()}
                severity="error"
                sx={{ width: '100%' }}
              >
                <strong style={{ marginRight: 10 }}>
                  An error has occurred!
                </strong>
                <small>Please refresh the page and try again.</small>
              </Alert>
            ) : null}

            <Button
              disabled={submitDisabled || mutation.isLoading}
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {mutation.isLoading ? 'Saving changes...' : 'Save changes'}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
}
