import React from 'react';
import { AnimateIn, AnimateInProps } from '../animate-in';
import { BackToTop } from '../back-to-top';

export function RouteWrapper(props: {
  children: React.ReactNode;
  paddingBottom?: number;
  nofade?: AnimateInProps['nofade'];
  notransform?: AnimateInProps['notransform'];
  noanimation?: boolean;
  // Display full width without spacing for left side navigation
  fullWidth?: boolean;
}) {
  // State
  const [showButtonTop, setShowButtonTop] = React.useState(false);

  React.useEffect(() => {
    window?.addEventListener('scroll', (event) => {
      if (window && window.scrollY > 500) {
        setShowButtonTop(true);
      } else {
        setShowButtonTop(false);
      }
    });

    return () => window?.removeEventListener('scroll', () => {});
  }, []);

  const handleScrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
  };

  const paddingBottom =
    props.paddingBottom !== undefined ? props.paddingBottom : 0;

  return (
    <React.Fragment>
      {props.noanimation ? (
        <div style={{ paddingBottom }}>{props.children}</div>
      ) : (
        <AnimateIn
          to="top"
          nofade={props.nofade}
          notransform={props.notransform}
        >
          <div style={{ paddingBottom }}>{props.children}</div>
        </AnimateIn>
      )}

      {showButtonTop && (
        <BackToTop fullWidth={props.fullWidth} onClick={handleScrollToTop} />
      )}
    </React.Fragment>
  );
}
