import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { RouteError } from '../../components/route-container/route-error';
import { AuthContext } from '../../contexts/auth-context';
import {
  AuthorizeData,
  DocumentAgentData,
  DocumentCarrierData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { AgreementPDF } from './agreement-pdf';

const DocIframe = React.memo(function DocIframe(props: { src: string }) {
  // State
  const [loading, setLoading] = React.useState(true);

  return (
    <React.Fragment>
      {loading ? <LinearProgress /> : null}

      <iframe
        title="Agent Agreement"
        src={props.src}
        style={{ flex: 1, width: '100%', border: 'none' }}
        onLoad={() => setLoading(false)}
      />
    </React.Fragment>
  );
});

export function AgreementDoc(props: {
  open: boolean;
  doc: DocumentCarrierData | undefined;
  onClose: () => void;
  onSuccess: () => void;
}) {
  // Context
  const {
    state: { user, impersonatedAgent, authorize },
    dispatch,
  } = React.useContext(AuthContext);
  // State
  const [fetching, setFetching] = React.useState({
    Closing: false,
    Authorize: false,
    NIPR: false,
  });
  const [AgtDocID, setAgtDocID] = React.useState<
    DocumentAgentData['AgtDocID'] | null
  >();

  React.useEffect(() => {
    if (props.open && props.doc) {
      setAgtDocID(props.doc.AgtDocID);
      setFetching({ Closing: false, Authorize: false, NIPR: false });
    }
  }, [props.open, props.doc]);

  const queryClient = useQueryClient();

  // Fetch Authorize info after NIPR data
  const fetchAuthorize = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, Authorize: true }));
      const pathAuth = '/authorize';
      const response: {
        data: AuthorizeData;
      } = await API.post('ContractingAPI', pathAuth, {});
      if (response.data) {
        // Update cache
        queryClient.setQueryData([pathAuth], response.data);
        // Update Context
        dispatch({ type: 'SET_AUTHORIZE', payload: response.data });
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, Authorize: false }));
    }
  };

  // Fetch NIPR data on close
  const fetchNIPR = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, NIPR: true }));
      await API.post('ContractingAPI', '/nipr/info/initiate', {});
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, NIPR: false }));
    }
  };

  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const handleClose = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, Closing: true }));

      // Check to see if the Agreement has been signed
      // Adobe may have not called the API webhook right away
      // so wait one second before checking if the Agreement has been signed
      await new Promise((resolve) => setTimeout(resolve, 5000));

      if (authorize && authorize.Agent && authorize.Agent.HasNIPRData !== '1') {
        // Fetch NIPR info
        await fetchNIPR();
      }

      if (
        authorize &&
        authorize.Agent &&
        (authorize.Agent.HasNIPRData !== '1' ||
          authorize.Agent.HasSignedICA !== '1')
      ) {
        // Fetch Authorize info to see if "HasNIPRData" or "HasSignedICA" has been updated
        await fetchAuthorize();
      }

      // Refetch Agreement status
      await queryClient.invalidateQueries({
        queryKey: ['/documents/list', { DocTitle: 'The Alliance ICA' }],
      });

      // Refetch Getting Started
      await queryClient.invalidateQueries({
        queryKey: ['/agent/getting-started', { AgtNo }],
      });

      props.onClose();
      setAgtDocID(undefined);
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, Closing: false }));
    }
  };

  // Query - Get Agent Agreement
  const path = '/documents/get';
  const query = useQuery({
    enabled: Boolean(
      props.open && props.doc && AgtDocID !== undefined && AgtDocID !== null
    ),
    retry: 2,
    retryDelay: 3000,
    queryKey: [path, { AgtDocID }],
    queryFn: async () => {
      const response: {
        data: DocumentAgentData | null;
      } = await API.post('ContractingAPI', path, {
        body: { AgtDocID },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Create Agent Agreement
  const pathCreate = '/documents/create';
  const DocID = props.doc?.DocID;
  const queryCreate = useQuery({
    enabled: Boolean(
      props.open &&
        props.doc &&
        props.doc.Signed === null &&
        AgtDocID !== undefined &&
        AgtDocID === null
    ),
    retry: 2,
    retryDelay: 3000,
    queryKey: [pathCreate, { DocID }],
    queryFn: async () => {
      const response: {
        data: DocumentAgentData | null;
      } = await API.post('ContractingAPI', pathCreate, {
        body: { DocID },
      });

      if (response.data) {
        return response.data;
      }

      return null;
    },
    onSuccess: async (data) => {
      if (data) {
        setAgtDocID(data.AgtDocID);
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  let content = <Box />;
  if (fetching.NIPR) {
    content = (
      <Box>
        <LinearProgress color="info" />
        <Box sx={{ p: 4, textAlign: 'center' }}>
          Please wait while we check the National Insurance Producer Registry
          (NIPR) for your license information...
        </Box>
      </Box>
    );
  } else if (
    fetching.Closing ||
    fetching.Authorize ||
    query.isFetching ||
    queryCreate.isFetching
  ) {
    content = <LinearProgress />;
  } else if (query.isError || queryCreate.isError) {
    content = <RouteError />;
  } else if (query.data && query.data.SignerUrl) {
    content = (
      <Box
        sx={{ minHeight: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
      >
        {query.data.SignerUrl ? <DocIframe src={query.data.SignerUrl} /> : null}
      </Box>
    );
  } else if (query.data && query.data.Filename && AgtDocID) {
    content = <AgreementPDF AgtDocID={AgtDocID} />;
  }

  const isWorking =
    fetching.NIPR ||
    fetching.Closing ||
    fetching.Authorize ||
    Boolean(queryCreate.isFetching) ||
    Boolean(AgtDocID && query.isFetching);

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={props.open}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', md: '85vw', lg: '75vw', xl: '65vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton disabled={isWorking} sx={{ mr: 2 }} onClick={handleClose}>
            {isWorking ? <CircularProgress size={24} /> : <CloseIcon />}
          </IconButton>

          <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ px: 1, flex: 1 }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontSize: { xs: 14, sm: 18, md: 22 },
                  fontWeight: 'normal',
                }}
              >
                Agent Agreement
              </Typography>
            </Box>
          </Box>
        </Toolbar>

        <Divider />

        {content}
      </Box>
    </Drawer>
  );
}
