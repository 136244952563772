import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AnimateIn } from '../../../components/animate-in';
import { RouteError } from '../../../components/route-container/route-error';
import { RequestChangeData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { isAgt } from '../../../utils/is-agent';
import { AgentChangeRequest } from './agent-change-request';
import { ManageChanges } from './manage-changes';
import { ManageChangesInfo } from './manage-changes-info';

export function PendingChanges(props: {
  enabled: boolean;
  isContAuth: boolean;
  AgtNo: string | undefined;
}) {
  // Props
  const { enabled, isContAuth, AgtNo } = props;
  // State
  const [changes, setChanges] = React.useState<RequestChangeData>();
  const [errMsg, setErrMsg] = React.useState('');

  // Query - Pending Changes
  const path = '/agent/hier-change/pending';
  const queryKey = [path, { AgtNo }];
  const query = useQuery({
    enabled,
    queryKey: queryKey,
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: RequestChangeData[];
        } = await API.post('ContractingAPI', path, {
          body: { AgtNo },
        });

        if (response.data.length) {
          const newData: RequestChangeData[] = response.data.map((i) => {
            if (i.ToLevel !== null) {
              return { ...i, ChangeType: 'level', ChangeTo: i.ToLevel };
            } else {
              return { ...i, ChangeType: 'hierarchy', ChangeTo: i.ToName };
            }
          });
          return newData;
        }
      }

      return [];
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'forbidden'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  if (Boolean(query.isLoading && query.fetchStatus !== 'idle')) {
    return <LinearProgress color="info" />;
  } else if (query.isError && errMsg) {
    return (
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Alert severity="warning">
          Your are not able to view{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            Pending Changes
          </Box>{' '}
          for the impersonated Agent{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {props.AgtNo}
          </Box>
          .
        </Alert>
      </Container>
    );
  } else if (query.isError) {
    return <RouteError />;
  } else {
    return (
      <AnimateIn to="top">
        <Container maxWidth="md" sx={{ pt: 2, pb: 16 }}>
          <Stack spacing={2}>
            {isContAuth ? (
              <React.Fragment>
                <AgentChangeRequest queryKey={queryKey} />

                <Divider />
              </React.Fragment>
            ) : null}

            <ManageChanges
              data={query.data || []}
              onSelect={(newChange) => setChanges(newChange)}
            />
          </Stack>

          <ManageChangesInfo
            agent={changes}
            onClose={() => setChanges(undefined)}
          />
        </Container>
      </AnimateIn>
    );
  }
}
