import Box from '@mui/material/Box';
import React from 'react';

export function DisplayValue(props: {
  label: string;
  value: JSX.Element | string | number | null;
  monospace?: boolean;
  href?: string | null;
}) {
  const isString = typeof props.value === 'string';
  const isNumber = typeof props.value === 'number';
  return (
    <Box sx={{ p: 1 }}>
      <Box
        sx={{
          textTransform: 'uppercase',
          fontSize: 10,
          fontWeight: 'bold',
          color: '#9b9b9b',
        }}
      >
        {props.label}
      </Box>
      {props.value && (isString || isNumber) ? (
        <Box
          sx={{
            fontSize: 18,
            fontFamily: props.monospace ? 'Roboto Mono' : 'inherit',
          }}
        >
          {props.href ? <a href={props.href}>{props.value}</a> : props.value}
        </Box>
      ) : props.value && React.isValidElement(props.value) ? (
        props.value
      ) : (
        <Box
          sx={{
            fontSize: 18,
            fontWeight: 'bold',
            fontFamily: 'Roboto Mono',
            color: '#ccc',
          }}
        >
          ---
        </Box>
      )}
    </Box>
  );
}
