import { Check } from '@mui/icons-material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import React from 'react';
import { AuthContext } from '../../../contexts/auth-context';
import { materialTheme } from '../../../theme';
import { NotificationData } from '../../../types';
import { captureError } from '../../../utils/capture-error';

export function NotificationItem(props: { data: NotificationData }) {
  // Props
  const { data } = props;
  // Context
  const {
    state: { impersonatedAgent },
  } = React.useContext(AuthContext);

  const queryClient = useQueryClient();
  // Mutation - Mark Notification As Read
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/notifications/read', {
        body: { NotificationId: data.NotificationId },
      });
    },
    onSuccess: async () => {
      // Refetch all notifications
      await queryClient.invalidateQueries({
        queryKey: ['/notifications/all'],
      });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box>
        <Box
          sx={{
            fontFamily: 'Roboto Mono',
            fontSize: 14,
            textDecoration: 'italic',
            fontWeight: 'bold',
          }}
        >
          {format(new Date(data.CreatedDate || ''), 'MM/dd/yyyy')}{' '}
          <span
            style={{
              fontWeight: 'bold',
              fontSize: 14,
              color: materialTheme.palette.secondary.main,
            }}
          >
            {data.Read === false ? 'New' : null}
          </span>
        </Box>
        <Box sx={{ fontSize: 14 }}>{data.Message} </Box>
      </Box>
      {data.Read === false && impersonatedAgent?.AgtNo === undefined ? (
        <Tooltip title="Mark as read" placement="left" arrow>
          <IconButton
            size="small"
            disabled={mutation.isLoading}
            onClick={() => mutation.mutate()}
          >
            {mutation.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <Check fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
}
