import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy } from 'lodash';
import React from 'react';
import { Agreement } from '../../components/agreement';
import { Contract } from '../../components/contract';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { AuthContext } from '../../contexts/auth-context';
import {
  CarrierData,
  DocumentCarrierData,
  DocumentCarrierGroupData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { CarrierListGroup } from './carrier-list-group';

export function Requests() {
  // Context
  const {
    state: { impersonatedAgent, authorize },
  } = React.useContext(AuthContext);
  // State
  const [searchString, setSearchString] = React.useState('');
  const [CarrierID, setCarrierID] = React.useState<CarrierData['CarrierID']>();
  const [DocID, setDocID] = React.useState<DocumentCarrierData['DocID']>();

  // This page cannot be impersonated
  const enabled =
    impersonatedAgent === null && authorize?.Agent?.HasSignedICA === '1';

  let agreementWarning: JSX.Element | null = null;
  if (authorize && authorize.Agent && authorize.Agent.HasSignedICA !== '1') {
    agreementWarning = (
      <Alert severity="warning">
        New Contracting Requests cannot be created until the Agent Agreement has
        been accepted.
      </Alert>
    );
  }

  let impersonateWarning: JSX.Element | null = null;
  if (impersonatedAgent !== null) {
    impersonateWarning = (
      <Alert severity="info">Impersonation does not work on this page.</Alert>
    );
  }

  // Query - Carriers
  const pathCarriers = '/carrier';
  const queryCarriers = useQuery({
    enabled,
    queryKey: [pathCarriers],
    queryFn: async () => {
      const response: {
        data: CarrierData[];
      } = await API.post('ContractingAPI', pathCarriers, {});

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Adobe Sign Documents
  const pathDocuments = '/documents/list';
  const queryKey = [pathDocuments];
  const queryDocuments = useQuery({
    enabled,
    queryKey,
    queryFn: async () => {
      const response: {
        data: {
          Groups: DocumentCarrierGroupData[];
          Contracts: DocumentCarrierData[];
        };
      } = await API.post('ContractingAPI', pathDocuments, {});

      if (response.data) {
        return response.data;
      }

      return {
        Groups: [],
        Contracts: [],
      };
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const availableContracts =
    queryDocuments.data && queryCarriers.data
      ? queryDocuments.data.Contracts.map((i) => {
          const carrier = queryCarriers.data.find(
            (c) => c.CarrierID === i.CarrierID
          );
          // Add the Full Name of the carrier to each contract to assist with searching
          if (carrier) {
            return { ...i, FullName: carrier.FullName };
          } else {
            return i;
          }
        })
      : [];

  // Searching
  const searchedContracts = searchBy(availableContracts, searchString);

  // Ordering Groups
  const orderedGroups = orderBy(
    queryDocuments.data?.Groups || [],
    'OrderIndex'
  );

  const isLoading =
    Boolean(queryCarriers.isLoading && queryCarriers.fetchStatus !== 'idle') ||
    Boolean(queryDocuments.isLoading && queryDocuments.fetchStatus !== 'idle');

  return (
    <RouteContainer
      routeTitle="Contracting Requests"
      withContainer
      loading={isLoading}
      hasError={queryCarriers.isError || queryDocuments.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Stack spacing={2}>
          {agreementWarning}

          {agreementWarning !== null ? <Agreement hideIfCompleted /> : null}

          {impersonateWarning}

          {impersonatedAgent === null ? (
            <SearchField
              id="carriers-search"
              fullWidth
              size="medium"
              placeholder="Search carriers"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
            />
          ) : null}

          {impersonatedAgent === null
            ? orderedGroups.map((group) => {
                return (
                  <CarrierListGroup
                    key={group.ListGroup}
                    carriers={queryCarriers.data || []}
                    items={searchedContracts}
                    group={group}
                    onSelect={(CarrierID, doc) => {
                      setCarrierID(CarrierID);
                      setDocID(doc.DocID);
                    }}
                  />
                );
              })
            : null}
        </Stack>

        <Contract
          carrier={queryCarriers.data?.find((i) => i.CarrierID === CarrierID)}
          AgtDocID={
            queryDocuments.data?.Contracts.find((i) => i.DocID === DocID)
              ?.AgtDocID || null
          }
          doc={queryDocuments.data?.Contracts.find((i) => i.DocID === DocID)}
          queryKey={queryKey}
          onClose={() => {
            setCarrierID(undefined);
            setDocID(undefined);
          }}
        />
      </Box>
    </RouteContainer>
  );
}
