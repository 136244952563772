import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StartIcon from '@mui/icons-material/Start';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { orderBy } from 'lodash';
import React from 'react';
import {
  CarrierData,
  DocumentCarrierData,
  DocumentCarrierGroupData,
} from '../../types';
import { CarrierListItem } from './carrier-list-item';

export function CarrierListGroup(props: {
  carriers: CarrierData[];
  items: DocumentCarrierData[];
  group: DocumentCarrierGroupData;
  onSelect: (
    CarrierID: CarrierData['CarrierID'],
    Doc: DocumentCarrierData
  ) => void;
}) {
  // Props
  const { items, group } = props;
  // State
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    // If items changes because of something like searching open the group up
    setOpen(true);
  }, [items]);

  const groupItems = items.filter((i) => i.ListGroup === group.ListGroup);

  if (groupItems.length) {
    const orderedItems = orderBy(groupItems, 'OrderIndex');

    return (
      <Stack
        spacing={2}
        sx={{
          p: 1,
          backgroundColor: '#f9f9f9',
          border: '1px solid #c1c1c1',
          borderRadius: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StartIcon sx={{ color: '#00C868aa' }} />

          <Box
            sx={{
              mx: 1,
              width: { xs: 10, sm: 20, md: 30, lg: 40 },
              height: 2,
              backgroundColor: '#2196f388',
            }}
          />

          <Box
            sx={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              fontSize: { xs: 14, sm: 18, md: 20, lg: 22 },
              color: '#2196f3',
            }}
          >
            {group.ListGroup}
          </Box>

          <Box
            sx={{
              mx: 1,
              flex: 1,
              height: 2,
              backgroundColor: '#2196f388',
            }}
          />

          <Tooltip title={open ? 'Collapse' : 'Expand'} placement="left" arrow>
            <IconButton
              size="small"
              sx={{ backgroundColor: '#ffffff44' }}
              onClick={() => setOpen((currentState) => !currentState)}
            >
              {open ? (
                <ExpandLessIcon fontSize="small" />
              ) : (
                <ExpandMoreIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>

        {open ? (
          <React.Fragment>
            {orderedItems.map((doc) => {
              // Find the Carrier for this Document
              const carrier = props.carriers.find(
                (i) => i.CarrierID === doc.CarrierID
              );

              if (carrier) {
                return (
                  <CarrierListItem
                    key={doc.DocID}
                    carrier={carrier}
                    doc={doc}
                    onClick={() => props.onSelect(carrier.CarrierID, doc)}
                  />
                );
              } else {
                return null;
              }
            })}
          </React.Fragment>
        ) : null}
      </Stack>
    );
  } else {
    return null;
  }
}
