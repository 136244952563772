import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AnimateIn } from '../../../components/animate-in';
import { RouteError } from '../../../components/route-container/route-error';
import { CarrierData, PendingContractData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { ManageContracts } from './manage-contracts';
import { ManageContractsInfo } from './manage-contracts-info';

export function PendingContracts(props: {
  enabled: boolean;
  AgtNo: string | undefined;
}) {
  // Props
  const { enabled, AgtNo } = props;
  // State
  const [selected, setSelected] =
    React.useState<PendingContractData['AgtDocID']>();
  const [errMsg, setErrMsg] = React.useState('');

  // Query - Carriers
  const pathCarriers = '/carrier';
  const queryCarriers = useQuery({
    queryKey: [pathCarriers],
    queryFn: async () => {
      const response: {
        data: CarrierData[];
      } = await API.post('ContractingAPI', pathCarriers, {
        body: {},
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Pending Contracts
  const path = '/documents/team/pending-contracts';
  const query = useQuery({
    enabled,
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      const response: {
        data: PendingContractData[];
      } = await API.post('ContractingAPI', path, {
        body: { AgtNo },
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'forbidden'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  if (
    Boolean(query.isLoading && query.fetchStatus !== 'idle') ||
    queryCarriers.isLoading
  ) {
    return <LinearProgress color="info" />;
  } else if (query.isError && errMsg) {
    return (
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Alert severity="warning">
          Your are not able to view{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            Pending Contracts
          </Box>{' '}
          for the impersonated Agent{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {props.AgtNo}
          </Box>
          .
        </Alert>
      </Container>
    );
  } else if (query.isError || queryCarriers.isError) {
    return <RouteError />;
  } else {
    return (
      <AnimateIn to="top">
        <Container maxWidth="md">
          <ManageContracts
            data={query.data || []}
            carriers={queryCarriers.data || []}
            onSelect={(newContract) => setSelected(newContract.AgtDocID)}
          />

          <ManageContractsInfo
            contract={query.data?.find((i) => i.AgtDocID === selected)}
            onClose={() => setSelected(undefined)}
          />
        </Container>
      </AnimateIn>
    );
  }
}
