import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { QueryFilters } from '@tanstack/react-query';
import React from 'react';
import { CustomerPaymentData, WalletData } from '../../../types';
import { CardProfile } from './card-profile';
import { CardProfileAdd } from './card-profile-add';
import { CardProfileDelete } from './card-profile-delete';
import { CardProfileManage } from './card-profile-manage';

export function CreditCards(props: {
  data: WalletData['credit-cards'] | undefined;
  queryKey: QueryFilters['queryKey'];
  disableEditing: boolean;
}) {
  // State
  const [managing, setManaging] =
    React.useState<CustomerPaymentData['customerPaymentProfileId']>();
  const [deleting, setDeleting] =
    React.useState<CustomerPaymentData['customerPaymentProfileId']>();

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ border: '1px solid #00000022' }}>
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap component="div">
              Credit Cards
            </Typography>
          </Box>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CardProfileAdd queryKey={props.queryKey} />
              </Grid>

              {props.data?.customerPaymentProfiles.map((profile) => {
                return (
                  <Grid
                    key={profile.customerPaymentProfileId}
                    item
                    xs={12}
                    md={6}
                  >
                    <CardProfile
                      data={profile}
                      queryKey={props.queryKey}
                      onManage={() =>
                        setManaging(profile.customerPaymentProfileId)
                      }
                      onDelete={() =>
                        setDeleting(profile.customerPaymentProfileId)
                      }
                      disableEditing={props.disableEditing}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Paper>

      <CardProfileManage
        data={props.data?.customerPaymentProfiles.find(
          (i) => i.customerPaymentProfileId === managing,
        )}
        queryKey={props.queryKey}
        onClose={() => setManaging(undefined)}
      />

      <CardProfileDelete
        data={props.data?.customerPaymentProfiles.find(
          (i) => i.customerPaymentProfileId === deleting,
        )}
        queryKey={props.queryKey}
        onClose={() => setDeleting(undefined)}
      />
    </React.Fragment>
  );
}
