import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnimateIn } from '../animate-in';

export function RouteError() {
  return (
    <React.Fragment>
      <div style={{ height: 4, backgroundColor: '#b71c1c' }} />

      <AnimateIn to="top">
        <div
          style={{
            width: '96%',
            maxWidth: 400,
            margin: '10px auto',
            padding: 10,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6">Sorry, something went wrong.</Typography>
          <Typography variant="h6" gutterBottom>
            We're working on it and we'll get it fixed as soon as we can.
          </Typography>
          <Link to="/home">Home</Link>
        </div>
      </AnimateIn>
    </React.Fragment>
  );
}
