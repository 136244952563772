import CloseIcon from '@mui/icons-material/Close';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  QueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { SelectAgent } from '../../../components/select-agent';
import { AuthContext } from '../../../contexts/auth-context';
import { AgentSelect } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { validateEmail } from '../../../utils/validation';

export function AgentChangeRequest(props: {
  queryKey: QueryFilters['queryKey'];
}) {
  // Context
  const {
    state: { impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [change, setChange] = React.useState<'hierarchy' | 'level'>(
    'hierarchy'
  );
  const [agent, setAgent] = React.useState<AgentSelect | null>(null);
  const [upline, setUpline] = React.useState<AgentSelect | null>(null);
  const [Level, setLevel] = React.useState('55');
  const [updateCellPh, setUpdateCellPh] = React.useState<string | null>(null);
  const [updateEmail, setUpdateEmail] = React.useState<string | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<any>(null);

  React.useEffect(() => {
    if (open) {
      setChange('hierarchy');
      setAgent(null);
      setUpline(null);
      setLevel('55');
      setUpdateCellPh(null);
      setUpdateEmail(null);
      setErrorMessage(null);
    }
  }, [open]);

  // Query - Levels available for Change
  const pathLevels = '/agent/levels';
  const queryLevels = useQuery({
    enabled: open,
    queryKey: [pathLevels],
    queryFn: async () => {
      const response: {
        data: string[];
      } = await API.post('ContractingAPI', pathLevels, {
        body: {},
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const queryClient = useQueryClient();
  // Mutation - Agent Change Request
  const mutation = useMutation({
    mutationFn: async () => {
      const body: any = { AgtNo: agent?.AgtNo };
      if (change === 'level') {
        body.Upline = null;
        body.Level = Level;
      } else if (change === 'hierarchy') {
        body.Upline = upline?.AgtNo;
        body.Level = null;
        if (updateCellPh && updateCellPh !== agent?.CellPh) {
          body.UpdateCellPh = updateCellPh;
        }
        if (updateEmail && updateEmail !== agent?.Email) {
          body.UpdateEmail = updateEmail;
        }
      }
      if (impersonatedAgent) {
        body.Manager = impersonatedAgent.AgtNo;
      }
      await API.post('ContractingAPI', '/agent/hier-change/request', { body });
    },
    onSuccess: async () => {
      // Refetch Pending Changes
      await queryClient.invalidateQueries({ queryKey: props.queryKey });

      setOpen(false);
    },
    onError: (error: any) => {
      captureError({ data: { error } });
      setErrorMessage(error.response.data.errors);
    },
  });

  let buttonText = 'Request change for';
  let submitDisabled = true;
  if (change === 'level') {
    buttonText = buttonText + ' level';
    submitDisabled = agent === null || !Level;
  } else if (change === 'hierarchy') {
    buttonText = buttonText + ' hierarchy';
    submitDisabled =
      agent === null ||
      upline === null ||
      validateEmail({ email: updateEmail || '' });
  }

  const handleClose = () => {
    if (!mutation.isLoading) {
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<ManageAccountsIcon />}
        onClick={() => setOpen(true)}
      >
        Change Hierarchy/Level for Agent
      </Button>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {mutation.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontSize: { xs: 16, md: 22 }, fontWeight: 'normal' }}
              >
                Change Hierarchy/Level for Agent
              </Typography>
            </Box>
          </Toolbar>

          <Divider />

          <Box sx={{ flex: 1, overflow: 'auto' }}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <ToggleButtonGroup
                fullWidth
                exclusive
                color="info"
                value={change}
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newValue: string | null
                ) => {
                  if (newValue) {
                    setChange(newValue as 'hierarchy' | 'level');
                  }
                  if (newValue === 'level') {
                    setUpline(null);
                  }
                  setAgent(null);
                  setUpline(null);
                }}
              >
                <ToggleButton size="medium" value="hierarchy">
                  Hierarchy
                </ToggleButton>
                <ToggleButton size="medium" value="level">
                  Level
                </ToggleButton>
              </ToggleButtonGroup>

              {change === 'hierarchy' ? (
                <Stack spacing={2}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    {agent !== null ? (
                      <Box sx={{ minWidth: 84, fontWeight: 'bold' }}>
                        For Agent
                      </Box>
                    ) : null}

                    <Box sx={{ flex: 1 }}>
                      <SelectAgent
                        searchAll
                        autoFocus
                        label="For Agent"
                        onSelectAgent={(newAgent) => {
                          setAgent(newAgent);
                          setUpdateCellPh(null);
                          setUpdateEmail(null);
                        }}
                      />
                    </Box>
                  </Stack>
                  {/* {agent !== null && (
                    <React.Fragment>
                      <Box
                        sx={{
                          textAlign: 'center',
                          fontSize: 12,
                          color: '#5a5a5a',
                        }}
                      >
                        Please update any incorrect contact information below.
                      </Box>

                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box sx={{ minWidth: 84 }} />

                        <Box
                          sx={{
                            flex: 1,
                          }}
                        >
                          {updateCellPh === null ? (
                            <Tooltip
                              title="Update cell phone"
                              placement="bottom"
                              arrow
                            >
                              <ButtonBase
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  backgroundColor: '#ffffff77',
                                  border: '1px solid #ccc',
                                  borderRadius: 1,
                                  minHeight: 40,
                                  transition: 'all 0.2s',
                                  ':hover': {
                                    backgroundColor: '#fff',
                                  },
                                }}
                                onClick={() =>
                                  setUpdateCellPh(agent?.CellPh || '')
                                }
                              >
                                <Box
                                  sx={{
                                    px: 1.2,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <PhoneAndroid
                                    fontSize="small"
                                    sx={{ color: '#7a7a7a' }}
                                  />
                                </Box>
                                <Typography
                                  noWrap
                                  sx={{ width: 175, textAlign: 'left' }}
                                >
                                  {agent?.CellPh || ''}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                direction: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                color="info"
                                style={{ backgroundColor: '#fff' }}
                              >
                                <InputLabel>Cell Phone</InputLabel>
                                <OutlinedInput
                                  label="Cell Phone"
                                  autoFocus
                                  size="small"
                                  value={updateCellPh}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setUpdateCellPh(e.target.value);
                                  }}
                                  name="CellPh"
                                  inputComponent={PhoneMask as any}
                                  sx={{ pl: 1 }}
                                  startAdornment={
                                    <InputAdornment
                                      position="start"
                                      sx={{ pl: 0.5 }}
                                    >
                                      <PhoneAndroid fontSize="small" />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Tooltip title="Cancel" placement="bottom" arrow>
                                <IconButton
                                  sx={{
                                    ml: 1,
                                    backgroundColor: '#fff',
                                    border: '1px solid #c1c1c1',
                                    borderRadius: 2,
                                  }}
                                  onClick={() => setUpdateCellPh(null)}
                                >
                                  <Cancel fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Stack>

                      <Stack spacing={1} direction="row" alignItems="center">
                        <Box sx={{ minWidth: 84 }} />
                        <Box sx={{ flex: 1 }}>
                          {updateEmail === null ? (
                            <Tooltip
                              title="Update email"
                              placement="bottom"
                              arrow
                            >
                              <ButtonBase
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'flex-start',
                                  backgroundColor: '#ffffff77',
                                  border: '1px solid #ccc',
                                  borderRadius: 1,
                                  minHeight: 40,
                                  transition: 'all 0.2s',
                                  ':hover': {
                                    backgroundColor: '#fff',
                                  },
                                }}
                                onClick={() =>
                                  setUpdateEmail(agent?.Email || '')
                                }
                              >
                                <Box
                                  sx={{
                                    px: 1.2,
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Email
                                    fontSize="small"
                                    sx={{ color: '#7a7a7a' }}
                                  />
                                </Box>
                                <Typography noWrap sx={{ textAlign: 'left' }}>
                                  {agent?.Email || ''}
                                </Typography>
                              </ButtonBase>
                            </Tooltip>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                direction: 'row',
                                alignItems: 'center',
                              }}
                            >
                              <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                color="info"
                                style={{ backgroundColor: '#fff' }}
                                error={validateEmail({
                                  email: updateEmail || '',
                                })}
                              >
                                <InputLabel>Email</InputLabel>
                                <OutlinedInput
                                  label="Email"
                                  fullWidth
                                  autoFocus
                                  size="small"
                                  value={updateEmail || ''}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setUpdateEmail(e.target.value);
                                  }}
                                  name="Email"
                                  sx={{ pl: 1 }}
                                  startAdornment={
                                    <InputAdornment
                                      position="start"
                                      sx={{ pl: 0.5 }}
                                    >
                                      <Email fontSize="small" />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Tooltip title="Cancel" placement="bottom" arrow>
                                <IconButton
                                  sx={{
                                    ml: 1,
                                    backgroundColor: '#fff',
                                    border: '1px solid #c1c1c1',
                                    borderRadius: 2,
                                  }}
                                  onClick={() => setUpdateEmail(null)}
                                >
                                  <Cancel fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    </React.Fragment>
                  )}
                  <Divider /> */}

                  <Stack spacing={1} direction="row" alignItems="center">
                    {upline !== null ? (
                      <Box sx={{ minWidth: 84, fontWeight: 'bold' }}>
                        New Upline
                      </Box>
                    ) : null}

                    <Box sx={{ flex: 1 }}>
                      <SelectAgent
                        label="New Upline"
                        onSelectAgent={(newAgent) => setUpline(newAgent)}
                      />
                    </Box>
                  </Stack>
                </Stack>
              ) : null}

              {change === 'level' ? (
                <Stack spacing={2}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    {agent !== null ? (
                      <Box sx={{ fontWeight: 'bold' }}>For Agent</Box>
                    ) : null}

                    <Box sx={{ flex: 1 }}>
                      <SelectAgent
                        autoFocus
                        label="For Agent"
                        onSelectAgent={(newAgent) => setAgent(newAgent)}
                      />
                    </Box>
                  </Stack>

                  <FormControl fullWidth size="small">
                    <InputLabel id="approval-level-select-label">
                      New Level
                    </InputLabel>
                    <Select
                      labelId="approval-level-select-label"
                      id="approval-level-select"
                      value={Level}
                      label="New Level"
                      onChange={(event) => setLevel(event.target.value)}
                    >
                      {queryLevels.data?.map((level) => {
                        return (
                          <MenuItem key={level} value={level}>
                            {level}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Stack>
              ) : null}

              <Box sx={{ textAlign: 'center', fontSize: 12, color: '#5a5a5a' }}>
                All changes will be reviewed by an Admin before taking effect.
              </Box>

              {mutation.isError ? (
                <Alert
                  onClose={() => {
                    mutation.reset();
                    setErrorMessage(null);
                  }}
                  severity="error"
                  sx={{ width: '100%' }}
                >
                  <strong style={{ marginRight: 10 }}>
                    An error has occurred!
                  </strong>
                  {errorMessage
                    ? errorMessage
                    : 'Please refresh the page and try again'}
                </Alert>
              ) : null}

              <Button
                disabled={
                  submitDisabled || mutation.isLoading || mutation.isError
                }
                fullWidth
                variant="contained"
                size="large"
                onClick={() => mutation.mutate()}
              >
                {mutation.isLoading ? 'Submitting request...' : buttonText}
              </Button>
            </Stack>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
