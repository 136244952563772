import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { DocumentAgentData, DocumentQuestionData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { DisplayMarkup } from '../display-markup';
import { DisplayValue } from '../display-value';

export function ContractStartedQuestions(props: { data: DocumentAgentData }) {
  // State
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.data.Filename === null) {
      setOpen(true);
    }
  }, [props.data]);

  // Query - Initial Questions before Contracting
  const DocID = props.data.DocID;
  const path = '/documents/form-questions/list';
  const query = useQuery({
    queryKey: [path, { DocID }],
    queryFn: async () => {
      const response: {
        data: DocumentQuestionData[];
      } = await API.post('ContractingAPI', path, {
        body: { DocID },
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onSuccess: (data) => {},
    onError: (error) => captureError({ data: { error } }),
  });

  return (
    <React.Fragment>
      <Box
        sx={{
          p: 1,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #00000011',
        }}
      >
        <Typography variant="h6" noWrap component="div">
          Contract Questions ({query.data?.length || 0})
        </Typography>

        <Box sx={{ flex: 1 }} />

        <Tooltip
          title={open ? 'Collapse' : 'View Contract Questions'}
          placement="left"
          arrow
        >
          <IconButton onClick={() => setOpen((currentState) => !currentState)}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      </Box>

      {open ? (
        <Box
          sx={{
            borderBottom: '1px solid #00000011',
            minHeight: 0,
            flex: 1,
            overflow: 'auto',
          }}
        >
          {query.data?.map((item, index) => {
            return (
              <Box
                key={item.QID}
                sx={{ display: 'flex', borderBottom: '1px solid #00000011' }}
              >
                <Box
                  sx={{
                    minWidth: 52,
                    borderRight: '1px solid #00000011',
                    backgroundColor: '#2196f311',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Box
                    sx={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto Mono',
                      textTransform: 'uppercase',
                      color: '#2196f3',
                    }}
                  >
                    {index + 1}
                  </Box>
                </Box>

                <Box sx={{ pl: 1, flex: 1 }}>
                  <DisplayValue
                    label="Question"
                    value={
                      <DisplayMarkup
                        markup={item.Question}
                        sx={{ fontSize: 14 }}
                      />
                    }
                  />

                  <DisplayValue
                    label="Answer"
                    value={
                      item.Answer ? (
                        <Box sx={{ fontSize: 20 }}>{item.Answer}</Box>
                      ) : (
                        <Box
                          sx={{
                            fontSize: 18,
                            fontWeight: 'bold',
                            fontFamily: 'Roboto Mono',
                            color: '#ccc',
                          }}
                        >
                          ---
                        </Box>
                      )
                    }
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : null}
    </React.Fragment>
  );
}
