import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { AnimateIn } from '../../../components/animate-in';
import { RouteError } from '../../../components/route-container/route-error';
import { GettingStartedTeamData } from '../../../types';
import { captureError } from '../../../utils/capture-error';
import { isAgt } from '../../../utils/is-agent';
import { ManageProgress } from './manage-progress';
import { ManageProgressInfo } from './manage-progress-info';

export function GettingStarted(props: {
  enabled: boolean;
  AgtNo: string | undefined;
}) {
  // Props
  const { enabled, AgtNo } = props;
  // State
  const [progress, setProgress] = React.useState<GettingStartedTeamData>();
  const [errMsg, setErrMsg] = React.useState('');

  // Query - Getting Started
  const path = '/agent/team/getting-started';
  const query = useQuery({
    enabled,
    queryKey: [path, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: GettingStartedTeamData[];
        } = await API.post('ContractingAPI', path, {
          body: { AgtNo },
        });

        if (response.data.length) {
          return response.data;
        }
      }

      return [];
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'forbidden'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  if (Boolean(query.isLoading && query.fetchStatus !== 'idle')) {
    return <LinearProgress color="info" />;
  } else if (query.isError && errMsg) {
    return (
      <Container maxWidth="md" sx={{ pt: 2 }}>
        <Alert severity="warning">
          Your are not able to view{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            Getting Started
          </Box>{' '}
          for the impersonated Agent{' '}
          <Box component="span" sx={{ fontWeight: 'bold' }}>
            {props.AgtNo}
          </Box>
          .
        </Alert>
      </Container>
    );
  } else if (query.isError) {
    return <RouteError />;
  } else {
    return (
      <AnimateIn to="top">
        <Container maxWidth="md" sx={{ pt: 2, pb: 16 }}>
          <Stack spacing={2}>
            <ManageProgress
              data={query.data || []}
              onSelect={(newProgress) => setProgress(newProgress)}
            />
          </Stack>

          <ManageProgressInfo
            progress={progress}
            onClose={() => setProgress(undefined)}
          />
        </Container>
      </AnimateIn>
    );
  }
}
