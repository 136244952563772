export interface QuestionData {
  id: number;
  question: string;
  answer: string;
}

export const commonQuestions: QuestionData[] = [
  {
    id: 0,
    question: 'Why do I need to complete a contract agreement?',
    answer:
      '<p>The contract agreement is an essential step in establishing your relationship with the insurance carrier. It delineates the terms and conditions of your partnership, defines your rights and responsibilities, and lays out the specifics of compensation, training, and support.</p>',
  },
  {
    id: 1,
    question:
      'What information will I need to submit in the contract agreement?',
    answer:
      '<p>You will typically be asked to provide your personal information, business information, and professional credentials. This includes your name, address, contact details, insurance license number, and potentially proof of E&O (Errors and Omissions) insurance. Additional details such as your tax identification number and bank account information for direct deposit may also be required.</p>',
  },
  {
    id: 2,
    question: 'How long does the contracting process take?',
    answer:
      '<p>The contracting process can vary depending on the insurance carrier. Typically, it can take anywhere from a few days to a few weeks. This timeline may be extended if additional documentation or verification is needed.</p>',
  },
  {
    id: 3,
    question: 'What is a background check and why is it necessary?',
    answer:
      '<p>A background check is an investigation into your past, particularly regarding criminal records, financial solvency, and professional conduct. Insurance carriers often require this to ensure their agents maintain a high standard of integrity and reliability, as they will be handling sensitive customer information and financial transactions.</p>',
  },
  {
    id: 4,
    question:
      'What are the ongoing requirements after contracting with the carrier?',
    answer:
      '<p>After contracting with the carrier, you are expected to maintain your insurance license, adhere to all terms and conditions outlined in the contract, and abide by the laws and regulations governing insurance sales. Regular training may also be required to ensure you stay up-to-date with industry changes and product knowledge.</p>',
  },
  {
    id: 5,
    question: 'Can I contract with multiple insurance carriers?',
    answer:
      "<p>Yes, many agents contract with multiple carriers. This practice, known as being 'independent' or 'broker', allows you to offer your clients a wider range of products and services. However, be sure to check the terms of your contract, as some carriers may impose exclusivity clauses.</p>",
  },
  {
    id: 6,
    question: 'What happens if I breach the contract?',
    answer:
      "<p>Breaching the contract can lead to serious consequences, including termination of your contract, legal action, and potential loss of your insurance license. It's crucial to thoroughly understand and follow the terms and conditions outlined in your contract.</p>",
  },
  {
    id: 7,
    question: 'What are Just-In-Time appointments?',
    answer:
      '<p>Just-In-Time appointments legally give insurance carriers the ability to delay appointing a producer (and paying the associated fees) until the producer actually begins writing business for that carrier in the states that allow JIT appointments.</p>',
  },
  {
    id: 8,
    question: 'What is FINRA?',
    answer:
      '<p>The Financial Industry Regulatory Authority, or FINRA, is a nongovernmental organization that works to ensure the integrity of the U.S. financial system. FINRA is a key player in the financial regulatory field with a wide range of disciplinary powers including the ability to take action against brokers and firms acting with ill-intent. The main goal of FINRA is to protect investors from fraud and unethical practices.</p>',
  },
  {
    id: 9,
    question: 'What is a FMO or IMO?',
    answer:
      '<p>An FMO (Field Marketing Organization) or IMO (Insurance Marketing Organization) serve as intermediaries between insurance companies and insurance agents. They provide various services to agents including access to many different insurance carriers, marketing assistance, business development help, and back-office support.</p>',
  },
  {
    id: 10,
    question: 'What does E&O Insurance mean?',
    answer:
      "<p>E&O stands for Errors and Omissions insurance. It's a type of professional liability insurance that protects companies, their workers, and other professionals against claims of inadequate work or negligent actions. In the context of insurance agents, it can provide coverage in case of mistakes or oversights made when selling insurance products.</p>",
  },
  {
    id: 11,
    question: 'What is a vesting period?',
    answer:
      "<p>In the context of insurance sales, a vesting period refers to the period of time an agent must be contracted and writing business with an insurance company before the agent owns the rights to their book of business. After this period, even if the agent's contract with the insurance company ends, the agent may continue to receive renewal commissions on policies they have sold.</p>",
  },
  {
    id: 12,
    question: 'What is a book of business?',
    answer:
      '<p>A book of business in insurance refers to a database or list of clients that an insurance agent or agency has contracts with. It represents the clientele relationships and future business opportunities for the agent or agency.</p>',
  },
  {
    id: 13,
    question: 'What are chargebacks?',
    answer:
      "<p>Chargebacks in insurance refer to the reversal of commission paid to an agent when a policy is cancelled before the end of the chargeback period specified in the agent's contract. It is the agent's obligation to repay the insurance company for these commissions.</p>",
  },
];
