import localForage from 'localforage';
import { AgentSelect } from '../types';

// The value of this key should be changed if the Object being stored ever changes
const IMPERSONATE_KEY = 'contracting-impersonated-user';

/**
 * Add the impersonated user to storage for use after logging in
 */
export async function storeImpersonatedUser(params: { user: AgentSelect }) {
  await localForage.setItem(IMPERSONATE_KEY, JSON.stringify(params.user));
}

/**
 * Remove impersonated user data from storage
 */
export async function removeImpersonatedUser() {
  await localForage.removeItem(IMPERSONATE_KEY);
}

/**
 * Check if there is an impersonated user stored in the browser
 */
export async function checkForImpersonatedUser() {
  const user = await localForage.getItem(IMPERSONATE_KEY);

  if (user && typeof user === 'string') {
    const impersonatedUser = JSON.parse(user) as AgentSelect;
    // return the data for the impersonated user
    return impersonatedUser;
  } else {
    return null;
  }
}
