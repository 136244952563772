import ErrorIcon from '@mui/icons-material/Error';
import LogoutIcon from '@mui/icons-material/Logout';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Auth } from 'aws-amplify';
import { materialTheme } from '../theme';

export function AuthorizeError() {
  return (
    <Box
      sx={{
        height: '100dvh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 320 }}>
        <Paper sx={{ overflow: 'hidden' }}>
          <Paper
            elevation={0}
            sx={{
              p: 1,
              color: '#fff',
              backgroundColor: materialTheme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '4px solid red',
              borderRadius: 0,
            }}
          >
            <ErrorIcon fontSize="large" />
            <Box sx={{ pl: 1, fontSize: 24, fontWeight: 'bold' }}>Error</Box>
          </Paper>

          <Box sx={{ p: 1, fontSize: 18 }}>
            An error occured while attempting to access your information.
          </Box>

          <Box sx={{ p: 1 }}>Please refresh the page and try again.</Box>

          <Box sx={{ p: 1, fontSize: 14, color: '#4a4a4a' }}>
            If the problem persists please contact your manager and have them
            check on the status of your account.
          </Box>

          <Box sx={{ p: 1 }}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              color="info"
              startIcon={<LogoutIcon />}
              onClick={() => Auth.signOut()}
            >
              Sign out
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}
