import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { CustomerProfileIframe } from '../../../components/customer-profile-iframe';
import { AuthContext } from '../../../contexts/auth-context';
import { captureError } from '../../../utils/capture-error';
import { isAgt } from '../../../utils/is-agent';

export function CardProfileAdd(props: { queryKey: QueryFilters['queryKey'] }) {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [open, setOpen] = React.useState(false);
  const [closing, setClosing] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    // Not using react-query because the response token should not be cached
    const fetchData = async () => {
      try {
        setLoading(true);
        setToken('');
        const response: { token: string } = await API.post(
          'ContractingAPI',
          '/billing/authorize/customer-profile/token',
          {
            body: {
              hostedIframeReturnUrl:
                window.location.origin + '/IFrameCommunicator.html',
            },
          },
        );
        setToken(response.token);
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open]);

  const queryClient = useQueryClient();
  const handleClose = async () => {
    if (!closing) {
      try {
        setClosing(true);

        // Refetch data
        await queryClient.invalidateQueries({ queryKey: props.queryKey });

        setOpen(false);
      } catch (error) {
        captureError({ data: { error } });
      } finally {
        setClosing(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Box
        disabled={!isAgt(user?.getUsername()) || impersonatedAgent !== null}
        component="button"
        onClick={() => setOpen(true)}
        sx={{
          cursor: 'pointer',
          height: 240,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fefefe',
          borderRadius: 4,
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: '#03182E22',
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            borderColor: '#03182E',
            backgroundColor: '#fff',
          },
          ':disabled': {
            cursor: 'inherit',
            opacity: 0.5,
            transform: 'none',
            borderColor: '#03182E22',
          },
        }}
      >
        <h2 style={{ color: '#4a4a4a', fontSize: 48 }}>
          <AddCircleOutlineIcon fontSize="large" />
          <span style={{ marginLeft: 10 }}>Add Card</span>
        </h2>
      </Box>

      <Drawer
        container={window.document.body}
        variant="temporary"
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
            height: '100dvh',
          },
        }}
      >
        <Box
          sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}
        >
          <Toolbar>
            <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
              {loading || closing ? (
                <CircularProgress size={24} />
              ) : (
                <CloseIcon />
              )}
            </IconButton>

            <Typography variant="h6" noWrap component="div">
              Add Card
            </Typography>
          </Toolbar>

          <Divider />

          {loading ? (
            <LinearProgress />
          ) : token !== '' ? (
            <Box
              sx={{
                minHeight: 0,
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CustomerProfileIframe
                action="/customer/addPayment"
                iframeToken={token}
                title="Add Card"
                onReceiveCommunication={(action) => {
                  if (action) {
                    handleClose();
                  }
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Drawer>
    </React.Fragment>
  );
}
