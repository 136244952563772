import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { GettingStartedTeamData } from '../../../types';
import { tasks } from '../../home/data';

export function ManageProgressItem(props: {
  item: GettingStartedTeamData;
  onClick: () => void;
}) {
  // Props
  const { item } = props;

  return (
    <Box
      component="li"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #00000011',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':nth-of-type(even)': {
          backgroundColor: '#fcfcfc',
        },
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
        ':hover .chevron-icon-item-hover': {
          color: '#03182E',
          mr: 0,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            padding: { xs: 1, md: 2 },
            pb: { xs: 0, md: 2 },
            color: '#b26500',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            fontSize: 12,
          }}
        >
          {item.AgtNo}
        </Box>

        <Box sx={{ flex: 1, padding: 1, pl: { xs: 1, md: 0 } }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: {
                xs: 'flex-start',
                lg: 'center',
              },
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
            }}
          >
            <Box sx={{ fontSize: 22, mr: 1 }}>{item.AgtName}</Box>
            <Box sx={{ fontSize: 10 }}>{item.Email}</Box>
          </Box>
          {item.CreatedDate ? (
            <Box sx={{ fontSize: 12, color: '#7a7a7a' }}>
              Completed registration on{' '}
              {new Date(item.CreatedDate).toDateString()}
            </Box>
          ) : null}
        </Box>

        {item.RecAgtName ? (
          <Box
            sx={{
              mx: { xs: 1, md: 0 },
              mb: { xs: 1, md: 0 },
              py: 0.6,
              px: 1,
              backgroundColor: '#f3f3f3',
              borderRadius: 2,
              border: '1px solid #e1e1e1',
            }}
          >
            <Tooltip title="Recruited by" placement="left" arrow>
              <Box sx={{ fontSize: 14, fontWeight: 'bold', color: '#2196f3' }}>
                {item.RecAgtName}
              </Box>
            </Tooltip>
          </Box>
        ) : null}

        <Box
          sx={{
            mx: 1,
            mb: { xs: 1, md: 0 },
            py: 0.6,
            px: 1,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#f1f1f1',
            borderRadius: 2,
            border: '1px solid #e1e1e1',
          }}
        >
          {tasks.map((task) => {
            let isCompleted = false;
            if (task.key === 'Fastract') {
              isCompleted = true;
            } else if (task.key in props.item) {
              // @ts-ignore
              isCompleted = Boolean(props.item[task.key]);
            }
            return (
              <React.Fragment key={task.key}>
                {task.key === 'Fastract' ? null : (
                  <Box
                    sx={{
                      ml: -0.2,
                      mr: -0.2,
                      width: 6,
                      height: 2,
                      backgroundColor: '#9b9b9b88',
                    }}
                  />
                )}

                {isCompleted ? (
                  <CheckCircleIcon fontSize="small" color="secondary" />
                ) : (
                  <RadioButtonUncheckedIcon
                    fontSize="small"
                    sx={{ color: '#9b9b9b' }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          minWidth: 38,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChevronRightIcon
          className="chevron-icon-item-hover"
          sx={{
            color: '#03182E44',
            transition: 'all 0.2s',
            mr: 0.8,
          }}
        />
      </Box>
    </Box>
  );
}
