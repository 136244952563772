import ForwardIcon from '@mui/icons-material/Forward';
import QuizIcon from '@mui/icons-material/Quiz';
import SendIcon from '@mui/icons-material/Send';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  QueryFilters,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteError } from '../../components/route-container/route-error';
import { AuthContext } from '../../contexts/auth-context';
import {
  AuthorizeData,
  DocumentCarrierData,
  DocumentQuestionData,
  DocumentQuestionType,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { DisplayValue } from '../display-value';

export function ContractQuestions(props: {
  doc: DocumentCarrierData;
  queryKey: QueryFilters['queryKey'];
}) {
  // Context
  const {
    state: { impersonatedAgent, authorize },
    dispatch,
  } = React.useContext(AuthContext);
  // State
  const [Questions, setQuestions] = React.useState<DocumentQuestionData[]>([]);
  const [fetching, setFetching] = React.useState({
    Authorize: false,
    NIPR: false,
  });

  // Query - Initial Questions before Contracting
  const DocID = props.doc.DocID;
  const path = '/documents/form-questions/list';
  const query = useQuery({
    queryKey: [path, { DocID }],
    queryFn: async () => {
      const response: {
        data: DocumentQuestionData[];
      } = await API.post('ContractingAPI', path, {
        body: { DocID },
      });

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onSuccess: (data) => {
      if (data.length) {
        setQuestions(
          data.map((i) => {
            const Answer = i.PrefillAnswer || '';
            return { ...i, Answer };
          })
        );
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Fetch Authorize info after NIPR data
  const fetchAuthorize = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, Authorize: true }));
      const pathAuth = '/authorize';
      const response: {
        data: AuthorizeData;
      } = await API.post('ContractingAPI', pathAuth, {});
      if (response.data) {
        // Update cache
        queryClient.setQueryData([pathAuth], response.data);
        // Update Context
        dispatch({ type: 'SET_AUTHORIZE', payload: response.data });
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, Authorize: false }));
    }
  };

  // Fetch NIPR data on close
  const fetchNIPR = async () => {
    try {
      setFetching((currentState) => ({ ...currentState, NIPR: true }));
      await API.post('ContractingAPI', '/nipr/info/initiate', {});
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setFetching((currentState) => ({ ...currentState, NIPR: false }));
    }
  };

  const queryClient = useQueryClient();
  // Mutation - Submit Answers and Create Contract
  const mutation = useMutation({
    mutationFn: async () => {
      if (
        impersonatedAgent === null &&
        authorize &&
        authorize.Agent &&
        authorize.Agent.HasNIPRData !== '1'
      ) {
        // Fetch NIPR info
        await fetchNIPR();
        // Fetch Authorize info to see if "HasNIPRData" or "HasSignedICA" has been updated
        await fetchAuthorize();
      }

      // Submit Answers
      await API.post('ContractingAPI', '/documents/form-questions/submit', {
        body: {
          DocID: props.doc.DocID,
          Answers: Questions.map((i) => ({
            QID: i.QID,
            Answer:
              Array.isArray(i.Answer) && i.Answer.length
                ? i.Answer.join('|')
                : i.Answer,
          })),
        },
      });

      // Create Contract
      const response: {
        data: { AgtDocID: number; SignerUrl: string | null };
      } = await API.post('ContractingAPI', '/documents/create', {
        body: { DocID: props.doc.DocID },
      });

      return response;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: props.queryKey });
    },
    onError: (error) => captureError({ data: { error } }),
  });

  if (query.isLoading) {
    return <LinearProgress />;
  } else if (query.isError) {
    return <RouteError />;
  } else {
    const renderInput = (item: DocumentQuestionData) => {
      switch (item.QType) {
        case DocumentQuestionType['Date']: {
          return (
            <TextField
              id={`question-${item.QID}`}
              type="date"
              label="Date"
              InputLabelProps={{ shrink: true }}
              required={item.Req || false}
              value={item.Answer}
              onChange={(event) => {
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                );
              }}
            />
          );
        }
        case DocumentQuestionType['Datetime']: {
          return (
            <TextField
              id={`question-${item.QID}`}
              type="datetime-local"
              label="Date and Time"
              InputLabelProps={{ shrink: true }}
              required={item.Req || false}
              value={item.Answer}
              onChange={(event) => {
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                );
              }}
            />
          );
        }
        case DocumentQuestionType['Number']: {
          return (
            <TextField
              id={`question-${item.QID}`}
              type="number"
              required={item.Req || false}
              value={item.Answer}
              onChange={(event) => {
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                );
              }}
            />
          );
        }
        case DocumentQuestionType['Text']: {
          return (
            <TextField
              id={`question-${item.QID}`}
              required={item.Req || false}
              value={item.Answer}
              onChange={(event) => {
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                );
              }}
            />
          );
        }
        case DocumentQuestionType['Radio']: {
          return (
            <RadioGroup
              value={item.Answer}
              onChange={(event) => {
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                );
              }}
            >
              {item.ChoiceAnswers?.map((c) => {
                return (
                  <FormControlLabel
                    key={c}
                    value={c}
                    control={<Radio color="info" />}
                    label={c}
                  />
                );
              })}
            </RadioGroup>
          );
        }
        case DocumentQuestionType['Checkbox']: {
          return (
            <FormGroup>
              {item.ChoiceAnswers?.map((c) => {
                return (
                  <FormControlLabel
                    key={c}
                    control={
                      <Checkbox
                        color="info"
                        checked={item.Answer?.includes(c)}
                        onChange={(event, checked) => {
                          setQuestions((currentState) =>
                            currentState.map((i) => {
                              if (item.QID === i.QID) {
                                let Answer = Array.isArray(i.Answer)
                                  ? i.Answer
                                  : [];
                                if (checked) {
                                  Answer = [...Answer, c];
                                } else {
                                  Answer = Answer.filter((a) => a !== c);
                                }
                                return { ...i, Answer };
                              } else {
                                return i;
                              }
                            })
                          );
                        }}
                      />
                    }
                    label={c}
                  />
                );
              })}
            </FormGroup>
          );
        }
        case DocumentQuestionType['Select']: {
          return (
            <Select
              id="upload-type-select"
              value={item.Answer}
              sx={{ minWidth: 240 }}
              onChange={(event) =>
                setQuestions((currentState) =>
                  currentState.map((i) => {
                    if (item.QID === i.QID) {
                      return {
                        ...i,
                        Answer: event.target.value,
                      };
                    } else {
                      return i;
                    }
                  })
                )
              }
            >
              {item.ChoiceAnswers?.map((c) => {
                return (
                  <MenuItem key={c} value={c}>
                    {c}
                  </MenuItem>
                );
              })}
            </Select>
          );
        }
        default: {
          return null;
        }
      }
    };

    let completedQuestions = 0;
    Questions.forEach((i) => {
      if (i.Req && Array.isArray(i.Answer) && i.Answer.length) {
        completedQuestions = completedQuestions + 1;
      } else if (i.Req && !Array.isArray(i.Answer) && i.Answer) {
        completedQuestions = completedQuestions + 1;
      } else if (!i.Req) {
        completedQuestions = completedQuestions + 1;
      }
    });
    const completedAll = completedQuestions === Questions.length;

    const isInvite = props.doc.DocType === 'Invite';

    return (
      <Box
        sx={{
          minHeight: 0,
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          <Box sx={{ px: 2 }}>
            <DisplayMarkup markup={props.doc.Instructions} />
          </Box>

          <Box>
            <Box
              sx={{
                borderTop: '1px solid #00000011',
                borderBottom: '1px solid #00000011',
              }}
            >
              <Box
                sx={{ display: 'flex', borderBottom: '1px solid #00000011' }}
              >
                <Box
                  sx={{
                    minWidth: 52,
                    borderRight: '1px solid #00000011',
                    backgroundColor: '#2196f311',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <QuizIcon color="info" />
                </Box>

                <Box sx={{ p: 2, fontWeight: 'bold', fontSize: 18 }}>
                  Please answer the following questions ({Questions.length})
                </Box>
              </Box>

              {Questions.map((item, index) => {
                return (
                  <Box
                    key={item.QID}
                    sx={{
                      display: 'flex',
                      borderBottom: '1px solid #00000011',
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: 52,
                        borderRight: '1px solid #00000011',
                        backgroundColor: '#2196f311',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: 24,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto Mono',
                          textTransform: 'uppercase',
                          color: '#2196f3',
                        }}
                      >
                        {index + 1}
                      </Box>
                    </Box>

                    <Box sx={{ pl: 1, pb: 1, flex: 1 }}>
                      <DisplayValue
                        label={`Question${item.Req ? ' *' : ''}`}
                        value={
                          <DisplayMarkup
                            markup={item.Question}
                            sx={{ fontSize: 16 }}
                          />
                        }
                      />

                      <Box sx={{ pl: 2 }}>{renderInput(item)}</Box>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            backgroundColor: '#fffbf3',
            px: { xs: 4, md: 8 },
            py: { xs: 2, md: 6 },
          }}
        >
          {mutation.isError ? (
            <Alert
              onClose={() => mutation.reset()}
              severity="error"
              sx={{ width: '100%', mb: 1 }}
            >
              <strong style={{ marginRight: 10 }}>
                An error has occurred!
              </strong>
              <small>Please refresh the page and try again.</small>
            </Alert>
          ) : null}

          {fetching.NIPR ? (
            <Box>
              <Box
                sx={{
                  fontSize: 12,
                  fontStyle: 'italic',
                  color: '#7a7a7a',
                  textAlign: 'center',
                  p: 1,
                }}
              >
                Please wait while we check the National Insurance Producer
                Registry (NIPR) for your license information...
              </Box>

              <LinearProgress color="info" />
            </Box>
          ) : fetching.Authorize ? (
            <LinearProgress />
          ) : mutation.isLoading ? (
            <Box>
              <Box
                sx={{
                  fontSize: 12,
                  fontStyle: 'italic',
                  color: '#7a7a7a',
                  textAlign: 'center',
                  p: 1,
                }}
              >
                {isInvite
                  ? 'Your request to contract is being created...'
                  : 'Retrieving the document from Adobe Acrobat Sign...'}
              </Box>

              <LinearProgress color="info" />
            </Box>
          ) : (
            <Button
              disabled={mutation.isLoading || mutation.isError || !completedAll}
              fullWidth
              size="large"
              color="info"
              variant="contained"
              endIcon={isInvite ? <SendIcon /> : <ForwardIcon />}
              onClick={() => mutation.mutate()}
            >
              {isInvite
                ? 'Request invite to contract'
                : 'View and Complete Contract'}
            </Button>
          )}
        </Box>
      </Box>
    );
  }
}
