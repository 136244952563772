import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Warning from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { RequestChangeData } from '../../../types';

export function ManageChangesInfo(props: {
  agent: RequestChangeData | undefined;
  onClose: () => void;
}) {
  // Props
  const { agent } = props;

  const handleClose = () => {
    props.onClose();
  };

  let CreatedDate = agent?.CreatedDate;
  if (CreatedDate && CreatedDate.includes(' ') && !CreatedDate.includes('T')) {
    // Convert "2023-06-12 15:47:42.3483176" to "2023-06-12T15:47:42.3483176"
    CreatedDate = CreatedDate.replace(' ', 'T');
  }

  return (
    <Drawer
      container={window.document.body}
      variant="temporary"
      anchor="right"
      open={agent !== undefined}
      onClose={handleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: { xs: '100vw', sm: '80vw', md: '60vw', lg: '40vw' },
          height: '100dvh',
        },
      }}
    >
      <Box sx={{ height: '100dvh', display: 'flex', flexDirection: 'column' }}>
        <Toolbar>
          <IconButton aria-label="close" sx={{ mr: 2 }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>

          {agent !== undefined ? (
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontSize: { xs: 16, md: 22 },
                  flex: 1,
                  fontWeight: 'normal',
                }}
              >
                Changes for {agent.Fname} {agent.Lname}
              </Typography>

              <Box
                sx={{
                  fontFamily: 'Roboto Mono',
                  fontWeight: 'bold',
                  fontSize: 14,
                  color: '#b26500',
                }}
              >
                {agent.AgtNo}
              </Box>
            </Box>
          ) : null}
        </Toolbar>

        <Divider />

        {agent !== undefined ? (
          <Box
            sx={{
              minHeight: 0,
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack spacing={1} sx={{ p: 2 }}>
              <Box
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #fafafa',
                  borderRadius: 2,
                }}
              >
                <ScheduleIcon fontSize="small" />

                {CreatedDate ? (
                  <Box sx={{ px: 1, fontSize: 16 }}>
                    Change requested on {new Date(CreatedDate).toDateString()}
                  </Box>
                ) : null}

                {CreatedDate ? (
                  <Box
                    sx={{ fontSize: 12, fontStyle: 'italic', color: '#9b9b9b' }}
                  >
                    {formatDistanceToNowStrict(new Date(CreatedDate), {
                      addSuffix: true,
                    })}
                  </Box>
                ) : null}
              </Box>

              <Box sx={{ p: 1, pl: 2, fontSize: 12, color: '#5a5a5a' }}>
                This change will take effect after being reviewed by an Admin.
              </Box>

              {agent.Reason ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #fafafa',
                    borderRadius: 2,
                  }}
                >
                  <Warning fontSize="small" color="warning" />
                  <Box sx={{ px: 1 }}>{agent.Reason}</Box>
                </Box>
              ) : null}

              {agent.ChangeType === 'level' ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #fafafa',
                    borderRadius: 2,
                  }}
                >
                  <MoveUpIcon fontSize="small" />

                  <Box sx={{ px: 1, fontSize: 16 }}>Set new level as</Box>

                  <Box
                    sx={{ fontSize: 18, fontWeight: 'bold', color: '#b26500' }}
                  >
                    {agent.ToLevel}
                  </Box>
                </Box>
              ) : agent.ChangeType === 'hierarchy' ? (
                <Box
                  sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid #fafafa',
                    borderRadius: 2,
                  }}
                >
                  <GroupAddIcon fontSize="small" />

                  <Box sx={{ px: 1, fontSize: 16 }}>Set new upline as</Box>

                  <Box
                    sx={{ fontSize: 18, fontWeight: 'bold', color: '#2196f3' }}
                  >
                    {agent.ToName}
                  </Box>
                </Box>
              ) : null}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Drawer>
  );
}
