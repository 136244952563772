import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { orderBy, uniq } from 'lodash';
import React from 'react';
import { RouteContainer } from '../../components/route-container';
import { SearchField, searchBy } from '../../components/search-field';
import { AuthContext } from '../../contexts/auth-context';
import { AppointmentData, CarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';
import { AppointmentGroup } from './appointment-group';

export function Appointments() {
  // Context
  const {
    state: { user, impersonatedAgent },
  } = React.useContext(AuthContext);
  // State
  const [viewing, setViewing] = React.useState<'state' | 'company'>('state');
  const [Status, setStatus] = React.useState<
    'all' | 'Appointed' | 'Terminated'
  >('all');
  const [searchString, setSearchString] = React.useState('');

  // Query - Carriers
  const pathCarriers = '/carrier';
  const queryCarriers = useQuery({
    queryKey: [pathCarriers],
    queryFn: async () => {
      const response: {
        data: CarrierData[];
      } = await API.post('ContractingAPI', pathCarriers, {
        body: {},
      });

      if (response.data) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Appointments
  const AgtNo = impersonatedAgent?.AgtNo || user?.getUsername();
  const pathAppts = '/agent/appointments';
  const queryAppts = useQuery({
    queryKey: [pathAppts, { AgtNo }],
    queryFn: async () => {
      if (isAgt(AgtNo)) {
        const response: {
          data: AppointmentData[];
        } = await API.post('ContractingAPI', pathAppts, {
          body: { AgtNo },
        });

        if (response.data.length) {
          return response.data;
        }
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const data = queryAppts.data !== undefined ? queryAppts.data : [];
  // Searching
  const searchedItems = searchBy(data, searchString);
  // Status
  let statusItems = searchedItems;
  if (Status !== 'all') {
    statusItems = searchedItems.filter((i) => i.ApptStatus === Status);
  }

  let content = <Box />;
  if (data.length === 0) {
    content = (
      <Paper elevation={0} sx={{ p: 8, border: '1px solid #eee' }}>
        <Typography
          sx={{
            textAlign: 'center',
            fontFamily: 'Roboto Mono',
            color: '#9b9b9b',
          }}
        >
          No appointments
        </Typography>
      </Paper>
    );
  } else if (viewing === 'state') {
    // Ordered items
    const orderedItems = orderBy(statusItems, [
      (i) => i.State.toLowerCase(),
      (i) => i.Company.toLowerCase(),
    ]);
    const states = uniq(orderedItems.map((i) => i.State));

    content = (
      <Stack spacing={2}>
        {states.map((item) => {
          const items = statusItems.filter(
            (i) => i.State.toLowerCase() === item.toLowerCase()
          );
          return (
            <AppointmentGroup
              key={item}
              viewing="state"
              title={item}
              items={items}
              carriers={queryCarriers.data || []}
            />
          );
        })}
      </Stack>
    );
  } else if (viewing === 'company') {
    // Ordered items
    const companyItems = orderBy(statusItems, [
      (i) => i.Company.toLowerCase(),
      (i) => i.State.toLowerCase(),
    ]);
    const companies = uniq(companyItems.map((i) => i.FEIN));

    content = (
      <Stack spacing={2}>
        {companies.map((item) => {
          const items = statusItems.filter(
            (i) => i.FEIN.toLowerCase() === item.toLowerCase()
          );
          const title = companyItems.find(
            (i) => i.FEIN.toLowerCase() === item.toLowerCase()
          )?.Company;
          return (
            <AppointmentGroup
              key={item}
              viewing="company"
              title={title || item}
              items={items}
              carriers={queryCarriers.data || []}
            />
          );
        })}
      </Stack>
    );
  }

  return (
    <RouteContainer
      routeTitle="My Appointments"
      withContainer
      loading={queryAppts.isLoading || queryCarriers.isLoading}
      hasError={queryAppts.isError || queryCarriers.isError}
    >
      <Box sx={{ pt: 2, pb: 16 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <SearchField
              id="appointments-search"
              fullWidth
              size="medium"
              placeholder="Search appointments"
              value={searchString}
              onChange={(event) => setSearchString(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ToggleButtonGroup
              fullWidth
              exclusive
              color="info"
              value={viewing}
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newValue: string | null
              ) => {
                if (newValue) {
                  setViewing(newValue as 'state' | 'company');
                }
              }}
            >
              <ToggleButton size="medium" value="state">
                State
              </ToggleButton>
              <ToggleButton size="medium" value="company">
                Company
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <ToggleButtonGroup
              fullWidth
              exclusive
              color="info"
              value={Status}
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                newValue: string | null
              ) => {
                if (newValue) {
                  setStatus(newValue as 'all' | 'Appointed' | 'Terminated');
                }
              }}
            >
              <ToggleButton size="medium" value="all">
                All
              </ToggleButton>
              <ToggleButton size="medium" value="Appointed">
                Appointed
              </ToggleButton>
              <ToggleButton size="medium" value="Terminated">
                Terminated
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        <Box sx={{ pt: 2, pb: 2 }}>
          <Divider />
        </Box>

        {content}
      </Box>
    </RouteContainer>
  );
}
