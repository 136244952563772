import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HideSourceIcon from '@mui/icons-material/HideSource';
import Box from '@mui/material/Box';
import { AppointmentData } from '../../types';

export function AppointmentItem(props: { item: AppointmentData }) {
  // Props
  const { item } = props;

  return (
    <Box
      component="li"
      sx={{
        padding: 1,
        pr: 3,
        fontFamily: 'Roboto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #00000011',
        transition: 'all 0.2s',
        ':first-of-type': {
          borderTop: 'none',
        },
        ':nth-of-type(even)': {
          backgroundColor: '#fcfcfc',
        },
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            px: { xs: 0, md: 1 },
            opacity: item.ApptStatus === 'Terminated' ? 0.6 : 1,
            color: '#b26500',
            fontFamily: 'Roboto Mono',
            fontWeight: 'bold',
            fontSize: 24,
          }}
        >
          {item.State}
        </Box>

        <Box sx={{ flex: 1, px: { xs: 0, md: 1 } }}>
          <Box sx={{ opacity: item.ApptStatus === 'Terminated' ? 0.7 : 1 }}>
            <Box sx={{ fontSize: 16 }}>{item.Company}</Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: { xs: 'flex-start', md: 'center' },
                flexDirection: { xs: 'column', md: 'row' },
              }}
            >
              {item.LOA ? (
                <Box sx={{ fontSize: 14, mr: 1 }}>{item.LOA}</Box>
              ) : null}

              <Box sx={{ fontSize: 11, fontStyle: 'italic' }}>
                {item.ApptStatus} {item.ReasonDate} {item.TermReason}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {item.ApptStatus === 'Appointed' ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <CheckCircleIcon
            fontSize="small"
            color="secondary"
            sx={{ mr: 0.4 }}
          />
        </Box>
      ) : null}

      {item.ApptStatus === 'Terminated' ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HideSourceIcon
            fontSize="small"
            sx={{ color: '#03182E22', mr: 0.4 }}
          />
        </Box>
      ) : null}
    </Box>
  );
}
