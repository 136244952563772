import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import { FileUploadData } from '../../types';

export function DocListItem(props: {
  item: FileUploadData;
  onClick: () => void;
}) {
  // Props
  const { item } = props;

  return (
    <Box
      component="li"
      tabIndex={0}
      onClick={props.onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          props.onClick();
        }
      }}
      sx={{
        fontFamily: 'Roboto',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        borderTop: '1px solid #00000011',
        cursor: 'pointer',
        transition: 'all 0.2s',
        ':first-of-type': {
          borderTop: 'none',
        },
        ':nth-of-type(even)': {
          backgroundColor: '#fcfcfc',
        },
        ':hover': {
          backgroundColor: '#f5f5f5',
        },
        ':hover #chevron-icon': {
          color: '#03182E',
          mr: 0,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: {
            xs: 'flex-start',
            md: 'center',
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            color: '#b26500',
          }}
        >
          {item.MimeType?.includes('pdf') ? (
            <PictureAsPdfIcon />
          ) : item.MimeType?.includes('word') ? (
            <DescriptionIcon />
          ) : item.MimeType?.includes('image') ? (
            <ImageIcon />
          ) : (
            <InsertDriveFileIcon />
          )}
        </Box>

        <Box sx={{ flex: 1, padding: 1, pl: { xs: 1, md: 0 } }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                lg: 'row',
              },
              alignItems: {
                xs: 'flex-start',
                lg: 'center',
              },
            }}
          >
            <Box sx={{ fontSize: 22, pr: 1 }}>{item.FileDisplayName}</Box>
            <Box sx={{ fontSize: 10 }}>{item.FileUploadType}</Box>
          </Box>

          {item.CreatedDate ? (
            <Box sx={{ fontSize: 12, color: '#7a7a7a' }}>
              Document uploaded on{' '}
              {item.CreatedDate
                ? new Date(
                    item.CreatedDate.substring(0, 10) + 'T00:00'
                  ).toDateString()
                : ''}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box
        sx={{
          minWidth: 38,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ChevronRightIcon
          id="chevron-icon"
          sx={{
            color: '#03182E44',
            transition: 'all 0.2s',
            mr: 0.8,
          }}
        />
      </Box>
    </Box>
  );
}
