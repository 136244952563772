import ConstructionIcon from '@mui/icons-material/Construction';
import LogoutIcon from '@mui/icons-material/Logout';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../contexts/auth-context';
import { materialTheme } from '../theme';

export function TempMaintenanceMessage() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [visible, setVisible] = React.useState(true);

  const isAuthenticated = state.user !== undefined;

  if (visible) {
    return (
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 3,
        }}
      >
        <Container maxWidth="sm" sx={{ p: 1 }}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <Stack spacing={1} direction="row" alignItems="center">
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => setVisible(false)}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Dismiss
                </Button>

                {isAuthenticated ? (
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => Auth.signOut()}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    Sign out
                  </Button>
                ) : null}
              </Stack>
            }
          >
            {/* <span>Maintenance is being performed until {mEnd}</span> */}
            <span>Maintenance is being performed</span>
          </Alert>
        </Container>
      </Box>
    );
  } else {
    return null;
  }
}

export function MaintenanceMessageAdmins(props: { maintenanceEnd: string }) {
  // Props
  // const { maintenanceEnd } = props;
  // State
  const [visible, setVisible] = React.useState(true);

  // let mEnd = '';
  // if (maintenanceEnd !== '' && isValid(new Date(maintenanceEnd))) {
  //   mEnd = format(new Date(maintenanceEnd), 'PPPPpppp');
  // }

  if (visible) {
    return (
      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          zIndex: 3,
        }}
      >
        <Container maxWidth="sm" sx={{ p: 1 }}>
          <Alert
            severity="error"
            variant="filled"
            action={
              <Stack spacing={1} direction="row" alignItems="center">
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => setVisible(false)}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Dismiss
                </Button>

                <Button
                  size="small"
                  variant="outlined"
                  color="inherit"
                  onClick={() => Auth.signOut()}
                  sx={{ whiteSpace: 'nowrap' }}
                >
                  Sign out
                </Button>
              </Stack>
            }
          >
            {/* <span>Maintenance is being performed until {mEnd}</span> */}
            <span>Maintenance is being performed</span>
          </Alert>
        </Container>
      </Box>
    );
  } else {
    return null;
  }
}

export function MaintenanceMessage(props: {
  data: {
    maintenanceActive: boolean;
    maintenanceBegin: string;
    maintenanceEnd: string;
  };
}) {
  // Props
  // const { maintenanceEnd } = props.data;
  // Context
  const { state } = React.useContext(AuthContext);

  const isAuthenticated = state.user !== undefined;

  // let mEnd = '';
  // if (maintenanceEnd !== '' && isValid(new Date(maintenanceEnd))) {
  //   mEnd = format(new Date(maintenanceEnd), 'PPPPpppp');
  // }

  return (
    <Box
      sx={{
        height: '100dvh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '100%', maxWidth: 320 }}>
        <Paper sx={{ overflow: 'hidden' }}>
          <Paper
            elevation={0}
            sx={{
              p: 1,
              color: '#fff',
              backgroundColor: materialTheme.palette.primary.main,
              display: 'flex',
              alignItems: 'center',
              borderBottom: '4px solid lightblue',
              borderRadius: 0,
            }}
          >
            <ConstructionIcon fontSize="large" />
            <Box sx={{ pl: 1, fontSize: 24, fontWeight: 'bold' }}>
              Maintenance
            </Box>
          </Paper>

          <Container maxWidth="sm" sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ color: '#4a4a4a' }}>
              IT Maintenance Update:
            </Typography>
            <Typography>
              We are currently experiencing systems issues. We are working hard
              to fix the issues and hope to be back online fully soon.
            </Typography>
          </Container>

          {/* <Box sx={{ p: 1, fontSize: 18 }}>
            The site is currently undergoing maintenance.
          </Box> */}

          {/* <Box sx={{ p: 1 }}>
            <Box>Please check back on:</Box>

            <Box
              sx={{
                p: 1,
                fontSize: 12,
                fontFamily: 'Roboto Mono',
                backgroundColor: '#eee',
                borderRadius: 2,
              }}
            >
              {mEnd}
            </Box>
          </Box> */}

          {isAuthenticated ? (
            <Box sx={{ p: 1 }}>
              <Button
                fullWidth
                variant="contained"
                disableElevation
                color="info"
                startIcon={<LogoutIcon />}
                onClick={() => Auth.signOut()}
              >
                Sign out
              </Button>
            </Box>
          ) : null}
        </Paper>
      </Box>
    </Box>
  );
}
