import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API } from 'aws-amplify';
import { CardIcon } from '../../../components/card-icon';
import { CustomerPaymentData } from '../../../types';
import { captureError } from '../../../utils/capture-error';

export function CardProfileDelete(props: {
  data: CustomerPaymentData | undefined;
  queryKey: QueryFilters['queryKey'];
  onClose: () => void;
}) {
  // Props
  const { data } = props;
  // Hooks
  const queryClient = useQueryClient();

  // Mutation - Delete Card
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post(
        'ContractingAPI',
        '/billing/authorize/customer-profile/payment-profile/delete',
        { body: { customerPaymentProfileId: data?.customerPaymentProfileId } },
      );
    },
    onSuccess: async () => {
      // Refetch Cards
      await queryClient.invalidateQueries({ queryKey: props.queryKey });

      props.onClose();
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleClose = async () => {
    if (!mutation.isLoading) {
      props.onClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.data !== undefined}
      onClose={handleClose}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flex: 1 }}>Delete card</Box>

        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          Are you sure you would like to delete this card?
        </Typography>

        {props.data ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CardIcon cardType={props.data?.cardType || ''} size="2x" />

            <Typography style={{ fontFamily: 'monospace', marginLeft: 10 }}>
              {props.data?.cardNumber}
            </Typography>
          </div>
        ) : null}
      </DialogContent>

      {mutation.isError ? (
        <Alert
          onClose={() => mutation.reset()}
          severity="error"
          sx={{ borderRadius: 0 }}
        >
          <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
          <small>Please refresh the page and try again.</small>
        </Alert>
      ) : null}

      <DialogActions>
        <Button
          disabled={mutation.isLoading}
          variant="outlined"
          disableElevation
          onClick={props.onClose}
        >
          Cancel
        </Button>

        <Button
          disabled={mutation.isLoading || mutation.isError}
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => mutation.mutate()}
        >
          {mutation.isLoading ? 'Deleting...' : 'Delete card'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
