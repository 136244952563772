import { createTheme } from '@mui/material/styles';

export const materialTheme = createTheme({
  palette: {
    background: {
      default: '#fcfcfc',
    },
    primary: {
      main: '#03182E',
    },
    secondary: {
      main: '#00C868',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: 6,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: '1px solid #00000011',
          borderRadius: 14,
        },
        sizeMedium: {
          borderRadius: 10,
        },
        sizeSmall: {
          borderRadius: 8,
        },
      },
    },
  },
});

export const appTheme = {
  drawerWidth: 280,
};
