import SaveIcon from '@mui/icons-material/Save';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import React from 'react';
import { CarrierData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function WrtNoItemCreate(props: {
  AgtNo: string | undefined;
  carrier: CarrierData;
  onSuccess: () => void;
}) {
  // State
  const [WrtCarNo, setWrtCarNo] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  const queryClient = useQueryClient();
  // Mutation - Add Writing Number for Carrier
  const mutation = useMutation({
    mutationFn: async () => {
      await API.post('ContractingAPI', '/agent/carrier-info/create', {
        body: {
          AgtNo: props.AgtNo,
          CarrierID: props.carrier.CarrierID,
          WrtCarNo,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['/agent/carrier-info', { AgtNo: props.AgtNo }],
      });

      props.onSuccess();
    },
    onError: (error) => {
      if (error instanceof Error) {
        if (
          // @ts-ignore
          error?.response?.data?.error &&
          // @ts-ignore
          error?.response?.data?.status === 'invalid'
        ) {
          // @ts-ignore
          const newError = error.response.data.error as string;
          setErrMsg(newError);
        }
      }
      captureError({ data: { error } });
    },
  });

  const handleSubmit = () => {
    mutation.mutate();
  };

  const submitDisabled = !WrtCarNo.trim();

  return (
    <Box sx={{ p: 1, border: '1px solid #e1e1e1', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id="writing-number-create"
          autoFocus
          fullWidth
          variant="outlined"
          label="Writing Number"
          placeholder="000000000"
          inputProps={{
            style: { fontFamily: 'Roboto Mono', fontSize: 22 },
          }}
          InputLabelProps={{ shrink: true }}
          value={WrtCarNo}
          onChange={(event) => setWrtCarNo(event.target.value)}
        />

        <Box sx={{ pl: 1 }}>
          <Tooltip title="Save writing number" placement="left" arrow>
            <span>
              <IconButton
                disabled={submitDisabled || mutation.isLoading}
                size="small"
                onClick={handleSubmit}
              >
                {mutation.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <SaveIcon color={submitDisabled ? 'disabled' : 'info'} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>

      {mutation.isError ? (
        <Box sx={{ pt: 1 }}>
          <Alert
            onClose={() => {
              mutation.reset();
              setErrMsg('');
            }}
            severity="error"
            sx={{ width: '100%' }}
          >
            <strong style={{ marginRight: 10 }}>An error has occurred!</strong>
            {errMsg ? (
              <small>{errMsg}</small>
            ) : (
              <small>Please refresh the page and try again.</small>
            )}
          </Alert>
        </Box>
      ) : null}
    </Box>
  );
}
